import axios from 'axios';
import API from "../config/API";
import siteSetting from "../config/env";

export const userService = {
    login,
    logout,
    signup,
    getProfile,
    forgot,
    reset,
    review,
    changePassword,
    editProfile,
    socialLogin
};

var instance = axios.create({
    baseURL: siteSetting.api.url,
    //timeout: 30000,
    headers: {
        'Content-Type' : 'application/json',
        'Authorization': 'Bearer '+localStorage.getItem('accessToken'),
    }
});

function logout(apiParam,params) {
    let headers = {
        'Content-Type' : 'application/json',
        'Authorization': 'Bearer '+localStorage.getItem('accessToken'),
        'Accept': 'application/json'
    }
    // remove user from local storage to log user out
    return instance.put(API.LOGOUT+params.userId, params,{headers:headers})
    .then((response) => {
        localStorage.clear();
        return response;
    })
    .catch((err) => {
        localStorage.clear();
    })
}

function login(params) {
    params.clientId=siteSetting.api_clientId;
    params.clientSecret=siteSetting.api_clientSecret;
    //console.log('params',params);
    return axios.post(API.LOGIN, params)
}

function socialLogin(params) {
    params.clientId=siteSetting.api_clientId;
    params.clientSecret=siteSetting.api_clientSecret;
    //console.log('params',params);
    return axios.post(API.USER_SOCIAL_LOGIN, params)
}

function signup(params) {
    params.clientId=siteSetting.api_clientId;
    params.clientSecret=siteSetting.api_clientSecret;
    //console.log('params',params);
    return axios.post(API.USER_SIGNUP, params)
}

function getProfile(params) {
    params.clientId=siteSetting.api_clientId;
    params.clientSecret=siteSetting.api_clientSecret;
    // console.log('params getProfile',params);
    
    return instance.get(API.USER_PROFILE, {params:params})
}

function forgot(params) {
    params.clientId=siteSetting.api_clientId;
    params.clientSecret=siteSetting.api_clientSecret;
    //console.log('params',params);
    return axios.post(API.USER_FORGOT, params)
}

function reset(params) {
    params.clientId=siteSetting.api_clientId;
    params.clientSecret=siteSetting.api_clientSecret;
    //console.log('params',params);
    return axios.post(API.USER_RESET, params)
}

function review(params) {
    params.clientId=siteSetting.api_clientId;
    params.clientSecret=siteSetting.api_clientSecret;
    //console.log('params',params);
    return instance.get('user/'+params.userId+'/'+API.USER_REVIEW, {params:params})
}

function changePassword(params){
    params.clientId=siteSetting.api_clientId;
    params.clientSecret=siteSetting.api_clientSecret;
    let headers = {
        'Content-Type' : 'application/json',
        'Authorization': 'Bearer '+localStorage.getItem('accessToken'),
        'Accept': 'application/json'
    }
    //console.log('params',params);
    return axios.put(API.USER_CHANGEPASSWORD, params,{headers:headers})
}

function editProfile(params) {
    params.clientId=siteSetting.api_clientId;
    params.clientSecret=siteSetting.api_clientSecret;
    //console.log('params',params);
    let headers = {
        'Content-Type' : 'multipart/form-data',
        'Authorization': 'Bearer '+localStorage.getItem('accessToken'),
        'Accept': 'application/json'
    }
    //console.log('params',params);
    return axios.put(API.USER_EDITPROFILE, params,{headers:headers})
}