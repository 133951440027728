import promise from 'bluebird'

import { pageConstants } from '../_constants';

import {pageService} from "../_services";

import { ResponseFilter } from "../config/response-handler";

export const pageActions = {
    termsAndConditions,
    privacyPolicy,
    gdpr,
    hairdresserTermsAndConditions
};

/************************************** getHairdresserInfo ******************************************************/


function termsAndConditions(params) {

    return function (dispatch) {

        return new promise(function (resolve, reject) {


            dispatch(request(params));
            pageService.termsAndConditions(params)
                .then(response => {
                    resolve(response);
                    let { serverResponseData } = ResponseFilter(response);
                    //console.log("serverResponseData", serverResponseData);
                    dispatch(success(serverResponseData.data));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));

                })
        })
    };
    function request(termsAndConditionsRequest) { return { type: pageConstants.T_AND_C_REQUEST, termsAndConditionsRequest } }
    function success(termsAndConditionsSuccess) { return { type: pageConstants.T_AND_C_SUCCESS, termsAndConditionsSuccess } }
    function failure(termsAndConditionsFail) { return { type: pageConstants.T_AND_C_ERROR, termsAndConditionsFail } }
}


function privacyPolicy(params) {

    return function (dispatch) {

        return new promise(function (resolve, reject) {


            dispatch(request(params));
            pageService.privacyPolicy(params)
                .then(response => {
                    resolve(response);
                    let { serverResponseData } = ResponseFilter(response);
                    //console.log("hairdresserReview serverResponseData", serverResponseData);
                    dispatch(success(serverResponseData.data));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));

                })
        })
    };
    function request(privacyPolicyRequest) { return { type:pageConstants.P_AND_P_REQUEST, privacyPolicyRequest } }
    function success(privacyPolicySuccess) { return { type: pageConstants.P_AND_P_SUCCESS, privacyPolicySuccess } }
    function failure(privacyPolicyFail) { return { type: pageConstants.P_AND_P_FAILURE, privacyPolicyFail } }
}

function gdpr(params) {

    return function (dispatch) {

        return new promise(function (resolve, reject) {


            dispatch(request(params));
            pageService.gdpr(params)
                .then(response => {
                    resolve(response);
                    let { serverResponseData } = ResponseFilter(response);
                    //console.log("serverResponseData", serverResponseData);
                    dispatch(success(serverResponseData.data));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));

                })
        })
    };
    function request(gdprRequest) { return { type: pageConstants.GDPR_REQUEST, gdprRequest } }
    function success(gdprSuccess) { return { type: pageConstants.GDPR_SUCCESS, gdprSuccess } }
    function failure(gdprFail) { return { type: pageConstants.GDPR_ERROR, gdprFail } }
}

function hairdresserTermsAndConditions(params) {

    return function (dispatch) {

        return new promise(function (resolve, reject) {


            dispatch(request(params));
            pageService.hairdresserTermsAndConditions(params)
                .then(response => {
                    resolve(response);
                    let { serverResponseData } = ResponseFilter(response);
                    //console.log("serverResponseData", serverResponseData);
                    dispatch(success(serverResponseData.data));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));

                })
        })
    };
    function request(hairdresserTermsAndConditionsRequest) { return { type: pageConstants.H_T_AND_C_REQUEST, hairdresserTermsAndConditionsRequest } }
    function success(hairdresserTermsAndConditionsSuccess) { return { type: pageConstants.H_T_AND_C_SUCCESS, hairdresserTermsAndConditionsSuccess } }
    function failure(hairdresserTermsAndConditionsFail) { return { type: pageConstants.H_T_AND_C_ERROR, hairdresserTermsAndConditionsFail } }
}
