import axios from 'axios';
import API from "../config/API";
// import siteSetting from "../config/env";

export const pageService = {
    termsAndConditions,
    privacyPolicy,
    gdpr,
    hairdresserTermsAndConditions
};

// var instance = axios.create({
//     baseURL: siteSetting.api.url,
//     //timeout: 30000,
//     headers: {
//         'Content-Type' : 'application/json',
//         'Authorization': 'Bearer '+localStorage.getItem('accessToken'),
//     }
// });



function termsAndConditions(params) {

    let  headers= {
        'Content-Type' : 'application/json',
    }
    return axios.get(API["T&C"] )
}

function privacyPolicy(params) {

    let  headers= {
        'Content-Type' : 'application/json',
    }
    return axios.get(API["P&P"] )
}

function gdpr(params) {

    let  headers= {
        'Content-Type' : 'application/json',
    }
    return axios.get(API["GDPR"] )
}

function hairdresserTermsAndConditions(params) {

    let  headers= {
        'Content-Type' : 'application/json',
    }
    return axios.get(API["HT&C"] )
}





