import React, {Component} from 'react';
import {connect} from "react-redux";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import {pageActions} from "../../_actions";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

class Legal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: {},
            message: '',
            loader: true
        };
        this.TandCClick=this.TandCClick.bind(this);
        this.gdprClick=this.gdprClick.bind(this);
        this.PandPClick=this.PandPClick.bind(this);
    }


    TandCClick(){
        var self = this;

        let params={}
        self.props.dispatch(pageActions.termsAndConditions(params))
            .then(function (response) {

            })
    }

    PandPClick(){
        var self = this;

        let params={}
        self.props.dispatch(pageActions.privacyPolicy(params))
            .then(function (response) {

            })
    }

    gdprClick(){
        var self = this;

        let params={}
        self.props.dispatch(pageActions.gdpr(params))
            .then(function (response) {

            })
    }

    componentDidMount() {
        var self = this;

        let params={}
        self.props.dispatch(pageActions.termsAndConditions(params))
            .then(function (response) {

            })

    }


    render() {
        return (
            <React.Fragment>
                <Tabs>
                    <TabList className="booking">
                        <h2 className="cm_bookin_deatils booking_tab row">
                            <Tab onClick={this.TandCClick.bind(this)}>Terms And Conditions </Tab>
                            <Tab onClick={this.PandPClick.bind(this)}>Privacy Policy</Tab>
                            <Tab onClick={this.gdprClick.bind(this)}>GDPR</Tab>
                        </h2>

                    </TabList>

                    <TabPanel>
                        <div className="contentBg">
                            { ReactHtmlParser(this.props.termsAndConditions.webPageContent) }
                        </div>
                    </TabPanel>

                    <TabPanel>
                        <div className="contentBg">
                            { ReactHtmlParser(this.props.privacyPolicy.webPageContent) }
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className="contentBg">
                            { ReactHtmlParser(this.props.gdpr.webPageContent) }
                        </div>
                    </TabPanel>
                </Tabs>
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    const { hairdresser,salon,hairdresserReview,user,
        setting,termsAndConditions,privacyPolicy,gdpr } = state;
    return {
        hairdresser,
        salon,
        hairdresserReview,
        user,
        setting,
        termsAndConditions,privacyPolicy,gdpr
    };
}

export default connect(mapStateToProps)(Legal);
