import promise from 'bluebird'

import { bookingConstants } from '../_constants';

import { bookingService } from "../_services";

import { ResponseFilter } from "../config/response-handler";

export const bookingActions = {
    userBooking,
    upcomingOrPastBooking,
    createBooking,
    getBooking,
    cancelBooking,
    rescheduleBooking,
    createEvent,

    getHairdresserBooking,
    hairdresserCancelBooking,
    hairdresserNoShowBooking,
    hairdresserRescheduleBooking,
    hairdresserEditBooking,
};

/************************************** getHairdresserInfo ******************************************************/


function userBooking(params) {

    return function (dispatch) {

        return new promise(function (resolve, reject) {


            dispatch(request(params));
            bookingService.userBooking(params)
                .then(response => {
                    resolve(response);
                    let { serverResponseData } = ResponseFilter(response);
                    //console.log("serverResponseData", serverResponseData);
                    dispatch(success(serverResponseData.data));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));

                })
        })
    };
    function request(userBookingRequest) { return { type: bookingConstants.USER_BOOKING_REQUEST, userBookingRequest } }
    function success(userBookingSuccess) { return { type: bookingConstants.USER_BOOKING_SUCCESS, userBookingSuccess } }
    function failure(userBookingFail) { return { type: bookingConstants.USER_BOOKING_ERROR, userBookingFail } }
}


function upcomingOrPastBooking(params) {

    return function (dispatch) {

        return new promise(function (resolve, reject) {


            dispatch(request(params));
            bookingService.upcomingOrPastBooking(params)
                .then(response => {
                    resolve(response);
                    let { serverResponseData } = ResponseFilter(response);
                    //console.log("hairdresserReview serverResponseData", serverResponseData);
                    dispatch(success(serverResponseData.data));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));

                })
        })
    };
    function request(getUpcomingOrPastBookingRequest) { return { type: bookingConstants.UPCOMING_OR_PAST_BOOKING_REQUEST, getUpcomingOrPastBookingRequest } }
    function success(getUpcomingOrPastBookingSuccess) { return { type: bookingConstants.UPCOMING_OR_PAST_BOOKING_SUCCESS, getUpcomingOrPastBookingSuccess } }
    function failure(getUpcomingOrPastBookingFail) { return { type: bookingConstants.UPCOMING_OR_PAST_BOOKING_FAILURE, getUpcomingOrPastBookingFail } }
}


function createBooking(params) {

    return function (dispatch) {

        return new promise(function (resolve, reject) {


            dispatch(request(params));
            bookingService.createBooking(params)
                .then(response => {
                    resolve(response);
                    let { serverResponseData } = ResponseFilter(response);
                    //console.log("serverResponseData", serverResponseData);
                    dispatch(success(serverResponseData.data));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));

                })
        })
    };
    function request(userCreateBookingRequest) { return { type: bookingConstants.USER_CREATE_BOOKING_REQUEST, userCreateBookingRequest } }
    function success(userCreateBookingSuccess) { return { type: bookingConstants.USER_CREATE_BOOKING_SUCCESS, userCreateBookingSuccess } }
    function failure(userCreateBookingFail) { return { type: bookingConstants.USER_CREATE_BOOKING_ERROR, userCreateBookingFail } }
}

function createEvent(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            dispatch(request(params));
            bookingService.createEvent(params)
                .then(response => {
                    resolve(response);
                    let { serverResponseData } = ResponseFilter(response);
                    dispatch(success(serverResponseData.data));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));

                })
        })
    };
    function request(userCreateEventRequest) { return { type: bookingConstants.USER_CREATE_EVENT_REQUEST, userCreateEventRequest } }
    function success(userCreateEventSuccess) { return { type: bookingConstants.USER_CREATE_EVENT_SUCCESS, userCreateEventSuccess } }
    function failure(userCreateEventFail) { return { type: bookingConstants.USER_CREATE_EVENT_ERROR, userCreateEventFail } }
}

function getBooking(params) {

    return function (dispatch) {

        return new promise(function (resolve, reject) {


            dispatch(request(params));
            bookingService.getBooking(params)
                .then(response => {
                    resolve(response);
                    let { serverResponseData } = ResponseFilter(response);
                    //console.log("serverResponseData", serverResponseData);
                    dispatch(success(serverResponseData.data));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));

                })
        })
    };
    function request(userBookingRequest) { return { type: bookingConstants.USER_BOOKING_REQUEST, userBookingRequest } }
    function success(userBookingSuccess) { return { type: bookingConstants.USER_BOOKING_SUCCESS, userBookingSuccess } }
    function failure(userBookingFail) { return { type: bookingConstants.USER_BOOKING_ERROR, userBookingFail } }
}

function cancelBooking(params) {

    return function (dispatch) {

        return new promise(function (resolve, reject) {


            //dispatch(request(params));
            bookingService.cancelBooking(params)
                .then(response => {
                    resolve(response);
                    // let { serverResponseData } = ResponseFilter(response);
                    // //console.log("serverResponseData", serverResponseData);
                    // dispatch(success(serverResponseData.data));
                })
                .catch((err) => {
                    reject(err);
                    //dispatch(failure(err));

                })
        })
    };
    function request(userBookingRequest) { return { type: bookingConstants.USER_BOOKING_REQUEST, userBookingRequest } }
    function success(userBookingSuccess) { return { type: bookingConstants.USER_BOOKING_SUCCESS, userBookingSuccess } }
    function failure(userBookingFail) { return { type: bookingConstants.USER_BOOKING_ERROR, userBookingFail } }
}

function rescheduleBooking(params) {

    return function (dispatch) {

        return new promise(function (resolve, reject) {


            //dispatch(request(params));
            bookingService.rescheduleBooking(params)
                .then(response => {
                    resolve(response);
                    // let { serverResponseData } = ResponseFilter(response);
                    // //console.log("serverResponseData", serverResponseData);
                    // dispatch(success(serverResponseData.data));
                })
                .catch((err) => {
                    reject(err);
                    //dispatch(failure(err));

                })
        })
    };
    function request(userBookingRequest) { return { type: bookingConstants.USER_BOOKING_REQUEST, userBookingRequest } }
    function success(userBookingSuccess) { return { type: bookingConstants.USER_BOOKING_SUCCESS, userBookingSuccess } }
    function failure(userBookingFail) { return { type: bookingConstants.USER_BOOKING_ERROR, userBookingFail } }
}

function getHairdresserBooking(params) {

    return function (dispatch) {

        return new promise(function (resolve, reject) {

            dispatch(request(params));
            bookingService.getHairdresserBooking(params)
                .then(response => {
                    resolve(response);
                    let { serverResponseData } = ResponseFilter(response);
                    //console.log("serverResponseData", serverResponseData);
                    dispatch(success(serverResponseData.data));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));
                })
        })
    };
    function request(hairdresserBookingRequest) { return { type: bookingConstants.HAIRDRESSER_BOOKING_REQUEST, hairdresserBookingRequest } }
    function success(hairdresserBookingSuccess) { return { type: bookingConstants.HAIRDRESSER_BOOKING_SUCCESS, hairdresserBookingSuccess } }
    function failure(hairdresserBookingFail) { return { type: bookingConstants.HAIRDRESSER_BOOKING_ERROR, hairdresserBookingFail } }
}

function hairdresserCancelBooking(params) {
    //console.log("params",params)
    return function (dispatch) {

        return new promise(function (resolve, reject) {

            //dispatch(request(params));
            bookingService.hairdresserCancelBooking(params)
                .then(response => {
                    resolve(response);
                    // let { serverResponseData } = ResponseFilter(response);
                    // //console.log("serverResponseData", serverResponseData);
                    // dispatch(success(serverResponseData.data));
                })
                .catch((err) => {
                    reject(err);
                    //dispatch(failure(err));

                })
        })
    };
    // function request(userBookingRequest) { return { type: bookingConstants.USER_BOOKING_REQUEST, userBookingRequest } }
    // function success(userBookingSuccess) { return { type: bookingConstants.USER_BOOKING_SUCCESS, userBookingSuccess } }
    // function failure(userBookingFail) { return { type: bookingConstants.USER_BOOKING_ERROR, userBookingFail } }
}

function hairdresserNoShowBooking(params) {

    return function (dispatch) {

        return new promise(function (resolve, reject) {

            //dispatch(request(params));
            bookingService.hairdresserNoShowBooking(params)
                .then(response => {
                    resolve(response);
                    // let { serverResponseData } = ResponseFilter(response);
                    // //console.log("serverResponseData", serverResponseData);
                    // dispatch(success(serverResponseData.data));
                })
                .catch((err) => {
                    reject(err);
                    //dispatch(failure(err));

                })
        })
    };
    // function request(userBookingRequest) { return { type: bookingConstants.USER_BOOKING_REQUEST, userBookingRequest } }
    // function success(userBookingSuccess) { return { type: bookingConstants.USER_BOOKING_SUCCESS, userBookingSuccess } }
    // function failure(userBookingFail) { return { type: bookingConstants.USER_BOOKING_ERROR, userBookingFail } }
}

function hairdresserRescheduleBooking(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            bookingService.hairdresserRescheduleBooking(params)
                .then(response => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    };
}

function hairdresserEditBooking(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            bookingService.hairdresserEditBooking(params)
                .then(response => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    };
}