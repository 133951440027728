import React, {Component} from 'react';
import {connect} from "react-redux";
import Popup from "reactjs-popup";
import {userActions} from "../../_actions/user.actions";
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import {settingConstants} from "../../_constants";
import {Modal} from 'react-bootstrap';

class Myaccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            CreateEditPopup: false,
            errors: {},
            newconfirmPassword: '',
            oldPassword: '',
            newPassword: '',
            ChangepasswordPopup: false,
            userInfo: props.user.userInfo,
            first_name:'',
            last_name:'',
            email:'',
            image:'',
            imagePreview:props.user.userInfo ? (props.user.userInfo.avatar && props.user.userInfo.avatar != '') ? props.user.userInfo.avatar :  require('../../assets/images/Pixie.png') : require('../../assets/images/Pixie.png'),
            passwordError: '',
            emailError: '',
            fNameError: '',
            lNameError: '',
        }
        this.toggleEditPopup = this.toggleEditPopup.bind(this);
        this.toggleChangepasswordPopup = this.toggleChangepasswordPopup.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.changePassword = this.changePassword.bind(this);
        this.handleValidation = this.handleValidation.bind(this);
        this.editProfile=this.editProfile.bind(this);
        this.onChanged = this.onChanged.bind(this);
        this.validateEmail = this.validateEmail.bind(this);
    }

    UNSAFE_componentWillReceiveProps(props) {
        let {user} = props;
        //console.log('UNSAFE_componentWillReceiveProps',user);
        if(user.userInfo) {
            this.setState({
                userInfo: user.userInfo,
                first_name: user.userInfo.first_name ? user.userInfo.first_name : user.userInfo.name,
                last_name: user.userInfo.last_name,
                email: user.userInfo.email,
                imagePreview: user.userInfo ? (user.userInfo.avatar && user.userInfo.avatar != '') ? user.userInfo.avatar :  require('../../assets/images/Pixie.png') : require('../../assets/images/Pixie.png'),
                image: '',
            })
        }
    }

    handlePasswordChange(event) {
        if (event.target.id == 'oldPassword') {
            this.setState({oldPassword: event.target.value})
        }
        if (event.target.id == 'newPassword') {
            this.setState({newPassword: event.target.value})
        }
        if (event.target.id == 'newconfirmPassword') {
            this.setState({newconfirmPassword: event.target.value})
        }
    }

    changePassword() {
        var self = this

        if (this.handleValidation()) {
            if (self.state.newPassword != self.state.newconfirmPassword) {
                document.getElementById('error').style.display = 'block'
                document.getElementById('error').innerHTML = 'Password did not match'
            } else {
                if (document.getElementById('error'))
                    document.getElementById('error').style.display = 'none'
                let {newPassword, oldPassword} = self.state
                let params = {
                    newPassword: newPassword,
                    oldPassword: oldPassword,
                }
                self.props.dispatch(userActions.changePassword(params))
                    .then(function (response) {
                        console.log('response', response);
                        if (response.data.errorCode === 0) {
                            NotificationManager.success('Succssfully updated', "Password Changed", 3000, () => {
                            })
                            self.setState({
                                CreateEditPopup: false
                            })
                        } else {
                            NotificationManager.error('Error message', response.data.messages, 3000, () => {
                            })
                        }
                    })
            }

        }
    }

    handleValidation() {
        let errors = {};
        let formIsValid = true;
        var self = this;
        if (!self.state.newPassword) {
            formIsValid = false;
            errors["newPassword"] = "New password can not be empty";
        }
        if (!self.state.oldPassword) {
            formIsValid = false;
            errors["oldPassword"] = "Old password can not be empty";
        }
        if (!self.state.newconfirmPassword) {
            formIsValid = false;
            errors["newconfirmPassword"] = "Confirm password can not be empty";
        }

        self.setState({errors: errors});
        return formIsValid;
    }

    toggleEditPopup(value) {
        this.props.dispatch({type: settingConstants.TOGGLE_EDIT_PROFILE, value: value});
    }

    toggleChangepasswordPopup(value) {
        this.props.dispatch({type: settingConstants.TOGGLE_CHANGE_PASSWORD, value: value});
    }

    editProfile(){
        let self = this;
        let email = this.state.email;
        let image = this.state.image;
        let first_name = this.state.first_name;
        let last_name = this.state.last_name;
        let params = {
            email: email,
            image: image,
            first_name: first_name,
            last_name: last_name,
            name: first_name + " " + last_name,
            deviceToken: 'sdadsadsada',
            deviceTypeId: 1,
            defaultRedirectTo:0
        }

        let data = new FormData();
        data.append('email', email);
        data.append('image', image);
        data.append('first_name', first_name);
        data.append('last_name', last_name);
        data.append('name', first_name + " " + last_name);
        data.append('deviceToken', 'sdfsfdsfds');
        data.append('deviceTypeId', 1);
        data.append('defaultRedirectTo', 0);


        if (this.state.first_name === "" || this.state.first_name === undefined) {
            this.setState({
                fNameError: 'Please enter first name'
            })
        } else if (this.state.last_name === "" || this.state.last_name === undefined) {
            this.setState({
                lNameError: 'Please enter last name'
            })
        } else if (this.state.email === "" || this.state.email === undefined) {
            this.setState({
                emailError: "Please enter your email address"
            })
        } else if (!this.validateEmail(this.state.email)) {
            this.setState({
                emailError: 'Please enter a valid email address'
            })
        } else {
            self.props.dispatch(userActions.editProfile(data))
                .then(function (response) {
                    console.log('response',response);
                    if (response.data.errorCode === 0) {
                        self.props.dispatch(userActions.profile(params));
                        self.toggleEditPopup(2);
                    } else {
                        self.setState({
                            error: response.data.messages
                        })
                    }
                })
        }
    }

    validateEmail(email) {
        const pattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const result = pattern.test(email);
        return result;
    }

    onChanged(e) {
        this.setState({[e.target.name]: e.target.value, copied: false});

        if (e.target.name == 'userImage') {
            let reader = new FileReader();
            let file = e.target.files[0];

            reader.onloadend = () => {
                this.setState({
                    imagePreview: reader.result
                });
            }
            reader.readAsDataURL(file)


            this.setState({
                imagePreview: file
            })
            this.setState({
                image: e.target.files[0]
            });
        }

        if (e.target.name === 'email') {
            if (!this.validateEmail(e.target.value)) {
                this.setState({
                    emailError: 'Please enter a valid email address'
                })
            } else {
                this.setState({
                    emailError: ''
                })
            }
        }
        if (e.target.name === 'password') {
            if (e.target.value === '' || e.target.value === null) {
                this.setState({
                    passwordError: ''
                })
            } else {
                this.setState({
                    passwordError: '',
                })
            }
        }

        if (e.target.name === 'first_name') {
            if (e.target.value === '' || e.target.value === null) {
                this.setState({
                    fNameError: ''
                })
            } else {
                this.setState({
                    fNameError: '',
                })
            }
        }

        if (e.target.name === 'last_name') {
            if (e.target.value === '' || e.target.value === null) {
                this.setState({
                    fNameError: ''
                })
            } else {
                this.setState({
                    lNameError: '',
                })
            }
        }
    }

    render() {
        console.log('state=====',this.state);
        return (
            <React.Fragment>
                <h2 className="myprofile_h2">My Profile </h2>

                <div className="contentBg pad0">
                    <NotificationContainer/>
                    <div className="row cm_pos_relative_">
                        <div className="col-sm-8 order-2 order-sm-1 account-table">
                            <table>
                                <tbody>
                                <tr>
                                    <td>First Name</td>
                                    <td>{this.state.userInfo ? this.state.userInfo.first_name ? this.state.userInfo.first_name : this.state.userInfo.name : ''}</td>
                                </tr>
                                <tr>
                                    <td>Last Name</td>
                                    <td>{this.state.userInfo ? this.state.userInfo.last_name ? this.state.userInfo.last_name : '' : ''}</td>
                                </tr>
                                <tr>
                                    <td>Email Address</td>
                                    <td>{this.state.userInfo ? this.state.userInfo.email : ''}</td>
                                </tr>
                                <tr>
                                    <td>Password</td>
                                    <td>*******</td>
                                </tr>
                                </tbody>
                            </table>
                            <div className="btnsml-screen-center hideBigscreen">
                                <button class="btn btn-edit btn-sm width-auto my_pro"
                                        onClick={this.toggleEditPopup.bind(this,1)}>Edit Profile
                                </button>
                            </div>

                        </div>
                        <div className="col-sm-4 order-1 order-sm-2">
                            <div className="account_img"><img
                                src={this.state.imagePreview}
                                className="cm_team-round-img"/>
                                </div>
                            <div className="btnsml-screen-center hideSmallscreen">
                                <button class="btn btn-edit btn-sm width-auto my_pro"
                                        onClick={this.toggleEditPopup.bind(this,1)}>Edit Profile
                                </button>
                            </div>

                        </div>
                       
                    </div>
                    {/* <!------------------------edit popup-----------------------------> */}
                    <Modal show={this.props.setting.editprofile}   onHide={this.toggleEditPopup.bind(this, 2)} className="popup-content profile">
                    {/* <Popup className="editmyaccount" open={this.props.setting.editprofile}
                        //onClose={this.toggleBooking.bind(this,2)}
                    > */}
                        <div className="cm_modal" style={{overflow: 'unset'}}>

                            <div className="modal-header" style={{borderBottom: 'none'}}>
                            <h5 class="serviceheader">My Profile </h5>
                                <button type="button" className="close"
                                        onClick={this.toggleEditPopup.bind(this, 2)}>&times;</button>
                            </div>
                            <div className="modal-body ">
                                <div className="row">
                                <div className="col-md-4" />
                                <div className="col-md-4">
                                        <div className="account_img">
                                            <img src={this.state.imagePreview} className="cm_team-round-img"/>
                                          
                                            <input ref={(ref) => this.userImage = ref}
                                                   type="file"
                                                   name="userImage"
                                                   onChange={this.onChanged}
                                                   id="userImage"
                                                   className="img-browse"
                                            />
                                              <i className="fa fa-camera uplod_fafa" aria-hidden="true" onClick={(e) => this.userImage.click()}></i>
                                        </div>


                                    </div>

                                    <div className="col-md-12 account-table">
                                    <div class="row">
                                        <div class="col-sm-4 border-right accountleft"><p>First Name</p></div>
                                        <div class="col-sm-8"> 
                                        <input type="text" class="form-control cm_form"
                                                           placeholder="First Name..." name="first_name" autoComplete="off"
                                                           value={this.state.first_name}
                                                           onChange={this.onChanged}/>
                                                    {this.state.fNameError ?
                                                        <span className="cm_error_msg">{this.state.fNameError}</span> : ''}
                                        </div>
                                     </div>
                                     <div class="row">
                                        <div class="col-sm-4 border-right accountleft"><p>Last Name</p></div>
                                        <div class="col-sm-8"> 
                                        <input type="text" class="form-control cm_form"
                                                           placeholder="Last Name..." name="last_name" autoComplete="off"
                                                           value={this.state.last_name} onChange={this.onChanged}/>
                                                    {this.state.lNameError ?
                                                        <span className="cm_error_msg">{this.state.lNameError}</span> : ''}
                                        </div>
                                     </div>
                                     <div class="row">
                                        <div class="col-sm-4 border-right accountleft"><p>Email Address</p></div>
                                        <div class="col-sm-8"> 
                                        <input type="text" class="form-control cm_form"
                                                           placeholder="Email..." name="email" value={this.state.email} onChange={this.onChanged}/>
                                                    {this.state.emailError ?
                                                        <span className="cm_error_msg">{this.state.emailError}</span> : ''}
                                        </div>
                                     </div>
                                     <div class="row">
                                        <div class="col-sm-4 border-right accountleft"><p>Password</p></div>
                                        <div class="col-sm-8"> 
                                        <span onClick={this.toggleChangepasswordPopup.bind(this,1)}>Change Password</span>
                                        </div>
                                     </div>





                                       

                                    </div>
                                    <div className="col-md-12 text-center">
                                        <div className="btnsml-screen-center">
                                            <button class="btn btn-edit btn-sm save-btn" onClick={this.editProfile.bind(this)}>Save</button>
                                        </div>


                                    </div>
                                </div>

                                <br/><br/>

                            </div>


                        </div>


                    </Modal>
                </div>

                <Popup
                    open={this.props.setting.changePass}
                >
                    <div className="cm_modal cm_calendar popupscroll">

                        <div className="modal-header" style={{borderBottom: 'none'}}>

                            <button type="button" className="close"
                                    onClick={this.toggleChangepasswordPopup.bind(this, 2)}>&times;</button>
                        </div>
                        <div className="modal-body ">

                            <h3 class="text-center">Change Password</h3>
                            <div class="form-group">
                                <input type="password"
                                       class="form-control circleform"
                                       name="oldPassword"
                                       placeholder="Old Password"
                                       id="oldPassword"
                                       value={this.state.oldPassword}
                                       onChange={this.handlePasswordChange}
                                       autocomplete="off"/>
                                <span style={{color: "red"}}>{this.state.errors["oldPassword"]}</span>
                            </div>

                            <div class="form-group">
                                <input type="password"
                                       class="form-control circleform"
                                       name="newPassword"
                                       id="newPassword"
                                       placeholder="New Password"
                                       value={this.state.newPassword}
                                       onChange={this.handlePasswordChange}
                                       autocomplete="off"/>
                                <span style={{color: "red"}}>{this.state.errors["newPassword"]}</span>
                            </div>

                            <div class="form-group">
                                <input type="password"
                                       class="form-control circleform"
                                       name="newconfirmPassword"
                                       id="newconfirmPassword"
                                       placeholder="Confirm New Password"
                                       value={this.state.newconfirmPassword}
                                       onChange={this.handlePasswordChange}
                                       autocomplete="off"/>
                                <span style={{color: "red"}}>{this.state.errors["newconfirmPassword"]}</span>
                            </div>

                            <div id="error" style={{color: "red"}}></div>
                            <div style={{color: "red"}}>{this.state.error}</div>

                            <div className="float-right">
                                <button class="btn btn-edit btn-sm save-btn" onClick={this.changePassword}>Save</button>
                            </div>
                            <br/><br/>

                        </div>


                    </div>


                </Popup>

            </React.Fragment>)
    }
}


function mapStateToProps(state) {
    const {
        hairdresser, salon, hairdresserReview, user,
        setting
    } = state;
    return {
        hairdresser,
        salon,
        hairdresserReview,
        user,
        setting
    };
}

export default connect(mapStateToProps)(Myaccount);
{/* */
}
