import { hairdresserConstants } from '../_constants';

const initialReviewState = {
  review: [],
};
export function hairdresserReview(state = initialReviewState, action) {
  //console.log('action.type',action);
  switch (action.type) {

    case hairdresserConstants.HAIRDRESSER_REVIEW_SUCCESS:
      // console.log('HAIRDRESSER_REVIEW_SUCCESS',action.getHairdresserReviewSuccess);
      let hairdresserReview=action.getHairdresserReviewSuccess;
      return hairdresserReview;

    case hairdresserConstants.HAIRDRESSER_INFO_ERROR:
      return {};

    case hairdresserConstants.HAIRDRESSER_INFO_CLEAR:
      return {};

    default:
      return state
  }
}
