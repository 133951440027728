import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import moment from 'moment';
import { Tab, Tabs, Dropdown, Modal } from 'react-bootstrap';
import SideNav from '../template/SideNav';
import TopHeader from '../template/TopHeader';
import ClientCheckout from './ClientCheckout';
// import Invoice from './Invoice';
// import Checkout from './Checkout';
import { connect } from "react-redux";
import { bookingActions, hairdresserActions, userActions } from "../../_actions";
import siteSetting from "../../config/env";
import { Addicon } from "../SvgIcon";
import { bookingConstants, settingConstants } from "../../_constants";
import dateFormat from "dateformat";
import ReactWeeklyDayPicker from "react-weekly-day-picker";
import Checkout from "./Checkout";
import {bookingService} from "../../_services";
import Invoice from "./Invoice";
var _ = require('lodash');

let userIdHolder = [];
const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];

function getslotTime(time) {
    let hr = parseInt(time / 60);
    let min = time - (hr * 60)
    return hr + ':' + (min === 0 ? '00' : min);
}

class Booking extends Component {

    constructor(props) {
        super(props);

        this.state = {
            bookingDate: new Date(),
            selectSlot: '',
            showOptionsModal: false,
            showTipModal: false,
            latitude: siteSetting.default_latitude,
            longitude: siteSetting.default_longitude,
            hairdresserName: 'test',
            selectedService: [],
            finalBookingSlots: [],
            includeTimes: [],
            slotInfo: {},
            totalAmount: 0,
            totalTime: 0,
            hairdresserId: '',
            selectedClientId: '',
            selectedClient: {},
            isSaveBooking: 0,
            bookingSlots: [],
            paymentType: '',
            isBookingAllowed: false,
            searchKey: '',
            bookingType:'past', //upcoming
            pendingAmount:0,
            transaction:[],
            paymentAmount:0,
            totalPaymentAmount:0,
            isCheckout:0,
            userId:'',
            isInvoice:0,
            currencySymbol:"£"
        }
        this.createBooking = this.createBooking.bind(this);
        this.resetBooking = this.resetBooking.bind(this);
        this.checkoutBooking=this.checkoutBooking.bind(this);
        this.serPaymentType=this.serPaymentType.bind(this);
        this.onChanged=this.onChanged.bind(this);
        this.updateBooking=this.updateBooking.bind(this);
        this.completeSale=this.completeSale.bind(this);
        this.cancelBooking=this.cancelBooking.bind(this);
        this.noShowBooking=this.noShowBooking.bind(this);
        this.rescheduleBooking=this.rescheduleBooking.bind(this);
    }


    handleOptionModal = () => this.setState({ showOptionsModal: !this.state.showOptionsModal })

    handleTipModal = () => {
        this.setState({
            showOptionsModal: false,
            showTipModal: !this.state.showTipModal
        })
    }

    componentDidMount() {
        var self = this;
        let params = {};
        if (localStorage.getItem('accessToken')&&this.props.match.params.bookingId!=='') {
            //self.props.dispatch(userActions.profile(params))
            let getparams = {
                bookingID:this.props.match.params.bookingId,
                getBookingsOfType:'updates'
            }
            self.props.dispatch(bookingActions.getHairdresserBooking(getparams))
                .then(function (response) {
                    console.log("response",response.data.data);
                    self.setState({
                        selectedClient:response.data.data.bookings[0].user,
                        selectedClientId:response.data.data.bookings[0].userId
                    })
                    let totalTime=0,totalAmount=0;
                    response.data.data.bookings[0].servicesBooked.map((services, i) => {
                        totalTime=totalTime+services.serviceTime;
                        totalAmount=totalAmount+parseFloat(services.servicePrice);
                    })
                    let totalPaidAmount=0;
                    if(response.data.data.bookings[0].transaction&&response.data.data.bookings[0].transaction.length>0) {
                        response.data.data.bookings[0].transaction.map((transaction, i) => {
                            totalPaidAmount=totalPaidAmount+parseFloat(transaction.amount)
                        })
                    }
                    let pendingAmount=totalAmount-totalPaidAmount;
                    let paymentAmount=pendingAmount;
                    self.setState({
                        totalAmount: totalAmount.toFixed(2),
                        totalTime: totalTime,
                        pendingAmount:pendingAmount,
                        paymentAmount,
                        hairdresserId:response.data.data.bookings[0].hairdresserId,
                        userId:response.data.data.bookings[0].userId,
                        currencySymbol:response.data.data.bookings[0].hairdresser.hairdresser&&response.data.data.bookings[0].hairdresser.hairdresser.currencySymbol&&response.data.data.bookings[0].hairdresser.hairdresser.currencySymbol!==""?response.data.data.bookings[0].hairdresser.hairdresser.currencySymbol:"£"
                    })
                    self.props.dispatch(hairdresserActions.getHairdresserClientDetail({
                        'userId': response.data.data.bookings[0].userId,
                        'hairdresserId':response.data.data.bookings[0].hairdresserId,
                        'bookingType':self.state.bookingType
                    }))
                })
        } else {
            this.props.history.push('/')
        }
    }

    getslotTime(time) {
        let hr = parseInt(time / 60);
        let min = time - (hr * 60)
        return hr + ':' + (min === 0 ? '00' : min);
    }

    createBooking(param) {
        let self = this;
        console.log('createBooking');
        // console.log(localStorage.getItem('accessToken'))

        console.log(this.state.selectedService);

        let params = {};
        params.servicesToBook = _.map(this.state.selectedService, function (o) {
            return o.serviceId;
        })
        params.userId = this.state.hairdresserId;
        params.bookingSlot = [this.state.slotInfo.startTime, (this.state.slotInfo.startTime + this.state.totalTime)]
        params.bookingDate = dateFormat(this.state.bookingDate, 'yyyy-mm-dd')
        params.deviceToken = "dfdsfdsfsd"
        params.isPayAtSalonBooking = 1;
        params.clientId = '';
        params.paidType = this.state.paidType;

        self.props.dispatch(bookingActions.createBooking(params))
            .then(function (response) {
                console.log('booking response', response);


                if (response.data.errorCode) {
                    self.setState({
                        error: response.data.messages
                    })
                } else {
                    if (param === 0) {
                        self.props.history.push('/diary/' + localStorage.getItem('salonId'))
                    } else {
                        //self.props.history.push('/new-booking/' + localStorage.getItem('salonId'))
                        window.location.reload();
                    }
                }
            })
    }

    resetBooking() {
        this.setState({
            bookingDate: new Date(),
            selectSlot: new Date(),
            showOptionsModal: false,
            showTipModal: false,
            latitude: siteSetting.default_latitude,
            longitude: siteSetting.default_longitude,
            hairdresserName: 'Select Staff Member',
            selectedService: [],
            finalBookingSlots: [],
            includeTimes: [],
            slotInfo: {},
            totalAmount: 0,
            totalTime: 0,
            hairdresserId: '',
            selectedClientId: '',
            selectedClient: {},
            isSaveBooking: 0,
            bookingSlots: [],
            paymentType: '',
            searchKey: ''
        })
    }

    changeClient() {
        this.setState({
            selectedClientId: '',
            selectedClient: {},
            isSaveBooking: 0,
            bookingSlots: [],
            paymentType: '',
            searchKey: ''
        })
    }

    handleEvent(e, d) {
        if (e === "eventStartTime") {
            let m = moment(d);
            let minutes = (m.hour() * 60) + m.minute();
            this.setState({ eventTime: d, eventStartTime: minutes })
        }
        if (e === "eventEndTime") {
            let m = moment(d);
            let minutes = (m.hour() * 60) + m.minute();
            this.setState({ endTime: d, eventEndTime: minutes })
        }
        if (typeof e === "object" && e.target.id === "eventTitle") {
            this.setState({ eventTitle: e.target.value })
        }
        if (typeof e === "object" && e.target.id === "isBookingAllowed") {
            this.setState({ isBookingAllowed: !this.state.isBookingAllowed })
        }
    }

    checkoutBooking(){
        this.setState({
            isCheckout:1
        })
    }

    serPaymentType(paymentType) {
        let transaction=this.state.transaction;

        transaction.push({
            paymentType:paymentType,
            amount:parseFloat(this.state.paymentAmount)
        })
        let totalPaymentAmount=parseFloat(this.state.totalPaymentAmount)+parseFloat(this.state.paymentAmount);
        let pendingAmount=parseFloat(this.state.totalAmount)-totalPaymentAmount;
        this.setState({
            totalPaymentAmount,
            transaction: transaction,
            pendingAmount:pendingAmount,
            paymentAmount:pendingAmount
        })
    }

    onChanged(e) {
        console.log("e",e.target);
        this.setState({ [e.target.name]: parseFloat(e.target.value)});

        if (e.target.name === 'pendingAmount') {
            let totalPaymentAmount=parseFloat(this.state.totalPaymentAmount)+parseFloat(e.target.value);
            let pendingAmount=parseFloat(this.state.totalAmount)-totalPaymentAmount;
            if (parseFloat(e.target.value) > pendingAmount) {
                this.setState({
                    paymentAmountError: 'Invalid Amount'
                })
            } else {
                this.setState({
                    paymentAmountError: '',
                })
            }
        }
    }

    updateBooking() {
        let params = {};
        params.servicesToBook = _.map(this.state.selectedService, function (o) {
            return o.serviceId;
        })
        params.userId = this.state.hairdresserId;
        params.transaction = this.state.transaction;
        //params.totalAmount = parseFloat(this.state.totalAmount).toFixed(2);
        params.bookingID=this.props.match.params.bookingId;
        bookingService.updateBooking(params)
            .then(function (response) {
                console.log('client response', response);
                window.location.reload();
            })
    }

    completeSale(){
        this.setState({isInvoice: 1})
    }

    noShowBooking(){
        let self=this;
        let params={}
        params.hairdresserId = this.state.hairdresserId;
        params.bookingID=this.props.match.params.bookingId;
        params.deviceToken = "dfdsfdsfsd";

        self.props.dispatch(bookingActions.hairdresserNoShowBooking(params))
            .then(function (response) {
                console.log('booking response', response);

                if (response.data.errorCode) {
                    self.setState({
                        error: response.data.messages
                    })
                } else {
                    //self.props.history.push('/diary/' + localStorage.getItem('salonId'))
                    window.location.reload();
                }
            })
    }

    cancelBooking(){
        let self=this;
        let params={}
        params.hairdresserId = self.state.hairdresserId;
        params.bookingID=this.props.match.params.bookingId;
        params.deviceToken = "dfdsfdsfsd";

        self.props.dispatch(bookingActions.hairdresserCancelBooking(params))
            .then(function (response) {
                console.log('booking response', response);

                if (response.data.errorCode) {
                    self.setState({
                        error: response.data.messages
                    })
                } else {
                    self.props.history.push('/diary/' + localStorage.getItem('salonId'))
                    //window.location.reload();
                }
            })
    }

    rescheduleBooking(){
        let self=this;
        let params={}
        params.hairdresserId = this.state.hairdresserId;
        params.bookingID=this.props.match.params.bookingId;
        params.deviceToken = "dfdsfdsfsd";

        self.props.dispatch(bookingActions.hairdresserRescheduleBooking(params))
            .then(function (response) {
                console.log('booking response', response);

                if (response.data.errorCode) {
                    self.setState({
                        error: response.data.messages
                    })
                } else {
                    //self.props.history.push('/diary/' + localStorage.getItem('salonId'))
                    window.location.reload();
                }
            })
    }

    render() {
        // console.log("this.props",this.props);
        // console.log("this.state", this.state);

        return (
            <div className="h_n">

                    <div className="body_container bg_white">
                        <TopHeader title="View Booking" {...this.props} />
                        <SideNav {...this.props} />
                        {this.props.hairdresserBooking.bookings ?
                        <div className="booking-parent">

                            <div className="tab_content_wrapper">

                                {this.state.isInvoice===1 ? (
                                        <div className="invoice_card_wrapper">
                                            <div className="cm_head">
                                                <h3>Invoice - {moment().format("YYYYMMDDHHmmSS")}</h3>
                                                <p className="cm_fw_b">{moment().format("dddd, MMM Do YY")}</p>
                                            </div>

                                            <div className="cm_body">
                                                <ul>

                                                    {this.state.transaction.map((transaction, index) => (
                                                        <li key={"t_"+index}>{transaction.paymentType === 1 ? 'Pay by App' : ''}{transaction.paymentType === 2 ? 'Card' : ''}{transaction.paymentType === 3 ? 'Cash' : ''}{transaction.paymentType === 4 ? 'Other' : ''} - {this.state.currencySymbol}{transaction.amount.toFixed(2)}</li>
                                                    ))}
                                                    {/*<li>Tip - £4.20</li>*/}
                                                    <li>Total - {this.state.currencySymbol}{parseFloat(this.state.totalAmount).toFixed(2)}</li>

                                                </ul>
                                            </div>

                                            <div className="cm_footer">
                                                <h2>Balance - {this.state.currencySymbol}{parseFloat(this.state.pendingAmount).toFixed(2)}</h2>
                                            </div>
                                        </div>)

                                :(<div>

                                    <div className="cm_picker_wrap">
                                        <p>{moment(this.props.hairdresserBooking.bookings[0].bookingDate).format('dddd DD MMM YYYY')}</p>
                                        <div></div>

                                    </div>

                                    <div className="booking_form_wrapper">

                                        <div className="cm_row m-0">
                                            <div className="cm_25">
                                                <div className="form-group">

                                                    <label>Start Time</label>
                                                    <div className="cm_picker_wrap">
                                                        <input type="text" value={this.props.hairdresserBooking.bookings[0].bookingSlot[0] ? this.getslotTime(this.props.hairdresserBooking.bookings[0].bookingSlot[0]) : ""} className="form-control"  />

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="cm_75">
                                                <div className="form-group">
                                                    <label>Staff Member</label>

                                                    <div className="cm__boxs">
                                                        <div className="cm__box" >

                                                        <div className="table-responsive  cm_table">
                                                            <table>
                                                                <tbody>

                                                                        <tr >
                                                                            <td className="t_width">{this.props.hairdresserBooking.bookings[0].hairdresser.name}</td>
                                                                        </tr>

                                                                </tbody>
                                                            </table>
                                                        </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="cm_row">
                                            <div className="cm_25">
                                                <div className="form-group">
                                                    <label>Duration</label>
                                                    <input type="text" className="form-control" value={this.state.totalTime + " m"}  />
                                                </div>
                                            </div>
                                            <div className="cm_75">
                                                <label>Service</label>
                                                <div className="cm__boxs">
                                                    <div className="cm__box" >
                                                        <div className="table-responsive  cm_table">
                                                            <table>
                                                                <tbody>
                                                                {this.props.hairdresserBooking.bookings && this.props.hairdresserBooking.bookings[0].servicesBooked.length > 0 ?
                                                                    this.props.hairdresserBooking.bookings[0].servicesBooked.map((services, i) => (
                                                                        <tr key={services._id}>
                                                                            <td className="t_width">{services.service.serviceName}</td>
                                                                            <td>{services.serviceTime}m</td>
                                                                            <td>{this.state.currencySymbol}{services.servicePrice.toFixed(2)}</td>

                                                                        </tr>
                                                                    ))
                                                                    :
                                                                    <div className="rating-header nodiv">
                                                                        <p>No Services Offered </p>
                                                                    </div>
                                                                }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    {this.state.transaction.length > 0?
                                        <div className="cm_width_25">
                                            <div className="cm_body">
                                                <ul>

                                                    {this.state.transaction.map((transaction, index) => (
                                                        <li key={"t_"+index}>{transaction.paymentType === 1 ? 'Pay by App' : ''}{transaction.paymentType === 2 ? 'Card' : ''}{transaction.paymentType === 3 ? 'Cash' : ''}{transaction.paymentType === 4 ? 'Other' : ''} - {this.state.currencySymbol}{transaction.amount.toFixed(2)}</li>
                                                    ))}
                                                    {/*<li>Tip - £4.20</li>*/}
                                                    <li>Total - {this.state.currencySymbol}{parseFloat(this.state.totalAmount).toFixed(2)}</li>

                                                </ul>
                                            </div>
                                            <div />

                                            <div className="cm_footer">
                                                <h2>Balance - {this.state.currencySymbol}{parseFloat(this.state.pendingAmount).toFixed(2)}</h2>
                                            </div>
                                        </div>:<div className="cm_width_25">
                                            <div className="cm_body">
                                                <ul>

                                                    {this.props.hairdresserBooking.bookings[0].transaction&&this.props.hairdresserBooking.bookings[0].transaction.length>0?
                                                        this.props.hairdresserBooking.bookings[0].transaction.map((transaction, index) => (
                                                            <li key={"t_"+index}>{transaction.paymentType === 1 ? 'Pay by App' : ''}{transaction.paymentType === 2 ? 'Card' : ''}{transaction.paymentType === 3 ? 'Cash' : ''}{transaction.paymentType === 4 ? 'Other' : ''} - {this.state.currencySymbol}{transaction.amount.toFixed(2)}</li>
                                                        )):""}
                                                    {/*<li>Tip - £4.20</li>*/}
                                                    <li>Total - {this.state.currencySymbol}{parseFloat(this.state.totalAmount).toFixed(2)}</li>

                                                </ul>
                                            </div>
                                            <div/>

                                            <div className="cm_footer">
                                                <h2>Balance - {this.state.currencySymbol}{this.state.pendingAmount?parseFloat(this.state.pendingAmount).toFixed(2):0}</h2>
                                            </div>
                                        </div>}


                                </div>)}

                            </div>
                            {
                                this.state.isInvoice === 0 ?
                                this.state.isCheckout === 1 ? (
                                    <Checkout saveBooking={this.saveBooking} onChangedValue={this.onChanged}
                                              pendingAmount={this.state.pendingAmount}
                                              currencySymbol={this.state.currencySymbol}
                                              paymentAmountError={this.state.paymentAmountError}
                                              selectedClient={this.state.selectedClient}
                                              selectedService={this.state.selectedService}
                                              totalAmount={this.state.totalAmount} paymentType={this.state.paymentType}
                                              serPaymentType={this.serPaymentType} resetBooking={this.resetBooking}
                                              paymentAmount={this.state.paymentAmount}
                                              completeSale={this.completeSale}
                                              hairdresserId={this.state.hairdresserId}
                                              bookings={this.props.hairdresserBooking.bookings[0]}
                                    />
                                ) :
                                <ClientCheckout selectedClientId={this.state.selectedClientId}
                                                selectedClient={this.props.hairdresserBooking.bookings ? this.props.hairdresserBooking.bookings[0].user : ""}
                                                changeClient={this.changeClient}
                                                currencySymbol={this.state.currencySymbol}
                                                pendingAmount={this.state.pendingAmount}
                                                checkoutBooking={this.checkoutBooking}
                                                userId={this.state.userId}
                                                hairdresserId={this.state.hairdresserId}
                                                noShowBooking={this.noShowBooking}
                                                cancelBooking={this.cancelBooking}
                                                rescheduleBooking={this.rescheduleBooking}
                                                bookings={this.props.hairdresserBooking.bookings[0]}
                                                history={this.props.history}

                                />
                                :
                                    (
                                        <Invoice updateBooking={this.updateBooking} selectedClient={this.state.selectedClient} selectedService={this.state.selectedService} totalAmount={this.state.totalAmount} createBooking={this.createBooking} paymentType={this.state.paymentType} resetBooking={this.resetBooking} />
                                    )
                            }

                        </div>
                            : <div className="cm_loader"><div className="loader"></div></div>}
                    </div>

            </div>

        )
    }

}

function mapStateToProps(state) {
    return {
        ...state
    };
}

export default connect(mapStateToProps)(Booking);

