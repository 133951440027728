import { hairdresserConstants } from '../_constants';


const initialState = {
  hairdresser: {},
  // hairdresserSchedule: {}
};

export function hairdresser(state = initialState, action) {
  //console.log('action.type',action);
  switch (action.type) {

    case hairdresserConstants.HAIRDRESSER_INFO_SUCCESS:
      let hairdresserInfo = action.getHairdresserInfoSuccess;
      return hairdresserInfo;
      //return {...state, hairdresserInfo};

    //
    // case hairdresserConstants.HAIRDRESSER_SCHEDULE_SUCCESS:
    //   let hairdresserSchedule = action.getHairdresserScheduleSuccess;
    //   return {...state, hairdresserSchedule};

    case hairdresserConstants.HAIRDRESSER_INFO_ERROR:
      return {};

    case hairdresserConstants.HAIRDRESSER_INFO_CLEAR:
      return {};

    default:
      return state
  }
}

