// import { NotificationManager } from 'react-notifications'
//
// import _ from 'lodash';

function ResponseFilter(response) {

  var serverResponseStatus = response.status;
  var serverResponseData = (typeof response.data != 'undefined')
    ? response.data
    : ((typeof response.error != 'undefined') ? response.error : null);

  return {
    serverResponseStatus,
    serverResponseData
  }
}


export {
  ResponseFilter,
}
