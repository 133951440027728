import axios from 'axios';
import API from "../config/API";
import siteSetting from "../config/env";

export const categoryService = {
    getAllServices,
};

var instance = axios.create({
    baseURL: siteSetting.api.url,
    //timeout: 30000,
    headers: {
        'Content-Type' : 'application/json',
        'Authorization': 'Bearer '+localStorage.getItem('accessToken'),
    }
});



function getAllServices(params) {

    let  headers= {
        'Content-Type' : 'application/json',
    }
    params.clientId=siteSetting.api_clientId;
    params.clientSecret=siteSetting.api_clientSecret;

    return axios.get(API.CATEGORY_SERVICE, {params:params,headers:headers})
}

function createSalon(params) {

    let  headers= {
        'Content-Type' : 'application/json',
    }
    params.append('clientId', siteSetting.api_clientId);
    params.append('clientSecret', siteSetting.api_clientSecret);

    return axios.post(API.SEARCH_SALON, params,{headers:headers})
}

function updateSalon(params) {

    let  headers= {
        'Content-Type' : 'application/json',
    }
    params.append('clientId', siteSetting.api_clientId);
    params.append('clientSecret', siteSetting.api_clientSecret);

    return axios.put(API.SEARCH_SALON, params,{headers:headers})
}






