import React, { Component } from 'react';
import { Router, Route, Switch } from "react-router-dom";
import { connect } from 'react-redux';
import axios from 'axios';
import { createBrowserHistory } from 'history';

import {PrivateRoute} from "./routing/PrivateRoute";

import HairdresserComponent   from "./component/hairdresser/Hairdresser";
import Dashboard              from "./component/Dashboard/Dashboard";
import Header                 from "./component/template/Header";
import Pagenotfound           from "./component/template/Pagenotfound";
import Reset                  from "./component/template/Reset";
import Homepage               from "./component/home/HomePage";
import NewBooking             from "./component/hairdresserBooking/NewBooking";
import Clients                from "./component/clients/Clients";

import './App.css';
import AddClient from './component/clients/AddClient';
import EditClient from './component/clients/EditClient';
import ClientDetail from './component/clients/ClientDetail';
import Staff from './component/staff/Staff';
import AddStaff from './component/staff/AddStaff';
import EditStaff from './component/staff/EditStaff';
import Services from './component/services/Services';
import AddService from './component/services/AddService';
import Settings from './component/settings/Settings';
import EditPassword from './component/settings/EditPassword';
import EditBankAccount from './component/settings/EditBankAccount';
import Signup from './component/hairdresserAuth/Signup';
import GoThroughModalAfterLogin from './component/hairdresserAuth/GoThroughModalAfterLogin';
import Steps from './component/hairdresserAuth/Steps';
// import HairdressersHome from './component/hairdresserBooking/HairdressersHome';
import JoinForFree from './component/hairdresserAuth/JoinForFree';
import TermsAndConditions from './component/hairdresserAuth/TermsAndConditions';
import PrivacyPolicy from './component/hairdresserAuth/PrivacyPolicy';
import HairdresserTermsAndConditions from './component/hairdresserAuth/HairdresserTermsAndConditions';
import ProfileSettings from './component/settings/ProfileSettings';
import AcountSettings from './component/settings/AcountSettings';
import ComingSoon from './component/ComingSoon';
import DiaryPage from './component/Diary/DiaryPage';
import Booking from './component/booking/Booking';
import Event from './component/events/Event';
import ClearCache from "react-clear-cache";
import EditBooking from "./component/bookingEdit/EditBooking";

export const history = createBrowserHistory();

let {isLatestVersion, emptyCacheStorage} = ClearCache

class Routes extends Component {
  constructor(props) {
    super(props);
    const { dispatch } = this.props;
    history.listen((location, action) => {

    });
  }

  render() {
    let accessToken = localStorage.getItem('accessToken');
    //console.log("######accessToken######",accessToken);
      if(accessToken && accessToken!=="") {
          axios.defaults.headers.common['Authorization'] = `${'Bearer ' + accessToken}`;
      }


    return (
        <Router history={history}>
            <ClearCache auto={true}>
            {({ isLatestVersion}) => (
            <>
            {console.log( "isLatestVersion # ",isLatestVersion,localStorage.getItem('APP_VERSION'))}
          <Header history={history}/>
          <div>
            <Switch>
                <Route exact path="/" component={Homepage}  />
                <Route path="/login" component={Homepage}/>
                <Route path="/redirect" component={Homepage}/>
                <Route path="/reset/:token" component={Reset} />
                <Route exact path="/salon/:salonId" component={HairdresserComponent} />
                <Route path="/salon/:salonId/professional/:hairdresserId" component={HairdresserComponent} />
                <Route exact path="/diary/:salonId" component={DiaryPage} />
                <Route exact path="/new-booking/:salonId" component={NewBooking} />
                <Route exact path="/new-booking/:salonId/:hairdresserId" component={NewBooking} />
                <Route exact path="/clients" component={Clients} />
                <Route exact path="/clients/add" component={AddClient} />
                <Route exact path="/clients/:id" component={ClientDetail} />
                <Route exact path="/clients/edit/:id" component={EditClient} />
                <Route exact path="/staff" component={Staff} />
                <Route exact path="/staff/add" component={AddStaff} />
                <Route exact path="/staff/:id" component={EditStaff} />
                <Route exact path="/services" component={Services} />
                <Route exact path="/services/:hairdresserId" component={Services} />
                <Route exact path="/services/:hairdresserId/:serviceId" component={AddService} />
                <Route exact path="/settings" component={Settings} />
                <Route exact path="/settings/edit-password" component={EditPassword} />
                <Route exact path="/settings/edit-bank-account" component={EditBankAccount} />
                <Route exact path="/settings/profile" component={ProfileSettings} />
                <Route exact path="/settings/account" component={AcountSettings} />
                <Route exact path="/event" component={Event} />
                <Route exact path="/join" component={JoinForFree} />
                <Route exact path="/signup" component={Signup} />
                <Route exact path="/steps" component={Steps} />
                <Route exact path="/steps-after-login" component={GoThroughModalAfterLogin} />
                {/*<Route exact path="/hairdressers-home" component={HairdressersHome}/>*/}

                <PrivateRoute path='/booking/:bookingId' component={Booking} />
                <PrivateRoute path='/booking-edit/:bookingId' component={EditBooking} />
                <PrivateRoute path='/review' component={Dashboard} />
                <PrivateRoute path='/booking' component={Dashboard} />
                <PrivateRoute path='/myaccount' component={Dashboard} />
                <PrivateRoute path='/legal' component={Dashboard} />

                <Route path="/terms-and-conditions" component={TermsAndConditions}/>
                <Route path="/privacy-policy" component={PrivacyPolicy}/>
                <Route path="/hairdresser-terms-and-conditions" component={HairdresserTermsAndConditions}/>

                <Route path='/coming-soon' component={ComingSoon}  />
                <Route path='/404' component={Pagenotfound}  />
                <Route path='*' component={Pagenotfound} status={404} />
            </Switch>
          </div>
                    </>
                    )}
            </ClearCache>
        </Router>
    );
  }
}


function mapStateToProps(state) {
  const { alert } = state;
  return {
    alert
  };
}

const connectedApp = connect(mapStateToProps)(Routes);
export { connectedApp as Routes };





