import React, { Component } from 'react';
import { connect } from "react-redux";
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"
import dateFormat from 'dateformat';
import StarRatings from 'react-star-ratings';

import siteSetting from '../../config/env'

const MapWithAMarker = withScriptjs(withGoogleMap((props) =>
    <GoogleMap
        defaultZoom={8}
        defaultCenter={{ lat: props.lat, lng: props.lng }}
    >
        {props.isMarkerShown && <Marker position={{lat: props.lat, lng: props.lng }} />}
    </GoogleMap>
))

// var dateFormat = require('dateformat');

const mapUrl="https://maps.googleapis.com/maps/api/js?key="+siteSetting.google_map_key+"&libraries=places"

class HairdresserRight extends Component{

    constructor(props) {
        super(props);
        this.state = {
            error: {},
            message: '',
            devHight: 'auto'
        };
        this.getDate=this.getDate.bind(this);
    }

    componentDidMount(){
        this.setState({
            devHight: document.getElementById('table_div').offsetHeight
        })
    }

    getDate(info) {
        if (info) {
            var date = new Date(info)
            //return date.toLocaleString()
            return dateFormat(date, "dd mmm yyyy").toLocaleString();
        }
        return null;
    }


render(){

    let {hairdresser,salon,hairdresserReview, devHight}=this.props;
    //console.log("lhjgjfhfdfgddfsfd",this.state)
    return(
       <React.Fragment>
           {salon.salonInfo&&hairdresser.userInfo?
               <div>
            <div className="mapouter">
                <div className="gmap_canvas">
                    <a href={'http://maps.google.com/?q='+salon.salonInfo.salons.loc[1]+','+salon.salonInfo.salons.loc[0]} target="_blank">
                    <MapWithAMarker
                        googleMapURL={mapUrl}
                        loadingElement={<div style={{ height: `100%` }} />}
                        containerElement={<div style={{ height: `100%` }} />}
                        mapElement={<div style={{ height: `100%` }} />}
                        isMarkerShown={true}
                        lat={salon.salonInfo.salons.loc[1]}
                        lng={salon.salonInfo.salons.loc[0]}
                    />
                    </a>
                    
                </div>
                   <div className="discription_text row">
                       <div className="col-md-8 text-left mT-4">{salon.salonInfo.salons.formattedAddress}</div>
                       {salon.salonInfo.salons.distance ?(<div className="col-md-4">{salon.salonInfo.salons.distance.toFixed(2)} mi</div>):''}
                   </div>
                       
            </div>
                   {salon.salonInfo.salons.opening_hours&&salon.salonInfo.salons.opening_hours.length>0?
            <div className="opening_time">
              <h3>Opening Hours</h3>

                  {salon.salonInfo.salons.opening_hours?
                      <div>
                          {salon.salonInfo.salons.opening_hours.map((item, i) => (
                                <div className="time-deatils" key={item.dayName}>
                                <p className="border-dot" >
                                <span className="leftspan">{item.dayName}</span>
                                <span className="rightspan">{item.sTime}-{item.eTime}</span>
                                </p>
                            </div>

                          ))
                          }
                      </div>
                      :
                      //  --------------for no opening----------------
                       <div className="time-deatils">
                          <p className="border-dot"><span className="leftspan">Opening hours have not been set</span></p>
                      </div>
                  }

            </div>
             :""}
            <div className="review">
                <h2>{hairdresser.userInfo.name}'s Reviews</h2>
                <div className="rating-header">
                    <span className="rate_number">{hairdresser.userInfo.hairdresser.reviewStats.ratingsAverage}</span>
                    <span className="Star push">
                        
                        <StarRatings
                            rating={hairdresser.userInfo.hairdresser.reviewStats.ratingsAverage?parseInt(hairdresser.userInfo.hairdresser.reviewStats.ratingsAverage):0}
                            starRatedColor="#aa9567"
                            numberOfStars={5}
                            name='rating'
                            starDimension="30px"
                            starSpacing="0px"
                        />
                       
                    </span>
                    <span className="comment_count">({hairdresser.userInfo.hairdresser.reviewStats.reviewsCount})</span>
                </div>
            </div>

            <div className="client_review" style={{height: this.state.devHight +'px'}}>
                {hairdresserReview.length>0?
                    hairdresserReview.map((reviewInfo, i) => (
                <div className="comment" key={reviewInfo._id}>
                    <div className="client_review_first">
                       <div><img src={reviewInfo.user.avatar?reviewInfo.user.avatar:reviewInfo.user.userImage?reviewInfo.user.userImage.circleImage:require('../../assets/images/client_dp.png')} className="review-circle-img"/></div>
                       <div><span>{reviewInfo.user.name}</span><br/><span>Cut & Finish</span></div>
                       <div className="push">
                           <span>
                               <StarRatings
                                   rating={reviewInfo.rating}
                                   starRatedColor="#aa9567"
                                   numberOfStars={5}
                                   name='rating'
                                   starDimension="16px"
                                   starSpacing="0px"
                               />
                            </span>
                            <br/>
                            <p className="text-right">{this.getDate(reviewInfo.createdTime)}</p>
                        </div>
                    </div>
                    <div className="client_review_second">
                        <p>{reviewInfo.review}</p>
                    </div>
                    <hr/>
                </div>
                    ))
                :
                <div className="rating-header nodiv">
                      <p>No Reviews</p>
                </div>
                }
                </div>
               </div>
               :
               ''}
       </React.Fragment>
    )
}
}
function mapStateToProps(state) {
    const { hairdresserReview,hairdresser,salon } = state;
    return {
        hairdresserReview,
        hairdresser,
        salon
    };
}

export default connect(mapStateToProps)(HairdresserRight);
