export const userConstants = {

    USER_REQUEST       : 'USER_REQUEST',
    USER_SUCCESS       : 'USER_SUCCESS',
    USER_ERROR         : 'USER_ERROR',
    USER_CLEAR         : 'USER_CLEAR',

    LOGIN_REQUEST      : 'LOGIN_REQUEST',
    LOGIN_SUCCESS      : 'LOGIN_SUCCESS',
    LOGIN_ERROR        : 'LOGIN_ERROR',

    SIGNUP_REQUEST     : 'SIGNUP_REQUEST',
    SIGNUP_SUCCESS     : 'SIGNUP_SUCCESS',
    SIGNUP_ERROR       : 'SIGNUP_ERROR',

    PROFILE_REQUEST     : 'PROFILE_REQUEST',
    PROFILE_SUCCESS     : 'PROFILE_SUCCESS',
    PROFILE_ERROR       : 'PROFILE_ERROR',

    LOGOUT_SUCCESS      :'LOGOUT_SUCCESS',

    RESET_REQUEST:'RESET_REQUEST',
    RESET_SUCCESS:'RESET_SUCCESS',
    RESET_ERROR:'RESET_ERROR',

    FORGOT_REQUEST:'FORGOT_REQUEST',
    FORGOT_SUCCESS:'FORGOT_SUCCESS',
    FORGOT_ERROR:'FORGOT_ERROR',

    REVIEW_REQUEST:'REVIEW_REQUEST',
    REVIEW_SUCCESS:'REVIEW_SUCCESS',
    REVIEW_ERROR:'REVIEW_ERROR',

    CHANGEPASSWORD_REQUEST: 'CHANGEPASSWORD_REQUEST',
    CHANGEPASSWORD_SUCCESS: 'CHANGEPASSWORD_SUCCESS',
    CHANGEPASSWORD_ERROR: 'CHANGEPASSWORD_ERROR'


};
