import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import TopHeader from '../template/TopHeader';
import SideNav from '../template/SideNav';
import {hairdresserActions, userActions} from "../../_actions";
import siteSetting from "../../config/env";
import {connect} from "react-redux";
import {Addicon} from "../SvgIcon";
import {hairdresserService} from "../../_services";

var _ = require('lodash');
let userIdHolder = [];
const newCalendar = [];
let timeSlot=[];
for(let i=0;i<24*60;i+=10){
    timeSlot.push(i)
}
const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];
class Services extends Component {
    constructor(props) {
        super(props);

        this.state = {
            latitude : siteSetting.default_latitude,
            longitude : siteSetting.default_longitude,
            hairdresserList:[],
            selectedHairdresserId:'',
            selectedHairdresser:{}
        }
        this.deleteService=this.deleteService.bind(this);
    }
    componentDidMount() {
        var self = this;
        let params = {};
        if (localStorage.getItem('accessToken')) {
            //self.props.dispatch(userActions.profile(params))


        let { dispatch } = this.props;

        let hairdresserId=localStorage.getItem('userId');
        let params1 = {
            searchKey:this.state.searchKey,
            'hairdresserId':hairdresserId,
            page:1,
            listType:1
        };
        self.props.dispatch(hairdresserActions.hairdresserStaffService(params1))
            .then(function (response) {
                console.log("response",response)
                //self.state.selectedHairdresserId===''||
                if(self.props.match.params.hairdresserId&&self.props.match.params.hairdresserId!==""){
                    let selectedHairdresser= _.find(response.data.data.total, function (o) {
                        return o._id === self.props.match.params.hairdresserId;
                    })
                    self.setState({
                        selectedHairdresserId:self.props.match.params.hairdresserId,
                        selectedHairdresser:selectedHairdresser
                    })
                    params.hairdresserId=self.props.match.params.hairdresserId
                    self.props.dispatch(hairdresserActions.getHairdresserService(params))
                        .then(function (response) {

                        });
                }else{
                    self.setState({
                        selectedHairdresserId:response.data.data.total[0]._id,
                        selectedHairdresser:response.data.data.total[0]
                    })
                    params.hairdresserId=response.data.data.total[0]._id
                    self.props.dispatch(hairdresserActions.getHairdresserService(params))
                        .then(function (response) {

                        });
                }
            })
        }else{
            this.props.history.push('/')
        }
    }

    changeHairdresser(hairdresserId, Hairdresser) {
        this.setState({
            hairdresserId,
            selectedHairdresserId:hairdresserId,
            selectedHairdresser:Hairdresser
        })

        let params = {};
        let self = this;
        params.hairdresserId = hairdresserId;

        self.props.dispatch(hairdresserActions.getHairdresserService(params))
            .then(function (response) {

            });
    }

    checkSelectedService(serviceId) {
        let selectedService = this.state.selectedService;
        let isCheck = _.find(selectedService, function (o) {
            return o._id === serviceId;
        })
        //console.log('isCheck',serviceId,isCheck)
        if (isCheck) {
            return true;
        } else {
            return false;
        }
    }

    selectService(service) {

        let serviceId=service.serviceId?service.serviceId:service._id;
        this.props.history.push('/services/'+this.state.selectedHairdresserId+'/'+serviceId)
    }

    deleteService(categoryId,service){
        // console.log("service",service);
        let self=this;
        let serviceId=service.serviceId?service.serviceId:service._id;
        let params={
            categoryId:categoryId,
            serviceId:serviceId,
            hairdresserId:this.state.selectedHairdresser.hairdresserInfo._id,
            deviceToken:'dfsdfdsf'
        };
        hairdresserService.deleteService(params)
            .then(function (response) {
                console.log('client response', response);
                if(response.data.errorCode==0){
                    self.changeHairdresser(self.state.selectedHairdresserId,self.state.selectedHairdresser)
                    self.setState({
                        successMessage:'Service has been delete successfully!'
                    })
                }
            })
    }

    render(){
        console.log("this.props",this.props);
        console.log("this.state",this.state);
        let { hairdresserServices, salon } = this.props;
        return(
            <div className="h_n">
            <div className="body_container cm_bg_white">

                <TopHeader title="Services" {...this.props}/>
                <SideNav {...this.props}/>

                {hairdresserServices.hairdresserSelectedServices && hairdresserServices.hairdresserSelectedServices.length >0 ?
                <div className="container">
                <div className="cm_box_view">

                    <div className="row d-flex flex-nowrap cm_over_auto">
                        {this.props.staffService&&this.props.staffService.total&&this.props.staffService.total.length>0?
                            this.props.staffService.total.map((hairdresser,i)=>(
                            <div className="col-lg-3 col-md-4 col-sm-6" key={hairdresser._id} onClick={this.changeHairdresser.bind(this,hairdresser._id,hairdresser)}>
                                <div className={this.state.selectedHairdresserId===hairdresser._id||(this.state.selectedHairdresserId===''&&i===0)?"cm_staff_catd active":"cm_staff_catd"}>
                                    <img src={hairdresser.userImage.circleImage ? hairdresser.userImage.circleImage : 'https://ra.ac.ae/wp-content/uploads/2017/02/user-icon-placeholder.png'} width="50px" />
                                    <h3>{hairdresser.name}</h3>
                                </div>
                            </div>
                        )):""}

                    </div>
           
                    <h3 className="cm_fw_b">{this.state.selectedHairdresser.name}’s Services</h3>

                    {/*<div className="cm_search_wrapper client_search srv_search">*/}
                    {/*    <input type="text" className="form-control" placeholder="Search…"/>*/}
                    {/*    <i className="fa fa-search"/>*/}
                    {/*</div>*/}

                    <div className="table-responsive srvc_table">
                    {hairdresserServices.hairdresserSelectedServices && hairdresserServices.hairdresserSelectedServices.length > 0 ?
                        <div className="table-responsive srvc_table">
                            <table className="table">
                                <tbody>
                                {hairdresserServices.hairdresserSelectedServices.map((category, i) => (
                                    <React.Fragment key={category._id}>
                                        {i===0?<tr>
                                                    <th><h3 className="cm_fw_l">{category.categoryName}</h3></th>
                                                    <th>Duration</th>
                                                    <th>Cost</th>
                                                </tr>:
                                    <tr>
                                        <td colSpan="4"><h3 className="cm_fw_l">{category.categoryName}</h3></td>
                                    </tr>}
                                {category.hairdresserService.map((services, i) => (
                                    <tr className="left_bg" key={services._id} >
                                    <td className="pointer-event" onClick={this.selectService.bind(this,services)}>{services.serviceName}</td>
                                    <td className="pointer-event" onClick={this.selectService.bind(this,services)}>{services.serviceTime!==0?services.serviceTime+"m":"--"}</td>
                                    <td onClick={this.selectService.bind(this,services)} className="position-relative cross_wrap pointer-event">{services.serviceTime!==0?hairdresserServices.userInfo.hairdresser.currencySymbol&&hairdresserServices.userInfo.hairdresser.currencySymbol!==""?hairdresserServices.userInfo.hairdresser.currencySymbol+services.servicePrice.toFixed(2):"£"+services.servicePrice.toFixed(2):"--"}</td>
                                    {/*<td onClick={this.serviceSelect.bind(this, services, !this.checkSelectedService(services._id))}>*/}
                                    <td className="i_width pointer-event">
                                    {services.serviceTime!==0 ? (<i className="fa fa-times"  onClick={this.deleteService.bind(this,category._id,services)}/>):""}
                                    </td>
                                    </tr>
                                    ))}

                                    </React.Fragment>
                                    ))}
                                </tbody>
                            </table>

                        </div>
                        :""
                    }

                    </div>
                    <div className="text-center mt-3">
                        <div className="mb-3">
                        Can't see your service? Request it here and we will add it.
                        </div>
                        <button onClick={()=>{window.location = "mailto:info@beuapp.com?subject=New Service Requested from "+this.props.user.userInfo.hairdresser.salon.salonName}} className="btn btn-dark">Request service</button>
                    </div>
                    </div>
                </div>
                :<div className="cm_loader"><div className="loader"></div></div>}
            </div>
            

        </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        ...state
    };
}

export default connect(mapStateToProps)(Services);
