import { hairdresserConstants } from '../_constants';


const initialState = {
};

export function staff(state = initialState, action) {
  // console.log('action.type',action);
  switch (action.type) {

    case hairdresserConstants.HAIRDRESSER_STAFF_SUCCESS:
      let hairdresserInfo = action.getHairdresserStaffSuccess;
      return hairdresserInfo;

    case hairdresserConstants.HAIRDRESSER_STAFF_ERROR:
      return {};

    case hairdresserConstants.HAIRDRESSER_STAFF_REQUEST:
      return {};

    default:
      return state
  }
}

const initialServiceState = {
};

export function staffService(state = initialServiceState, action) {
  // console.log('action.type',action);
  switch (action.type) {

    case hairdresserConstants.HAIRDRESSER_STAFFSERVICE_SUCCESS:
      let hairdresserInfo = action.getHairdresserStaffServiceSuccess;
      return hairdresserInfo;

    case hairdresserConstants.HAIRDRESSER_STAFFSERVICE_ERROR:
      return {};

    case hairdresserConstants.HAIRDRESSER_STAFFSERVICE_REQUEST:
      return {};

    default:
      return state
  }
}

const initialServiceDetailState = {
};

export function staffServiceDetail(state = initialServiceDetailState, action) {
  // console.log('action.type',action);
  switch (action.type) {

    case hairdresserConstants.HAIRDRESSER_SERVICE_DETAIL_SUCCESS:
      let hairdresserInfo = action.getHairdresserServiceDetailSuccess;
      return hairdresserInfo;

    case hairdresserConstants.HAIRDRESSER_SERVICE_DETAIL_ERROR:
      return {};

    case hairdresserConstants.HAIRDRESSER_SERVICE_DETAIL_REQUEST:
      return {};

    default:
      return state
  }
}

const initialStaffRotaState = [];

export function staffRota(state = initialStaffRotaState, action) {
  // console.log('action.type',action);
  switch (action.type) {

    case hairdresserConstants.HAIRDRESSER_STAFF_ROTA_SUCCESS:
      let hairdresserInfo = action.hairdresserStaffRotaSuccess;
      return hairdresserInfo;

    case hairdresserConstants.HAIRDRESSER_STAFF_ROTA_ERROR:
      return []

    case hairdresserConstants.HAIRDRESSER_STAFF_ROTA_REQUEST:
      return []

    default:
      return state
  }
}