import React, {Component} from 'react';
import {Tab, Tabs, Dropdown, Accordion, Card, Button} from 'react-bootstrap';
import {connect} from "react-redux";
import {hairdresserActions, userActions} from "../../_actions";
import moment from "moment";

class ClientCheckout  extends Component {

    constructor(props) {
        super(props);

        this.state = {
            bookingType:'past' //upcoming
        }
        this.getServiceName=this.getServiceName.bind(this);
        this.getBookingTime=this.getBookingTime.bind(this);
        this.getTotalPrice=this.getTotalPrice.bind(this);
    }

    componentDidMount() {
        var self = this;
        let params = {
            defaultRedirectTo:localStorage.getItem('defaultRedirectTo')
        };
        if (localStorage.getItem('accessToken')) {
            self.props.dispatch(userActions.profile(params))
        }else{
            this.props.history.push('/')
        }

        let {dispatch} = this.props;
        let hairdresserId = localStorage.getItem('userId');
        self.props.dispatch(hairdresserActions.getHairdresserClientDetail({
            'userId': this.props.selectedClient._id,
            'hairdresserId':this.props.hairdresserId,
            'bookingType':self.state.bookingType
        }))
    }

    getServiceName(booking){
        let serviceName="";
        booking.servicesBooked.map((service,i) => {
            serviceName=serviceName+(serviceName==""?"":", ");
            serviceName=serviceName+service.service.serviceName;
        })
        return serviceName;
    }

    getslotTime(time) {

        let hr = parseInt(time / 60);
        let min = time - (hr * 60);
        //console.log("time",time,hr,min);
        return hr + ':' + (min === 0 ? '00' : min);
    }

    getBookingTime(booking){
        //console.log("booking",booking.bookingSlot);
        let totalTime=booking.bookingSlot[1]-booking.bookingSlot[0];
        let hr = parseInt(totalTime / 60);
        let min = totalTime - (hr * 60);
        let showTme=totalTime>60?hr+' hr '+min+' min':min+' min'
        return this.getslotTime(booking.bookingSlot[0])+" - "+this.getslotTime(booking.bookingSlot[1])+", "+showTme
    }

    getTotalPrice(booking){
        let serviceTotalPrice=0;
        booking.servicesBooked.map((service,i) => {
            serviceTotalPrice=serviceTotalPrice+service.servicePrice;
        })
        return serviceTotalPrice.toFixed(2);
    }
    render() {
        let propes=this.props;
        return (
            propes.selectedClient ?
                (<div className="cm_right_panal client_checkout">
                    <div className="cm_bao">


                        <Dropdown>
                            <Dropdown.Toggle id="dropdown-basic">
                                <i className="fa fa-ellipsis-v"/>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => propes.changeClient()}>Change Client</Dropdown.Item>
                                {/*<Dropdown.Item href="#/action-2">Another action</Dropdown.Item>*/}
                                {/*<Dropdown.Item href="#/action-3">Something else</Dropdown.Item>*/}
                            </Dropdown.Menu>
                        </Dropdown>
                        <img
                            src={propes.selectedClient.localAvatar != propes.selectedClient.localAvatar ? "" : require('../../assets/images/client-plachoder.jpg')}/>
                        <h3>{propes.selectedClient.name}</h3>
                        <p>{propes.selectedClient.email}</p>
                    </div>


                    <Tabs defaultActiveKey="Booking" className="new-booking-tab">
                        <Tab eventKey="Booking" title="Booking">

                            <Accordion defaultActiveKey="0">
                                <Card>

                                    <Card.Header>
                                        <Accordion.Toggle as={Button} variant="link" eventKey="0">Upcoming
                                            ({this.props.clientDetail.upcomingBookings?this.props.clientDetail.upcomingBookings.length:0})</Accordion.Toggle>
                                    </Card.Header>

                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body>
                                            <ul>
                                                {this.props.clientDetail.upcomingBookings&&this.props.clientDetail.upcomingBookings.length>0?
                                                    this.props.clientDetail.upcomingBookings.map((booking,i) => (
                                                        <li className="d-flex justify-content-between" key={booking._id}>
                                                            <div className="cm_bg_uniq">
                                                                <h4>{moment(booking.bookingDate).format('DD')}</h4>
                                                                <p className="cm_fw_l">{moment(booking.bookingDate).format('MMM')}</p>
                                                            </div>
                                                            <div>
                                                                <p>{this.getServiceName(booking)}</p>
                                                                <p className="cm_fw_l">{this.getBookingTime(booking)}</p>
                                                            </div>
                                                            <div className="cm_fw_l">
                                                                <p>with {booking.hairdresser.name}</p>
                                                                <p>{propes.currencySymbol}{this.getTotalPrice(booking)}</p>
                                                            </div>
                                                        </li>)):""}
                                            </ul>
                                        </Card.Body>
                                    </Accordion.Collapse>

                                </Card>
                                <Card>

                                    <Card.Header>
                                        <Accordion.Toggle as={Button} variant="link" eventKey="1">Past
                                            ({this.props.clientDetail.pastBooking?this.props.clientDetail.pastBooking.length:0})</Accordion.Toggle>
                                    </Card.Header>

                                    <Accordion.Collapse eventKey="1">
                                        <Card.Body>
                                            <ul>
                                                {this.props.clientDetail.pastBooking&&this.props.clientDetail.pastBooking.length>0?
                                                    this.props.clientDetail.pastBooking.map((booking,i) => (
                                                        <li className="d-flex justify-content-between" key={booking._id}>
                                                            <div className="cm_bg_uniq">
                                                                <h4>{moment(booking.bookingDate).format('DD')}</h4>
                                                                <p className="cm_fw_l">{moment(booking.bookingDate).format('MMM')}</p>
                                                            </div>
                                                            <div>
                                                                <p>{this.getServiceName(booking)}</p>
                                                                <p>{this.getBookingTime(booking)}</p>
                                                            </div>
                                                            <div className="cm_fw_l">
                                                                <p>with {booking.hairdresser.name}</p>
                                                                <p>{propes.currencySymbol}{this.getTotalPrice(booking)}</p>
                                                            </div>
                                                        </li>)):""}
                                            </ul>

                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>

                        </Tab>
                        <Tab eventKey="notes" title="Notes">
                            <p>{propes.selectedClient.note?propes.selectedClient.note:"No notes"}</p>
                        </Tab>

                    </Tabs>


                    <div className="cm_btns m_revers">
                        <button className="btn btn-dark btn-block mb-3" onClick={() => propes.checkoutBooking()}>Checkout</button>
                        <button className="btn btn-primary btn-block" onClick={() => propes.saveBooking()}>Save booking</button>
                        {/*<button className="btn btn-dark btn-block mb-3" onClick={() => propes.changeClient()}>Change*/}
                        {/*    Client*/}
                        {/*</button>*/}
                    </div>

                </div>) : ""
        )
    }
}

function mapStateToProps(state) {
    return {
        ...state
    };
}

export default connect(mapStateToProps)(ClientCheckout);