import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import Select from 'react-select';
import {connect} from "react-redux";



const options = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' },
  ];


class JoinForFree extends Component {
    
    constructor(props){
        super(props);

        this.state = {
        }
    }

    render(){
        return(
            <div className="h_n auth_wrapper" style={{backgroundImage: `url(${require('../../assets/images/auth-wrapper.jpg')})`}}>
                <div className="modal_header">
                    <h3 className="m-0 text-center">Join for Free</h3>
                </div>
                
                <div className="hairdress_modal_body text-center join_for_free">
                    <img src={require('../../assets/images/beu-logo.png')} />
                    <h2 className="mb-5">Welcome Partner</h2>
                    <h3>No set up cost, no monthly subscription.<br />That’s Beu. </h3>

                    {/*<button type="submit" className="btn btn-default btn-block facebook-btn mt-4 mb-4">*/}
                    {/*    <span><img src={require('../../assets/images/facebook white.png')} /></span>*/}
                    {/*    Continue with Facebook*/}
                    {/*</button>*/}
                    {/*<br />*/}
                    {/*<h5 className="text-center">or</h5>*/}
                    {/*<br />*/}
                    <a href="/signup"><button type="button" className="btn btn-default btn-block gmail-btn mb-4">Continue with Email</button></a>

                    <p className="cm_fw_l">Already have a professional account?<br /><a href="/login" className="cm_fw_m">Sign in</a></p>

                </div>

                <div className="hairdress_modal_body text-center join_for_free mb-5 mt-5">
                    <h2 className="mb-5 cm_fw_l">Just here for a booking?</h2>
                    <h3>You’ve come to the wrong place, <a href="/login" ><span className="cm_fw_b">log in to our client booker</span></a> instead.</h3>
                </div>

            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        ...state
    };
}

export default connect(mapStateToProps)(JoinForFree);