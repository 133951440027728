import siteSetting from "./env";

export default {
    'HAIRDRESSER_PROFILE': siteSetting.api.url + "hairdresser/",
    'HAIRDRESSER_REVIEW': siteSetting.api.url + "hairdresser/",
    'SALON_PROFILE': siteSetting.api.url + "salon/",

    'LOGIN': siteSetting.api.url + "user/login",
    'USER_SIGNUP': siteSetting.api.url + "user",
    'USER_SOCIAL_LOGIN': siteSetting.api.url + "user/socialLogin",
    'USER_FORGOT': siteSetting.api.url + "user/forgot",
    'USER_RESET': siteSetting.api.url + "user/reset",
    'USER_CHANGEPASSWORD': siteSetting.api.url + "user/changePassword",

    'USER_EDITPROFILE':siteSetting.api.url +'user',

    'USER_PROFILE': "user/profile",
    'LOGOUT': "user/logout/",
    'USER_REVIEW': "userreview",

    'USER_BOOKING': "user/booking",

    'USER_CREATE_BOOKING':"/bookings",
    'USER_CREATE_EVENT': "/events",

    'SALON_LIST': siteSetting.api.url + 'hairdresser/search',


    'UPCOMING': siteSetting.api.url + 'user/',
    'SERVICES': siteSetting.api.url + 'user/category',


    'T&C':siteSetting.api.backend +'admin/company?webPageType=0',
    'P&P':siteSetting.api.backend +'admin/company?webPageType=1',
    'GDPR':siteSetting.api.backend +'admin/company?webPageType=3',
    'HT&C':siteSetting.api.backend +'admin/company?webPageType=2',

    'HAIRDRESSER_SWITCH_PROFILE':siteSetting.api.url + "user/switchProfile",

    'SEARCH_SALON':siteSetting.api.url + "salon",
    'HAIRDRESSER_SIGNUP':siteSetting.api.url + "hairdresser/",

    'CATEGORY_SERVICE': siteSetting.api.url + "user/category",

    'ADD_SERVICE': siteSetting.api.url + 'service/',

    'STAFF_ROTA': siteSetting.api.url + 'rota/emp/',

    'EVENT': siteSetting.api.url + "hairdresser/",

    'HAIRDRESSER': siteSetting.api.url + "hairdresser/",

}
