import React, { Component } from 'react';
import Popup from "reactjs-popup";
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { settingConstants, bookingConstants } from "../../_constants";
import { userActions } from "../../_actions/user.actions";
import { hairdresserActions } from "../../_actions";

import {Modal} from 'react-bootstrap';
import { Addicon } from '../SvgIcon';
import { Dropdown } from 'react-bootstrap';
import siteSetting from "../../config/env";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import AppleLogin from 'react-apple-login'
const queryString = require('query-string');
class HeaderComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: '',
            passwordError: '',
            emailError: '',
            fNameError: '',
            lNameError: '',
            email: '',
            password: '',
            first_name: '',
            last_name: '',
            dropdown: false,
            isShowClose: true,
            isLoginPopup: this.props.setting.login,
            DeletemszPopup: false,
            latitude: siteSetting.default_latitude,
            longitude:siteSetting.default_longitude,
            loginPopup:true,
            loginButton:false
        }
        this.toggleLogin = this.toggleLogin.bind(this);
        this.toggleSignup = this.toggleSignup.bind(this);
        this.login = this.login.bind(this);
        this.onChanged = this.onChanged.bind(this);
        this.validateEmail = this.validateEmail.bind(this);
        this.openDropdown = this.openDropdown.bind(this);
        this.logout = this.logout.bind(this);
        this.signup = this.signup.bind(this);
        this.toggleFrogot = this.toggleFrogot.bind(this);
        this.toggleReset = this.toggleReset.bind(this);
        this.toggleThanku = this.toggleThanku.bind(this);
        this.forgot = this.forgot.bind(this);
        this.homeOpen = this.homeOpen.bind(this);
        this.togglePartner=this.togglePartner.bind(this);
        this.toggleSocialLogin=this.toggleSocialLogin.bind(this);
        this.componentClicked=this.componentClicked.bind(this);
        this.responseFacebook=this.responseFacebook.bind(this);
    }

    closeModal = () => {
        this.setState({
            DeletemszPopup: false,
        })
    }
    openDeletemszPopup = () => {
        this.setState({
            DeletemszPopup: !this.state.DeletemszPopup
        })
    }
    componentDidMount() {
        var self = this;
        let params = {
            defaultRedirectTo:localStorage.getItem('defaultRedirectTo')
        };
        if (localStorage.getItem('accessToken')) {
            self.props.dispatch(userActions.profile(params))
                .then(function (response) {

                })
        }
        if(this.props.history.location.pathname==="/login"){
            // console.log("this.props.history",this.props.history);
            if (!localStorage.getItem('accessToken')) {
                this.toggleLogin();
            }else{
                this.props.history.push("/");
            }
        }
        if(this.props.history.location.pathname==="/redirect"){
            console.log("this.props.location",this.props.history.location.search);
            let params = queryString.parse(this.props.history.location.search);
            console.log("params",params)
            let user=JSON.parse(params.user);
            console.log("user",user)
            if (!localStorage.getItem('accessToken')) {
                self.props.dispatch({type: settingConstants.TOGGLE_SOCIAL_LOGIN});
                this.appleLogin(user);
            }else{
                this.props.history.push("/");
            }
        }
    }

    appleLogin(user){
        let self=this;
        if(user&&user.sub) {
            let param = {
                appleId: user.sub,
                name: user.name?user.name:user.email.split("@")[0],
                email: user.email,
                first_name: user.name?user.name:user.email.split("@")[0],
                //'socialAvatar',
                deviceToken: 'sdadsadsada',
                deviceTypeId: 2,
                'latitude': this.state.latitude,
                'longitude': this.state.longitude,
            };
            // if (fbData.picture && fbData.picture.data && fbData.picture.data.url !== "") {
            //     param.socialAvatar = fbData.picture.data.url
            // }
            self.props.dispatch(userActions.socialLogin(param))
                .then(function (response) {
                    console.log('response',response);
                    if (response.data.errorCode === 0) {
                        self.props.dispatch({type: settingConstants.TOGGLE_SOCIAL_LOGIN});
                        // self.props.dispatch({type: settingConstants.TOGGLE_BOOKING});
                        if(localStorage.getItem('isBooking')===1||localStorage.getItem('isBooking')==="1") {
                            self.props.dispatch({type: settingConstants.TOGGLE_BOOKING});
                        }
                    } else {
                        self.setState({
                            error: response.data.messages
                        })
                    }
                })
        }else{
            self.setState({
                error: "Error in apple login"
            })
        }
    }

    homeOpen() {
        this.props.history.push("/");
    }

    openDropdown() {
        this.setState({
            dropdown: !this.state.dropdown
        })
    }

    toggleFrogot(value) {
        this.props.dispatch({ type: settingConstants.TOGGLE_FORGOT, value: value });
    }

    toggleReset(value) {
        this.props.dispatch({ type: settingConstants.TOGGLE_RESET, value: value });
    }

    toggleThanku(value) {
        this.props.dispatch({ type: settingConstants.TOGGLE_THANKU, value: value });
    }

    toggleLogin() {
        this.setState({
            error: '',
            passwordError: '',
            emailError: '',
            email: '',
            password: '',
        })
        this.props.dispatch({ type: settingConstants.TOGGLE_LOGIN });
    }

    togglePartner(){

        this.props.history.push('/join');
    }

    toggleSignup() {
        this.setState({
            error: '',
            passwordError: '',
            emailError: '',
            email: '',
            password: '',
        })
        this.props.dispatch({ type: settingConstants.TOGGLE_SIGNUP });
    }

    toggleSocialLogin() {
        this.setState({
            error: '',
            passwordError: '',
            emailError: '',
            email: '',
            password: '',
            dropdown:false
        })
        this.props.dispatch({ type: settingConstants.TOGGLE_SOCIAL_LOGIN });
    }

    login() {
        let self = this;
        let email = this.state.email;
        let password = this.state.password;
        let params = {
            email: email,
            password: password,
            deviceToken: 'sdadsadsada',
            deviceTypeId: 2
        }
        if (this.state.email === "" || this.state.email === undefined) {
            this.setState({
                emailError: "Please enter your email address"
            })
        } else if (!this.validateEmail(this.state.email)) {
            this.setState({
                emailError: 'Please enter a valid email address'
            })
        } else if (this.state.password === "" || this.state.password === undefined) {
            this.setState({
                passwordError: 'Please enter password'
            })
        } else {
            self.props.dispatch(userActions.login(params))
                .then(function (response) {
                    //console.log('response',response);                
                    if (response.data.errorCode === 0) {
                        self.props.dispatch({type: settingConstants.TOGGLE_LOGIN});
                        if(localStorage.getItem('isBooking')===1||localStorage.getItem('isBooking')==="1") {
                            self.props.dispatch({type: settingConstants.TOGGLE_BOOKING});
                        }
                        self.setState({
                            dropdown:false
                        })

                        if(response.data.data.userInfo&&response.data.data.userInfo.hairdresser&&response.data.data.userInfo.hairdresser.salon) {
                            //if(response.data.data.userInfo.hairdresser.isSalonOwner===1) {
                                localStorage.setItem('salonId', response.data.data.userInfo.hairdresser.salon._id);
                                localStorage.setItem('defaultRedirectTo', response.data.data.userInfo.defaultRedirectTo);
                                localStorage.setItem('isShow', response.data.data.userInfo.hairdresser.salon.isShow);
                                //self.props.history.push('/diary/' + response.data.data.userInfo.hairdresser.salon._id)
                                window.location.href = "/diary/" + response.data.data.userInfo.hairdresser.salon._id;
                            // }else{
                            //     self.props.history.push('/coming-soon');
                            // }
                        }
                        // else{
                        //     window.location.reload();
                        // }
                    } else {
                        self.setState({
                            error: response.data.messages
                        })
                    }
                })
        }
    }

    logout() {
        let self = this;
        let params = {
            userId: this.props.user.userInfo.userId
        }
        self.props.dispatch(userActions.logout(params))
            .then(function (response) {
                localStorage.clear();
                self.props.history.push("/");
            })
    }

    onChanged(e) {
        this.setState({ [e.target.name]: e.target.value, copied: false });

        if (e.target.name === 'email') {
            if (!this.validateEmail(e.target.value)) {
                this.setState({
                    emailError: 'Please enter a valid email address'
                })
            } else {
                this.setState({
                    emailError: ''
                })
            }
        }
        if (e.target.name === 'password') {
            if (e.target.value === '' || e.target.value === null) {
                this.setState({
                    passwordError: ''
                })
            } else {
                this.setState({
                    passwordError: '',
                })
            }
        }

        if (e.target.name === 'first_name') {
            if (e.target.value === '' || e.target.value === null) {
                this.setState({
                    fNameError: ''
                })
            } else {
                this.setState({
                    fNameError: '',
                })
            }
        }

        if (e.target.name === 'last_name') {
            if (e.target.value === '' || e.target.value === null) {
                this.setState({
                    fNameError: ''
                })
            } else {
                this.setState({
                    lNameError: '',
                })
            }
        }
    }

    validateEmail(email) {
        const pattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const result = pattern.test(email);
        return result;
    }

    signup() {
        let self = this;
        let email = this.state.email;
        let password = this.state.password;
        let first_name = this.state.first_name;
        let last_name = this.state.last_name;
        let params = {
            email: email,
            password: password,
            first_name: first_name,
            last_name: last_name,
            name: first_name + " " + last_name,
            deviceToken: 'sdadsadsada',
            deviceTypeId: 2
        }

        if (this.state.first_name === "" || this.state.first_name === undefined) {
            this.setState({
                fNameError: 'Please enter first name'
            })
        } else if (this.state.last_name === "" || this.state.last_name === undefined) {
            this.setState({
                lNameError: 'Please enter last name'
            })
        } else if (this.state.email === "" || this.state.email === undefined) {
            this.setState({
                emailError: "Please enter your email address"
            })
        } else if (!this.validateEmail(this.state.email)) {
            this.setState({
                emailError: 'Please enter a valid email address'
            })
        } else if (this.state.password === "" || this.state.password === undefined) {
            this.setState({
                passwordError: 'Please enter password'
            })
        } else {
            self.props.dispatch(userActions.signup(params))
                .then(function (response) {
                    //console.log('response',response);
                    if (response.data.errorCode === 0) {
                        self.props.dispatch({type: settingConstants.TOGGLE_SIGNUP});
                        //self.props.dispatch({type: settingConstants.TOGGLE_BOOKING});
                        if(localStorage.getItem('isBooking')===1||localStorage.getItem('isBooking')==="1") {
                            self.props.dispatch({type: settingConstants.TOGGLE_BOOKING});
                        }
                    } else {
                        self.setState({
                            error: response.data.messages
                        })
                    }
                })
        }
    }

    forgot() {
        let self = this;
        let email = this.state.email;
        let params = {
            email: email,
            deviceToken: 'sdadsadsada',
            deviceTypeId: 2
        }
        if (this.state.email === "" || this.state.email === undefined) {
            this.setState({
                emailError: "Please enter your email address"
            })
        } else if (!this.validateEmail(this.state.email)) {
            this.setState({
                emailError: 'Please enter a valid email address'
            })
        } else {
            self.props.dispatch(userActions.forgot(params))
                .then(function (response) {
                    //console.log('response',response);
                    if (response.data.errorCode === 0) {
                        self.props.dispatch({ type: settingConstants.TOGGLE_THANKU });
                    } else {
                        self.setState({
                            error: response.data.messages
                        })
                    }
                })
        }
    }

    handleMenuOpen = () => document.body.classList.toggle('menu_open')

    handleCloseMenu = () => document.body.classList.remove('menu_open')

    componentClicked(params){
        console.log("componentClicked",params);
    }

    responseFacebook(fbData){
        let self=this;
        console.log("responseFacebook",fbData);
        if(fbData&&fbData.id) {
            let param = {
                facebookId: fbData.id,
                name: fbData.name,
                email: fbData.email,
                first_name: fbData.name,
                //'socialAvatar',
                deviceToken: 'sdadsadsada',
                deviceTypeId: 2,
                'latitude': this.state.latitude,
                'longitude': this.state.longitude,
            };
            if (fbData.picture && fbData.picture.data && fbData.picture.data.url !== "") {
                param.socialAvatar = fbData.picture.data.url
            }
            self.props.dispatch(userActions.socialLogin(param))
                .then(function (response) {
                    console.log('response',response);
                    if (response.data.errorCode === 0) {
                        self.props.dispatch({type: settingConstants.TOGGLE_SOCIAL_LOGIN});
                        // self.props.dispatch({type: settingConstants.TOGGLE_BOOKING});
                        if(localStorage.getItem('isBooking')===1||localStorage.getItem('isBooking')==="1") {
                            self.props.dispatch({type: settingConstants.TOGGLE_BOOKING});
                        }
                    } else {
                        self.setState({
                            error: response.data.messages
                        })
                    }
                })
        }else{
            self.setState({
                error: "Error in facebook login"
            })
        }
    }

    switchProfile(){
        let self=this;
        let param={
            latitude:this.state.latitude,
            longitude:this.state.longitude,
            switchTo:1

        }
        self.setState({dropdown:false})
        this.props.dispatch(hairdresserActions.hairdresserSwitchProfile(param))
            .then(function (response) {

                if(response.data.data.userInfo&&response.data.data.userInfo.hairdresser.salon) {
                    localStorage.setItem('salonId',response.data.data.userInfo.hairdresser.salon._id);
                    localStorage.setItem('defaultRedirectTo', response.data.data.userInfo.defaultRedirectTo);

                    self.props.history.push('/diary/' + response.data.data.userInfo.hairdresser.salon._id)
                }
            })
    }

    render() {
        const { hairdresser, salon, hairdresserReview, user, setting } = this.props;
        // console.log("this.props",this.props);
        return (
            <React.Fragment>
                <div className="menu_ovelay" onClick={this.handleCloseMenu}></div>
                {user.userInfo ?
                    <nav className="navbar navbar-expand-md navbar-dark cm-navbar">
                        <div className="mobile_trigger order-1" onClick={this.handleMenuOpen}>
                            <i className="fa fa-bars"></i>
                        </div>
                        <div className="logo order-3 order-md-1"><a href="/">
                            <img src={require('../../assets/images/beu logo white transparent.svg')}
                                className="img-logo" /></a>
                        </div>
                        <div className="mx-auto order-2 order-md-2 phn-name">
                            <a className="avbar-brand mx-auto cm_navbar-brand" href="/">beu </a>
                        </div>
                        <div className="navbar-collapse collapse w-100 order-3 order-md-3 dual-collapse2">
                            <ul className="navbar-nav ml-auto">
                                <li
                                    className={`nav-item dropdown ${this.state.dropdown ? 'open' : ''}`}
                                    style={{ cursor: 'pointer', minWidth: '180px' }}>
                                    <span className="nav-link dropdown-toggle" onClick={this.openDropdown.bind(this)}>
                                        <span><img className="dropdown-img"
                                            src={(user.userInfo.avatar && user.userInfo.avatar != '') ? user.userInfo.avatar : user.userInfo.userImage ? user.userInfo.userImage.circleImage && user.userInfo.userImage.circleImage != '' ? user.userInfo.userImage.circleImage : require('../../assets/images/Pixie.png') : require('../../assets/images/Pixie.png')}
                                            style={{ marginRight: '6px' }} /></span>{user.userInfo.name}
                                    </span>
                                    <div className="dropdown-menu">
                                        <a className="dropdown-item searchfield" href="/">Search</a>
                                        <a className="dropdown-item" href="/booking">Bookings</a>
                                        <a className="dropdown-item" href="/review">Reviews</a>
                                        <a className="dropdown-item" href="/myaccount">My Account</a>
                                        {user.userInfo.hairdresser?
                                            (<a className="dropdown-item" href="#" onClick={this.switchProfile.bind(this)}>Switch To Business</a>):""}
                                        <a className="dropdown-item" href="/legal">Legal</a>
                                        <a className="dropdown-item" href="#"
                                            onClick={this.logout.bind(this)}>Logout</a>
                                    </div>
                                </li>
                            </ul>

                        </div>

                    </nav>
                    :
                    <nav className="navbar navbar-expand-md navbar-dark cm-navbar">
                        <div className="mobile_trigger order-1" onClick={this.handleMenuOpen}><i
                            className="fa fa-bars"></i></div>
                        <div className="logo order-3 order-md-1">
                            <a href="https://www.beuapp.com"><img src={require('../../assets/images/beu logo white transparent.svg')}
                                className="img-logo" /></a>
                        </div>
                        <div className="mx-auto order-2 order-md-2 phn-name">
                            <a className="navbar-brand mx-auto cm_navbar-brand" href="https://www.beuapp.com" >beu </a>
                        </div>
                        <div className="navbar-collapse collapse w-100 order-3 order-md-3 dual-collapse2">
                            <ul className="navbar-nav ml-auto">
                                <li className="nav-item">
                                    <a className="nav-link login-btn" onClick={this.togglePartner}>Become a partner</a>
                                </li>
                                <li className="nav-item">
                                    {/*<a className="nav-link login-btn" onClick={this.toggleLogin}>Log In</a>*/}
                                    <a className="nav-link login-btn" onClick={this.toggleSocialLogin}>Log In</a>
                                </li>
                            </ul>
                        </div>
                    </nav>
                }

                {/* --------------------------------Social Login Popup---------------------------------------- */}
                <Modal show={this.props.setting.socialLogin}   onHide={this.toggleSocialLogin.bind(this)}>
                {/* <Popup  open={this.state.loginPopup} closeOnDocumentClick={false} closeOnEscape={false} > */}
                    <div className="cm_modal popupscroll">
                        <div className="modal-header" style={{ borderBottom: 'none' }}>
                            <span className="modealPrev" onHide={this.toggleSocialLogin.bind(this)}>
                                {/*<i className="fa fa-angle-left"*/}
                                {/*aria-hidden="true"></i>*/}
                            </span>
                            <button type="button" className="close" onClick={this.toggleSocialLogin.bind(this)}>&times;</button>
                        </div>
                        {/* <span className="cm_modal_close" >&times;</span> */}
                        <div className="modal-body loginPopup">
                            <div className="text-center" style={{ marginBottom: '20px' }}><img
                                src={require('../../assets/images/loginLogo.png')} className="popupLogo" /></div>
                            <p>Almost there! login or sign up to finalise your booking.</p>
                            {this.state.error ? <span className="cm_error_msg pointer_c">{this.state.error}</span> : ''}
                            <div className="btns_padding">
                            <FacebookLogin
                                isMobile={false}
                                appId={siteSetting.fb_appId}
                                autoLoad={false}
                                fields={siteSetting.fb_field}
                                //version={siteSetting.fb_version}
                                onClick={this.componentClicked.bind(this)}
                                callback={this.responseFacebook.bind(this)}
                                render={renderProps => (
                                    <button type="button" className="btn btn-default btn-block facebook-btn" onClick={renderProps.onClick}><span><img
                                        src={require('../../assets/images/facebook white.png')} /></span>Continue with Facebook
                                    </button>
                                )}
                            />
                            <AppleLogin
                                clientId={siteSetting.apple_clientId}
                                redirectURI={siteSetting.apple_redirectURI}
                                scope="name%20email"
                                responseType="code id_token"
                                responseMode="form_post"
                                designProp={{
                                    height:50,
                                    width:375,
                                    border_radius:15
                                }}
                            />
                            <br /><h5 className="text-center">or</h5><br />
                            <button type="button" className="btn btn-default btn-block gmail-btn" onClick={this.toggleLogin}>Continue with Email</button>
                            </div>


                            <p className="cm_discript">Beu will only use your details for booking purposes. So don't
                                worry, we will not be posting on your Facebook or send you any unwanted emails without
                                your consent.</p>
                        </div>
                    </div>
                {/* </Popup> */}
                </Modal>


                {/* --------------------------------Login Popup---------------------------------------- */}
                <Modal show={this.props.setting.login}   onHide={this.toggleLogin.bind(this)}>
                {/* <Popup  open={this.props.setting.login} closeOnDocumentClick={false} closeOnEscape={false} > */}
                    <div className="cm_modal popupscroll">
                        <div className="modal-header" style={{ borderBottom: 'none' }}>
                            {/*<span className="modealPrev" onClick={this.toggleLogin.bind(this)}><i className="fa fa-angle-left" aria-hidden="true"></i></span>*/}
                            {this.state.isShowClose ? (<button type="button" className="close" onClick={this.toggleLogin.bind(this)}>&times;</button>) : ''}
                        </div>
                        {/* <span className="cm_modal_close" >&times;</span> */}
                        <div className="modal-body loginPopup">
                            <div className="text-center" style={{ marginBottom: '10px' }}><img
                                src={require('../../assets/images/loginLogo.png')} className="popupLogo" /></div>
                            <h3 className="text-center login_h3">Sign In</h3>

                            {this.state.error ? <span className="cm_error_msg">{this.state.error}</span> : ''}

                            <div className="form-group">
                                <input type="text" className="form-control" placeholder="Email..." name="email"
                                    value={this.state.email} autoComplete="off" onChange={this.onChanged} />
                                {this.state.emailError ?
                                    <span className="cm_error_msg">{this.state.emailError}</span> : ''}
                            </div>

                            <div className="form-group">
                                <input type="password" className="form-control" placeholder="Password..."
                                    name="password" value={this.state.password} autoComplete="off"
                                    onChange={this.onChanged} />
                                {this.state.passwordError ?
                                    <span className="cm_error_msg">{this.state.passwordError}</span> : ''}
                            </div>

                            <div>
                                <a href="#" className="float-right" onClick={this.toggleFrogot.bind(this, 1)}>Forgot
                                    Password? </a>
                            </div>
                            <br /><br />
                            <button type="button" className="btn btn-primary btn-block "
                                onClick={this.login.bind(this)} disabled={this.state.loginButton}>Sign in
                            </button>
                            <h5 className="text-center">or</h5>
                            <button type="button" className="btn btn-default btn-block"
                                onClick={this.toggleSignup.bind(this)}>Create Account
                            </button>
                            <br />
                        </div>
                    </div>
                    </Modal>
                {/* </Popup> */}
              


                {/* --------------------------------Create Account Popup---------------------------------------- */}
                <Modal show={this.props.setting.register}  onHide={this.toggleSignup.bind(this)}>
                {/* <Popup open={this.props.setting.register} closeOnDocumentClick={false} closeOnEscape={false} > */}
                    <div className="cm_modal popupscroll">
                        <div className="modal-header" style={{ borderBottom: 'none' }}>
                            {/*<span className="modealPrev" onClick={this.closeModal}><i className="fa fa-angle-left" aria-hidden="true"></i></span>*/}

                            {this.state.isShowClose ? (<button type="button" className="close" onClick={this.toggleSignup.bind(this)}>&times;</button>) : ''}
                        </div>
                        {/* <span className="cm_modal_close" >&times;</span> */}
                        <div className="modal-body loginPopup">
                            <div className="text-center" style={{ marginBottom: '10px' }}><img
                                src={require('../../assets/images/loginLogo.png')} className="popupLogo" /></div>
                            <h3 className="text-center login_h3">Register</h3>

                            {this.state.error ? <span className="cm_error_msg">{this.state.error}</span> : ''}

                            <div className="form-group">
                                <input type="text" className="form-control" placeholder="First Name..."
                                    name="first_name" autoComplete="off" value={this.state.first_name}
                                    onChange={this.onChanged} />
                                {this.state.fNameError ?
                                    <span className="cm_error_msg">{this.state.fNameError}</span> : ''}
                            </div>

                            <div className="form-group">
                                <input type="text" className="form-control" placeholder="Last Name..." name="last_name"
                                    autoComplete="off" value={this.state.last_name} onChange={this.onChanged} />
                                {this.state.lNameError ?
                                    <span className="cm_error_msg">{this.state.lNameError}</span> : ''}
                            </div>

                            <div className="form-group">
                                <input type="text" className="form-control" placeholder="Email..." name="email"
                                    autoComplete="off" value={this.state.email} onChange={this.onChanged} />
                                {this.state.emailError ?
                                    <span className="cm_error_msg">{this.state.emailError}</span> : ''}
                            </div>

                            <div className="form-group">
                                <input type="password" className="form-control" placeholder="Password..."
                                    name="password" autoComplete="off" value={this.state.password}
                                    onChange={this.onChanged} />
                                {this.state.passwordError ?
                                    <span className="cm_error_msg">{this.state.passwordError}</span> : ''}
                            </div>

                            <button type="button" className="btn btn-primary btn-block"
                                onClick={this.signup.bind(this)}>Create Account
                            </button>
                            <br />
                        </div>
                    </div>
                {/* </Popup> */}
                </Modal>
                {/* --------------------------------forgot password Popup---------------------------------------- */}
                <Modal show={this.props.setting.forgot}  onHide={this.toggleFrogot.bind(this, 2)}>
                {/* <Popup open={this.props.setting.forgot} closeOnDocumentClick={false} closeOnEscape={false} > */}
                    <div className="cm_modal popupscroll">
                        <div className="modal-header" style={{ borderBottom: 'none' }}>
                            {/*<span className="modealPrev" onClick={this.toggleLogin.bind(this)}><i className="fa fa-angle-left" aria-hidden="true"></i></span>*/}

                            {this.state.isShowClose ? (<button type="button" className="close" onClick={this.toggleFrogot.bind(this, 2)}>&times;</button>) : ''}
                        </div>
                        {/* <span className="cm_modal_close" >&times;</span> */}
                        <div className="modal-body loginPopup">
                            <div className="text-center" style={{ marginBottom: '20px' }}><img
                                src={require('../../assets/images/loginLogo.png')} className="popupLogo" /></div>
                            <h3 className="text-center">Forgot Password</h3>
                            <br /><h5 className="text-center">It happens to the best of us...</h5>

                            {this.state.error ? <span className="cm_error_msg">{this.state.error}</span> : ''}
                            <br />
                            <div className="form-group">
                                <input type="text" className="form-control" placeholder="Email..." name="email"
                                    value={this.state.email} autoComplete="off" onChange={this.onChanged} />
                                {this.state.emailError ?
                                    <span className="cm_error_msg">{this.state.emailError}</span> : ''}
                            </div>
                            <br />
                            <button type="button" className="btn btn-primary btn-block " onClick={this.forgot.bind(this)}>Forgot
                            </button>
                            <br />
                        </div>
                    </div>
                {/* </Popup> */}
                </Modal>
                {/* --------------------------------Thank you Popup---------------------------------------- */}
                <Popup
                    open={this.props.setting.thanku}
                    closeOnDocumentClick={false} closeOnEscape={false}
                >
                    <div className="cm_modal popupscroll">
                        <div className="modal-header" style={{ borderBottom: 'none' }}>
                            {this.state.isShowClose ? (<button type="button" className="close" onClick={this.toggleThanku.bind(this, 2)}>&times;</button>) : ''}
                        </div>
                        <div className="modal-body loginPopup">
                            <div className="text-center" style={{ marginBottom: '20px' }}><img
                                src={require('../../assets/images/loginLogo.png')} className="popupLogo" /></div>
                            <h2 className="text-center">Thank you</h2>
                            <br />
                            <h5 className="text-center">We have sent an email to your registered email address. Click
                                the link to be redirected to reset your password.</h5>
                            <br />
                            <button type="button" className="btn btn-primary btn-block " onClick={this.toggleLogin.bind(this)}>
                                Sign in
                            </button>
                            <br />

                            <br />
                        </div>
                    </div>
                </Popup>
                {/* ----------------------------popup msz---------------------- */}

                <Popup
                    open={this.state.DeletemszPopup}
                    closeOnDocumentClick={false} onClose={this.closeModal}
                >
                    <div className="cm_modal popupscroll">
                        <div className="modal-header" style={{ borderBottom: 'none' }}>
                            {this.state.isShowClose ? (<button type="button" className="close" onClick={this.closeModal}>&times;</button>) : ''}
                        </div>
                        <div className="modal-body loginPopup">
                            <br />
                            <h5 className="text-center">Are You Sure You Want To Delete This Documents</h5>
                            <br />
                            <div class="text-center"><button type="submit" class="btn btn-edit btn-sm ">Yes</button>
                                <button type="submit" class="btn btn-sm btn-edit delete">No</button>
                            </div>
                            <br />

                            <br />
                        </div>
                    </div>
                </Popup>

            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    // console.log('###########', state)
    const { hairdresser, salon, hairdresserReview, user, setting, createBooking } = state;
    return {
        hairdresser,
        salon,
        hairdresserReview,
        user,
        setting,
        createBooking
    };
}

export default connect(mapStateToProps)(HeaderComponent);
