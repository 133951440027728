import React from 'react';
import { Link } from 'react-router-dom';

const ComingSoon = props => {
    return(
        <div className="h_n">
            <div className="body_container cm_bg_white setting_page pl-3">
                <div className="container text-center pt-5">
                    <img src={require('../assets/images/login-step-4.png')} />
                    <h5 className="mt-5">
                        Access to the professional Beu Web app will be coming soon for employees and self-employed staff. Please use our iOS for now and we will keep you updated.
                    </h5>

                    <Link className="btn btn-dark pl-5 pr-5 mt-5" to="/">Back to Home</Link>
                </div>
            </div>
        </div>
    )
}

export default ComingSoon;