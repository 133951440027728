import React, {Component} from 'react';
import TopHeader from '../template/TopHeader';
import SideNav from '../template/SideNav';
import { Link } from 'react-router-dom';
import {connect} from "react-redux";
import {hairdresserActions, userActions} from "../../_actions";

class Clients extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchKey:''
        }
        this.selectClient=this.selectClient.bind(this);
        this.searchClient=this.searchClient.bind(this);
        this.checkReadOnly=this.checkReadOnly.bind(this);
    }

    componentDidMount() {
        var self = this;
        if (localStorage.getItem('accessToken')) {
            let hairdresserId=localStorage.getItem('userId');
            let params = {
                searchKey:this.state.searchKey,
                'hairdresserId':hairdresserId,
                page:1,
                listType:1
            };
            self.props.dispatch(hairdresserActions.getHairdresserClient(params))
        }else{
            this.props.history.push('/')
        }
    }

    selectClient(clientId){
        this.props.history.push('/clients/'+clientId)
    }

    searchClient(e){
        let self=this;
        self.setState({
            searchKey: e.target.value
        },function () {
            self.props.dispatch(hairdresserActions.getHairdresserClient({ history: this.props.history, 'searchKey': this.state.searchKey, 'hairdresserId': localStorage.getItem('userId'), page: 1, listType: 1 }))
        })
    }

    checkReadOnly(){
        if(this.props.user&&this.props.user.userInfo&&(this.props.user.userInfo.hairdresser.isSalonOwner===1||this.props.user.userInfo.hairdresser.isSalonOwner==="1"||this.props.user.userInfo.hairdresser.employmentTypeId==="2"||this.props.user.userInfo.hairdresser.employmentTypeId===2)){
            return true;
        }else{
            return false;
        }
    }

    render() {
        // console.log("this.prop",this.props);
        return (
            <div className="h_n">
                <div className="body_container cm_bg_white">
                    <TopHeader title="Clients" {...this.props}/>
                    <SideNav {...this.props}/>


                    <div className="container">
                        <div className="d-flex justify-content-between cm_mob_block">
                            <div className="cm_search_wrapper client_search m-0">
                                <input type="text" className="form-control" placeholder="Search…" name="searchKey" onChange={this.searchClient}/>
                                <i className="fa fa-search"/>
                            </div>
                            {this.checkReadOnly()?
                            <Link to="/clients/add" style={{lineHeight: '38px'}} className="btn btn-dark pl-5 pr-5">Add New Client</Link>
                                :""}
                        </div>


                        <div className="table-responsive client_table">
                            <table className="table">
                                <thead>
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Number</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    this.props.hairdresserClient.client&&this.props.hairdresserClient.client.length>0?
                                    this.props.hairdresserClient.client.map((client,i) => (
                                        client.name!=="Unknown"?
                                        <tr key={client._id} onClick={this.selectClient.bind(this,client._id)}className="pointer-event">
                                            <td className="alfa_text"><h3 className="cm_fw_b">{(this.props.hairdresserClient.client[i-1]&&(this.props.hairdresserClient.client[i].name[0]!==this.props.hairdresserClient.client[i-1].name[0])||i==0)?client.name[0]:""}</h3></td>
                                            <td>
                                                {/*<Link to={"/clients/"+client._id}>*/}
                                                    <img width="50px" src={client.localAvatar?client.localAvatar: require('../../assets/images/client-plachoder.jpg')}/>
                                                {/*</Link>*/}
                                            </td>
                                            <td>
                                                {/*<Link to={"/clients/"+client._id}>*/}
                                                {client.name}
                                            {/*</Link>*/}
                                            </td>
                                            <td>{client.email}</td>
                                            <td>{client.phoneNumber?client.phoneNumber:"--"}</td>
                                        </tr>
                                    :"")):(<tr>
                                            <td colSpan={5} className="text-center">No Client available </td>
                                        </tr>)
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        ...state
    };
}

export default connect(mapStateToProps)(Clients);
