import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Tabs, Tab, Modal } from 'react-bootstrap';

import SideNav from '../template/SideNav';
import TopHeader from '../template/TopHeader';
import { Link, Redirect } from 'react-router-dom';
import siteSetting from "../../config/env";
import {userActions} from "../../_actions";


class AcountSettings extends Component {

    constructor(props){
        super(props);

        this.state = {
            isProfileSettingTab: false
        }

    }


    onTabChange = e => {
        if(e == 'profileSettings'){
            this.setState({isProfileSettingTab: true})
        }
    }

    componentDidMount() {
        let employmentType={};
        var self = this;
        if (localStorage.getItem('accessToken')) {
            let params={
                defaultRedirectTo:localStorage.getItem('defaultRedirectTo')
            }
            params.latitude = siteSetting.default_latitude;
            params.longitude = siteSetting.default_longitude;
            self.props.dispatch(userActions.profile(params))
                .then(function (response) {
                    console.log("response",response.data.data);
                    if(response.data.data) {
                        if(response.data.data.userInfo.hairdresser.isSalonOwner===1){
                            employmentType={ value: '1', label: 'Owner'}
                        }else if(response.data.data.userInfo.hairdresser.isSalonOwner===2){
                            employmentType={ value: '2', label: 'Account Manager'}
                        }else if(response.data.data.userInfo.hairdresser.isSalonOwner===0) {

                            if (response.data.data.userInfo.hairdresser.employmentTypeId === 1) {
                                employmentType = {value: '3', label: 'Employee'}
                            } else {
                                employmentType = {value: '4', label: 'Self Employee'}
                            }
                        }
                        self.setState(
                            {
                                employmentType:employmentType,
                                first_name: response.data.data.userInfo.first_name&&response.data.data.userInfo.first_name!==""?response.data.data.userInfo.first_name:response.data.data.userInfo.name,
                                last_name:response.data.data.userInfo.last_name,
                                email: response.data.data.userInfo.email,
                                phoneNumber: response.data.data.userInfo.phoneNumber.toString(),
                                jobTitle: response.data.data.userInfo.hairdresser.jobTitle,
                                shortBio: response.data.data.userInfo.hairdresser.shortBio,
                                circleImagePrev:response.data.data.userInfo.userImage.circleImage?response.data.data.userInfo.userImage.circleImage:require('../../assets/images/user-placeholder.png')
                            }
                        )

                    }
                })
        }else{
            this.props.history.push('/')
        }
    }


    checkReadOnly(){
        if(this.props.user&&this.props.user.userInfo&&this.props.user.userInfo.hairdresser.isSalonOwner===1){
            return true;
        }else{
            return false;
        }
    }

    
    render() {
        console.log("this.props",this.props);
        let { isProfileSettingTab } = this.state;
        return (
            <div className="h_n">
            <div className="body_container cm_bg_white setting_page">

                <TopHeader title="Settings" {...this.props}/>
                <SideNav {...this.props}/>




                <nav className="nav nav-tabs">
                    <Link className="nav-item nav-link" to="/settings">Salon Settings</Link>
                    <Link className="nav-item nav-link active" to="/settings/account">Account Settings</Link>
                    <Link className="nav-item nav-link" to="/settings/profile">Profile Settings</Link>
                </nav>
                    <div className="seetings_wrap_box">
                                <div className="row">
                                    <div className="col-md-6 border_right">

                                        <div className="text-center same_box">
                                            <img src={require('../../assets/images/bank-bg.png')} />
                                            <h2 className="cm_fw_b mt-4">Bank Account</h2>
                                            {/*<h3 className="cm_fw_l">Take payments directly through Beu via Apple Pay and card</h3>*/}
                                            <h3 className="cm_fw_l">Coming Soon <br /><br /></h3>

                                        </div>


                                        <div className="form-group position-relative">
                                            <label>Salon Bank Account</label>
                                            {/*<Link to="edit-bank-account">*/}
                                            <input type="text" className="form-control" value="" readOnly={true}/>
                                            <img src={require('../../assets/images/pass-icon.png')} className="input__icon" />
                                            {/*</Link>*/}
                                        </div>


                                    </div>
                                    <div className="col-md-6">

                                        <div className="text-center same_box">
                                            <img src={require('../../assets/images/password-bg.png')} />
                                            <h2 className="cm_fw_b mt-4">Edit Password</h2>
                                            <h3 className="cm_fw_l">If you forget your password you can reset or manage your password here.</h3>
                                        </div>


                                        <div className="form-group">
                                            <label>Account Password</label>
                                            <Link to="edit-password">
                                                <div className="form-control">***********************</div>
                                            </Link>
                                            
                                        </div>

                                        

                                        
                                    </div>
                                </div>
                            </div>


              

            </div>


        </div>
        )
    }

}



function mapStateToProps(state) {
    return {
        ...state
    };
}

export default connect(mapStateToProps)(AcountSettings);





