import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import Select from 'react-select';
import {connect} from "react-redux";
import ReactHtmlParser from "react-html-parser";
import {pageActions} from "../../_actions";



class HairdresserTermsAndConditions extends Component {
    
    constructor(props){
        super(props);

        this.state = {

        }

    }

    componentDidMount() {
        var self = this;

        let params={}
        self.props.dispatch(pageActions.hairdresserTermsAndConditions(params))
            .then(function (response) {

            })

    }

    render(){
        return(
            <div className="auth_wrapper" style={{backgroundImage: `url(${require('../../assets/images/auth-wrapper.jpg')})`}}>
                <div className="modal_header">
                    <h3 className="m-0 text-center">Hairdresser Terms And Conditions</h3>
                    {/*<i className="fa fa-times cm_close" />*/}
                </div>
                

                <div className="hairdress_modal_body">

                    <div className="row">

                        <div className="col-md-12 form-group">
                            { ReactHtmlParser(this.props.hairdresserTermsAndConditions.webPageContent) }
                        </div>

                    </div>

                </div>
                
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        ...state
    };
}

export default connect(mapStateToProps)(HairdresserTermsAndConditions);