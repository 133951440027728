export const hairdresserConstants = {

    HAIRDRESSER_INFO_REQUEST: 'HAIRDRESSER_INFO_REQUEST',
    HAIRDRESSER_INFO_SUCCESS: 'HAIRDRESSER_INFO_SUCCESS',
    HAIRDRESSER_INFO_ERROR: 'HAIRDRESSER_INFO_ERROR',
    HAIRDRESSER_INFO_CLEAR: 'HAIRDRESSER_INFO_CLEAR',

    HAIRDRESSER_REVIEW_SUCCESS: 'HAIRDRESSER_REVIEW_SUCCESS',

    SALON_PROFILE_REQUEST: 'SALON_PROFILE_REQUEST',
    SALON_PROFILE_SUCCESS: 'SALON_PROFILE_SUCCESS',
    SALON_PROFILE_ERROR: 'SALON_PROFILE_ERROR',
    SALON_PROFILE_CLEAR: 'SALON_PROFILE_CLEAR',

    SALON_LIST_SUCCESS: 'SALON_LIST_SUCCESS',

    SALON_SERVICE_REQUEST: 'SALON_SERVICE_REQUEST',
    SALON_SERVICE_SUCCESS: 'SALON_SERVICE_SUCCESS',
    SALON_SERVICE_ERROR: 'SALON_SERVICE_ERROR',


    HAIRDRESSER_SCHEDULE_REQUEST: 'HAIRDRESSER_SCHEDULE_REQUEST',
    HAIRDRESSER_SCHEDULE_SUCCESS: 'HAIRDRESSER_SCHEDULE_SUCCESS',
    HAIRDRESSER_SCHEDULE_ERROR: 'HAIRDRESSER_SCHEDULE_ERROR',

    HAIRDRESSER_CLIENT_SUCCESS: 'HAIRDRESSER_CLIENT_SUCCESS',
    HAIRDRESSER_CLIENT_ERROR: 'HAIRDRESSER_CLIENT_ERROR',
    HAIRDRESSER_CLIENT_CLEAR: 'HAIRDRESSER_CLIENT_CLEAR',
    HAIRDRESSER_CLIENT_REQUEST:'HAIRDRESSER_CLIENT_REQUEST',


    HAIRDRESSER_SWITCH_REQUEST:'HAIRDRESSER_SWITCH_REQUEST',
    HAIRDRESSER_SWITCH_SUCCESS:'HAIRDRESSER_SWITCH_SUCCESS',
    HAIRDRESSER_SWITCH_ERROR:'HAIRDRESSER_SWITCH_ERROR',

    HAIRDRESSER_CLIENTDETAIL_SUCCESS:'HAIRDRESSER_CLIENTDETAIL_SUCCESS',
    HAIRDRESSER_CLIENTDETAIL_ERROR:'HAIRDRESSER_CLIENTDETAIL_ERROR',
    HAIRDRESSER_CLIENTDETAIL_REQUEST:'HAIRDRESSER_CLIENTDETAIL_REQUEST',

    HAIRDRESSER_CLIENTCREATE_SUCCESS:'HAIRDRESSER_CLIENTCREATE_SUCCESS',
    HAIRDRESSER_CLIENTCREATE_ERROR:'HAIRDRESSER_CLIENTCREATE_ERROR',
    HAIRDRESSER_CLIENTCREATE_REQUEST:'HAIRDRESSER_CLIENTCREATE_REQUEST',

    HAIRDRESSER_STAFF_SUCCESS:'HAIRDRESSER_STAFF_SUCCESS',
    HAIRDRESSER_STAFF_ERROR:'HAIRDRESSER_STAFF_ERROR',
    HAIRDRESSER_STAFF_REQUEST:'HAIRDRESSER_STAFF_REQUEST',

    HAIRDRESSER_PROFILE_SUCCESS:'HAIRDRESSER_PROFILE_SUCCESS',
    HAIRDRESSER_PROFILE_ERROR:'HAIRDRESSER_PROFILE_ERROR',
    HAIRDRESSER_PROFILE_REQUEST:'HAIRDRESSER_PROFILE_REQUEST',

    CATEGORY_SUCCESS:'CATEGORY_SUCCESS',
    CATEGORY_ERROR:'CATEGORY_ERROR',
    CATEGORY_REQUEST:'CATEGORY_REQUEST',
    CATEGORY_CLEAR:'CATEGORY_CLEAR',

    HAIRDRESSER_SERVICE_REQUEST: 'HAIRDRESSER_SERVICE_REQUEST',
    HAIRDRESSER_SERVICE_SUCCESS: 'HAIRDRESSER_SERVICE_SUCCESS',
    HAIRDRESSER_SERVICE_ERROR: 'HAIRDRESSER_SERVICE_ERROR',
    HAIRDRESSER_SERVICE_CLEAR: 'HAIRDRESSER_SERVICE_CLEAR',

    HAIRDRESSER_STAFFSERVICE_SUCCESS:'HAIRDRESSER_STAFFSERVICE_SUCCESS',
    HAIRDRESSER_STAFFSERVICE_ERROR:'HAIRDRESSER_STAFFSERVICE_ERROR',
    HAIRDRESSER_STAFFSERVICE_REQUEST:'HAIRDRESSER_STAFFSERVICE_REQUEST',

    HAIRDRESSER_SERVICE_DETAIL_SUCCESS:'HAIRDRESSER_SERVICE_DETAIL_SUCCESS',
    HAIRDRESSER_SERVICE_DETAIL_ERROR:'HAIRDRESSER_SERVICE_DETAIL_ERROR',
    HAIRDRESSER_SERVICE_DETAIL_REQUEST:'HAIRDRESSER_SERVICE_DETAIL_REQUEST',

    HAIRDRESSER_STAFF_ROTA_SUCCESS:'HAIRDRESSER_STAFF_ROTA_SUCCESS',
    HAIRDRESSER_STAFF_ROTA_ERROR:'HAIRDRESSER_STAFF_ROTA_ERROR',
    HAIRDRESSER_STAFF_ROTA_REQUEST:'HAIRDRESSER_STAFF_ROTA_REQUEST',
};
