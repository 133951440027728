import promise from 'bluebird'

import { eventConstants } from '../_constants';

import { eventService } from "../_services";

import { ResponseFilter } from "../config/response-handler";

export const eventActions = {
    getEvent,
    editEvent,
    deleteEvent,
    viewEvent
};

/********************************************************************************************/

function getEvent(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            dispatch(request(params));
            eventService.getEvent(params)
            .then(response => {
                resolve(response);
                let { serverResponseData } = ResponseFilter(response);
                dispatch(success(serverResponseData.data));
            })
            .catch((err) => {
                reject(err);
                dispatch(failure(err));
            })
        })
    };
    function request(eventRequest) { return { type: eventConstants.EVENT_REQUEST, eventRequest } }
    function success(eventSuccess) { return { type: eventConstants.EVENT_SUCCESS, eventSuccess } }
    function failure(eventFail) { return { type: eventConstants.EVENT_ERROR, eventFail } }
}

function editEvent(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            dispatch(request(params));
            eventService.editEvent(params)
                .then(response => {
                    resolve(response);
                    let { serverResponseData } = ResponseFilter(response);
                    dispatch(success(serverResponseData.data));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));

                })
        })
    };
    function request(eventEditRequest) { return { type: eventConstants.EVENT_REQUEST, eventEditRequest } }
    function success(eventEditSuccess) { return { type: eventConstants.EVENT_SUCCESS, eventEditSuccess } }
    function failure(eventEditFail) { return { type: eventConstants.EVENT_ERROR, eventEditFail } }
}

function deleteEvent(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            dispatch(request(params));
            eventService.deleteEvent(params)
                .then(response => {
                    resolve(response);
                    let { serverResponseData } = ResponseFilter(response);
                    dispatch(success(serverResponseData.data));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));

                })
        })
    };
    function request(eventDeleteRequest) { return { type: eventConstants.EVENT_REQUEST, eventDeleteRequest } }
    function success(eventDeleteSuccess) { return { type: eventConstants.EVENT_SUCCESS, eventDeleteSuccess } }
    function failure(eventDeleteFail) { return { type: eventConstants.EVENT_ERROR, eventDeleteFail } }
}

function viewEvent(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            dispatch(success(params));
            resolve(params);
        })
    };
    function request(eventViewRequest) { return { type: eventConstants.EVENT_REQUEST, eventViewRequest } }
    function success(eventViewSuccess) { return { type: eventConstants.EVENT_VIEW_SUCCESS, eventViewSuccess } }
    function failure(eventViewFail) { return { type: eventConstants.EVENT_ERROR, eventViewFail } }
}

