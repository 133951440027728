export default {
  api: {
    url: 'https://apistag.beublog.com/api/',
    mode: 'cors',
    backend:'https://apistag.beublog.com/',
    weburl:'https://stagapp.beublog.com/'
  },
  socket: '',
  google_map_key:'AIzaSyAxx2cDzRRwbsY99ey2-G6gHVQpTwA4N_Y',
  apple_redirectURI:"https://apistag.beublog.com/api/user/apple-redirect"
}
