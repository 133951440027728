import promise from 'bluebird'

import { hairdresserConstants, alertConstants, userConstants } from '../_constants';

import { userService } from "../_services";

import { ResponseFilter } from "../config/response-handler";

export const userActions = {
    login,
    signup,
    profile,
    logout,
    forgot,
    reset,
    review,
    changePassword,
    editProfile,
    socialLogin
};


function login(params) {

    return function (dispatch) {

        return new promise(function (resolve, reject) {

            params.latitude = "28.640004"
            params.longitude = "77.376707"

            dispatch(request(params));
            userService.login(params)
                .then(response => {
                    resolve(response);
                    let { serverResponseData } = ResponseFilter(response);
                    if (serverResponseData.data) {
                        let data = serverResponseData.data;
                        localStorage.setItem('accessToken', data.tokenInfo.accessToken);
                        localStorage.setItem('refreshToken', data.tokenInfo.refreshToken);
                        localStorage.setItem('expiresIn', data.tokenInfo.expiresIn);
                        localStorage.setItem('tokenType', data.tokenInfo.tokenType);
                        localStorage.setItem('userId', data.userInfo.userId);
                        if(data.userInfo.hairdresser&&data.userInfo.hairdresser.salon){
                            localStorage.setItem('salonId', data.userInfo.hairdresser.salon._id);
                        }
                        localStorage.setItem('defaultRedirectTo', data.userInfo.defaultRedirectTo);
                    }
                    dispatch(success(serverResponseData.data));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));

                })
        })
    };
    function request(loginRequest) { return { type: userConstants.LOGIN_REQUEST, loginRequest } }
    function success(loginSuccess) { return { type: userConstants.LOGIN_SUCCESS, loginSuccess } }
    function failure(loginFail) { return { type: userConstants.LOGIN_ERROR, loginFail } }
}

function signup(apiParams) {

    return function (dispatch) {

        return new promise(function (resolve, reject) {

            apiParams.latitude = "28.640004"
            apiParams.longitude = "77.376707"

            dispatch(request(apiParams));
            userService.signup(apiParams)
                .then(response => {
                    resolve(response);
                    let { serverResponseData } = ResponseFilter(response);
                    if (serverResponseData.data) {
                        let data = serverResponseData.data;
                        localStorage.setItem('accessToken', data.tokenInfo.accessToken);
                        localStorage.setItem('refreshToken', data.tokenInfo.refreshToken);
                        localStorage.setItem('expiresIn', data.tokenInfo.expiresIn);
                        localStorage.setItem('tokenType', data.tokenInfo.tokenType);
                        localStorage.setItem('userId', data.userInfo.userId);
                    }
                    dispatch(success(serverResponseData.data));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));

                })
        })
    };
    function request(signupRequest) { return { type: userConstants.SIGNUP_REQUEST, signupRequest } }
    function success(signupSuccess) { return { type: userConstants.SIGNUP_SUCCESS, signupSuccess } }
    function failure(signupFail) { return { type: userConstants.SIGNUP_ERROR, signupFail } }
}

function socialLogin(apiParams) {

    return function (dispatch) {

        return new promise(function (resolve, reject) {

            apiParams.latitude = "28.640004"
            apiParams.longitude = "77.376707"

            dispatch(request(apiParams));
            userService.socialLogin(apiParams)
                .then(response => {
                    resolve(response);
                    let { serverResponseData } = ResponseFilter(response);
                    if (serverResponseData.data) {
                        let data = serverResponseData.data;
                        localStorage.setItem('accessToken', data.tokenInfo.accessToken);
                        localStorage.setItem('refreshToken', data.tokenInfo.refreshToken);
                        localStorage.setItem('expiresIn', data.tokenInfo.expiresIn);
                        localStorage.setItem('tokenType', data.tokenInfo.tokenType);
                        localStorage.setItem('userId', data.userInfo.userId);
                    }
                    dispatch(success(serverResponseData.data));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));

                })
        })
    };
    function request(signupRequest) { return { type: userConstants.SIGNUP_REQUEST, signupRequest } }
    function success(signupSuccess) { return { type: userConstants.SIGNUP_SUCCESS, signupSuccess } }
    function failure(signupFail) { return { type: userConstants.SIGNUP_ERROR, signupFail } }
}

function profile(params) {
    return function (dispatch) {

        return new promise(function (resolve, reject) {

            dispatch(request(params));
            userService.getProfile(params)
                .then(response => {
                    resolve(response);
                    let { serverResponseData } = ResponseFilter(response);
                    dispatch(success(serverResponseData.data));
                })
                .catch((err) => {
                    console.log("err",err);
                    localStorage.clear();
                    window.location.reload();
                    reject(err);
                    dispatch(failure(err));

                })
        })
    };
    function request(profileRequest) { return { type: userConstants.PROFILE_REQUEST, profileRequest } }
    function success(profileSuccess) { return { type: userConstants.PROFILE_SUCCESS, profileSuccess } }
    function failure(profileFail) { return { type: userConstants.PROFILE_ERROR, profileFail } }
}


function logout(params) {

    return function (dispatch) {

        return new promise(function (resolve, reject) {

            params.deviceToken = 'sfsafdsfsafsa';
            const apiParam = new URLSearchParams();
            apiParam.append('deviceToken', 'sadsadsadas');
            apiParam.append('userId', params.userId);

            dispatch(request(apiParam));
            userService.logout(apiParam, params)
                .then(response => {
                    resolve(response);
                    let { serverResponseData } = ResponseFilter(response);
                    dispatch(success(serverResponseData.data));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));

                })
        })
    };
    function request(profileRequest) { return { type: userConstants.PROFILE_REQUEST, profileRequest } }
    function success(logoutSuccess) { return { type: userConstants.LOGOUT_SUCCESS, logoutSuccess } }
    function failure(profileFail) { return { type: userConstants.PROFILE_ERROR, profileFail } }
}

function forgot(params) {

    return function (dispatch) {

        return new promise(function (resolve, reject) {

            dispatch(request(params));
            userService.forgot(params)
                .then(response => {
                    resolve(response);
                    let { serverResponseData } = ResponseFilter(response);
                    if (serverResponseData.data) {
                        let data = serverResponseData.data;
                    }
                    dispatch(success(serverResponseData.data));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));

                })
        })
    };
    function request(forgotRequest) { return { type: userConstants.FORGOT_REQUEST, forgotRequest } }
    function success(forgotSuccess) { return { type: userConstants.FORGOT_SUCCESS, forgotSuccess } }
    function failure(forgotFail) { return { type: userConstants.FORGOT_ERROR, forgotFail } }
}


function reset(params) {

    return function (dispatch) {

        return new promise(function (resolve, reject) {

            dispatch(request(params));
            userService.reset(params)
                .then(response => {
                    resolve(response);
                    let { serverResponseData } = ResponseFilter(response);
                    if (serverResponseData.data) {
                        let data = serverResponseData.data;
                    }
                    dispatch(success(serverResponseData.data));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));

                })
        })
    };
    function request(resetRequest) { return { type: userConstants.RESET_REQUEST, resetRequest } }
    function success(resetSuccess) { return { type: userConstants.RESET_SUCCESS, resetSuccess } }
    function failure(resetFail) { return { type: userConstants.RESET_ERROR, resetFail } }
}


function review(params) {

    return function (dispatch) {

        return new promise(function (resolve, reject) {
            params.deviceToken = 'sfsafdsfsafsa';
            dispatch(request(params));
            userService.review(params)
                .then(response => {
                    resolve(response);
                    let { serverResponseData } = ResponseFilter(response);
                    if (serverResponseData.data) {
                        let data = serverResponseData.data;
                    }
                    dispatch(success(serverResponseData.data));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));

                })
        })
    };
    function request(reviewRequest) { return { type: userConstants.REVIEW_REQUEST, reviewRequest } }
    function success(reviewSuccess) { return { type: userConstants.REVIEW_SUCCESS, reviewSuccess } }
    function failure(reviewFail) { return { type: userConstants.REVIEW_ERROR, reviewFail } }
}

function changePassword(params) {

    return function (dispatch) {

        return new promise(function (resolve, reject) {
            params.deviceToken = 'sfsafdsfsafsa';
            dispatch(request(params));
            userService.changePassword(params)
                .then(response => {
                    console.log(response,'jhtgyhedede');
                    
                    resolve(response);
                    let { serverResponseData } = ResponseFilter(response);
                    if (serverResponseData.data) {
                        let data = serverResponseData.data;
                    }
                    dispatch(success(serverResponseData.data.reviews));
                })
                .catch((err) => {
                    console.log(err,'jhtgyhbgbgedede');
                    reject(err);
                    dispatch(failure(err));

                })
        })
    };
    function request(changePasswordRequest) { return { type: userConstants.CHANGEPASSWORD_REQUEST, changePasswordRequest } }
    function success(changePasswordSuccess) { return { type: userConstants.CHANGEPASSWORD_SUCCESS, changePasswordSuccess } }
    function failure(changePasswordFail) { return { type: userConstants.CHANGEPASSWORD_ERROR, changePasswordFail } }
}


function editProfile(params) {

    return function (dispatch) {

        return new promise(function (resolve, reject) {
            dispatch(request(params));
            userService.editProfile(params)
                .then(response => {

                    resolve(response);
                    let { serverResponseData } = ResponseFilter(response);
                    if (serverResponseData.data) {
                        let data = serverResponseData.data;
                    }
                    dispatch(success(serverResponseData.data.reviews));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));

                })
        })
    };
    function request(changePasswordRequest) { return { type: userConstants.CHANGEPASSWORD_REQUEST, changePasswordRequest } }
    function success(changePasswordSuccess) { return { type: userConstants.CHANGEPASSWORD_SUCCESS, changePasswordSuccess } }
    function failure(changePasswordFail) { return { type: userConstants.CHANGEPASSWORD_ERROR, changePasswordFail } }
}

