import { hairdresserConstants } from '../_constants';


const initialState = {};

export function hairdresserServices(state = initialState, action) {
  //console.log('action.type',action);
  switch (action.type) {

    case hairdresserConstants.HAIRDRESSER_SERVICE_SUCCESS:
      let getService = action.getHairdresserServiceSuccess;
      return getService;

    case hairdresserConstants.HAIRDRESSER_SERVICE_ERROR:
      return {};

    case hairdresserConstants.HAIRDRESSER_SERVICE_CLEAR:
      return {};

    default:
      return state
  }
}

