import React, { Component } from 'react';
import moment from 'moment';

const oneDayMiliscond = 24 * 60 * 60 * 1000;

class CalendarCard extends Component {
    constructor(props){
        super(props);
        let currentDate=new Date();
        var seconds = currentDate.getSeconds();
        var minutes = currentDate.getMinutes();
        var hour = currentDate.getHours();
        var getMilliseconds =currentDate.getMilliseconds()
        var getTimezone = currentDate.toString().match(/([A-Z]+[\+-][0-9]+)/)[1]
        this.state = {
            currentDate: currentDate.getTime(),
            dates: [],
            today: new Date().getTime(),
            //selectDate: this.props.bookingDate,
            selectDate:new Date(this.props.bookingDate+" "+hour+":"+minutes+":"+seconds+"."+getMilliseconds+" "+getTimezone).getTime(),
            holidays: [],

        }
        this.handleDateClick=this.handleDateClick.bind(this);
        console.log("currentDate",currentDate);
    }

    handlePreviousDate = () => this.setState({
                                    currentDate: this.state.currentDate - (7 * oneDayMiliscond)
                                }, () => this.renderDates())

    handleNextDate = () => this.setState({
                                    currentDate: this.state.currentDate + (7 * oneDayMiliscond)
                                }, () => this.renderDates())

    renderDates = () => {
        let { currentDate } = this.state;
        this.setState({
            dates: [
                currentDate - (3 * oneDayMiliscond),
                currentDate - (2 * oneDayMiliscond),
                currentDate - oneDayMiliscond,
                currentDate,
                currentDate + oneDayMiliscond,
                currentDate + (2 * oneDayMiliscond),
                currentDate + (3 * oneDayMiliscond),
            ]
        })
    }

    componentDidMount(){
       this.renderDates();
       this.setState({holidays: this.state.holidays.map( holiday => moment(parseInt(holiday)).format('YYYYMMDD'))})
    }

    handleDateClick(selectDate) {
        //console.log("selectDate",selectDate)
        this.setState({selectDate:selectDate});
        this.props.setCalenderDate(selectDate)
    }

    render(){
        console.log("this.props",this.props);
        console.log("this.state",this.state);
        const { currentDate, dates, today, selectDate, holidays } = this.state;
        return(
            <React.Fragment>
            <div className="calendar_card">

                <div className="d-flex justify-content-center align-items-center mb-3">
                    <i className="fa fa-chevron-left" onClick={this.handlePreviousDate} />
                    <h4 className="ml-4 mr-4 mb-1">{moment(currentDate).format("MMM  YYYY")}</h4>
                    <i className="fa fa-chevron-right" onClick={this.handleNextDate} />
                </div>

                <div className="d-flex justify-content-between">
                    {
                        dates.map(date => (
                            <div key={date} className={`animated fadeInRight ${(today === date || selectDate === date) ? 'current_date' : ''} ${holidays.includes(moment(parseInt(date)).format('YYYYMMDD')) ? 'cm_holiday' : ''}`} onClick={() => this.handleDateClick(date)}>
                                <span>{moment(date).format("ddd")}</span>
                                <p className={`${moment(dates[3]).format("MM") === moment(date).format("MM") ? '' : 'cm_gary'}`}>{moment(date).format("DD")}</p>
                            </div>
                        ))
                    }

                </div>

            </div>




            

                {/* <h1>{selectDate}  => {moment(selectDate).format("DD, MM, YYYY")}</h1> */}
            </React.Fragment>
        )
    }
}

export default CalendarCard;
