import { bookingConstants } from '../_constants';


const initialSettings = {};
const initialEventSettings = {};


export function booking(state = initialSettings, action) {
  //console.log('action.type',action);
  switch (action.type) {

    case bookingConstants.USER_BOOKING_SUCCESS:
      let bookingInfo = action.userBookingSuccess;
      return bookingInfo;

    case bookingConstants.USER_CREATE_BOOKING_SUCCESS:
      let bookingCreateInfo = action.userCreateBookingSuccess;
      return bookingCreateInfo;

    case bookingConstants.USER_BOOKING_ERROR:
      return {};

    case bookingConstants.USER_CREATE_BOOKING_REQUEST:
      return {};

    default:
      return state
  }
}


const initialCreateBookingSettings = {
  isBooking: false
};


export function event(state = initialEventSettings, action) {
  //console.log('action.type',action);
  switch (action.type) {

    case bookingConstants.USER_CREATE_EVENT_SUCCESS:
      let bookingInfo = action.userCreateEventSuccess;
      return bookingInfo;

    case bookingConstants.USER_CREATE_EVENT_ERROR:
      return {};

    case bookingConstants.USER_CREATE_EVENT_REQUEST:
      return {};

    default:
      return state
  }
}

export function createBooking(state = initialCreateBookingSettings, action) {
  //console.log('action.type',action);
  switch (action.type) {

    case bookingConstants.IS_BOOKING:
      let bookinValue = state.isBooking;
      if (action.value) {
        if (action.value === 1) {
          bookinValue = true;
        } else {
          bookinValue = false;
        }
      }
      return Object.assign({}, initialCreateBookingSettings, { isBooking: bookinValue });

    case bookingConstants.IS_BOOKING_NOT:
      let bookinNotValue = state.isBooking;
      if (action.value) {
        if (action.value === 1) {
          bookinNotValue = true;
        } else {
          bookinNotValue = false;
        }
      }
      return Object.assign({}, initialCreateBookingSettings, { isBooking: bookinNotValue });


    default:
      return state
  }
}

const initialHairdresserBooking = {};


export function hairdresserBooking(state = initialHairdresserBooking, action) {
  //console.log('action.type',action);
  switch (action.type) {

    case bookingConstants.HAIRDRESSER_BOOKING_SUCCESS:
      let bookingInfo = action.hairdresserBookingSuccess;
      return bookingInfo;

    case bookingConstants.HAIRDRESSER_BOOKING_ERROR:
      return {};

    case bookingConstants.HAIRDRESSER_BOOKING_REQUEST:
      return {};

    default:
      return state
  }
}