import _ from "lodash";

import DevSetting from './development';
import ProdSetting from './production';
import StagSetting from './staging';
import localhostSetting from './localhost';
import ProdRushSetting from './rush';

let defaultSetting = {
  api: {
    url: 'http://localhost:3001/api/',
    mode: 'cors',
    backend:'http://localhost:3001/',
  },
  socket: '',
  google_map_key:'AIzaSyAxx2cDzRRwbsY99ey2-G6gHVQpTwA4N_Y',
  api_clientId:'mobileV1',
  api_clientSecret:'abc123456',
  default_latitude:'51.5063341',
  default_longitude:'-0.118092',
  fb_appId:'191495858050164',
  fb_field:'name,email,picture',
  fb_version:'7.0',
  apple_clientId:"app.beuappweb.com",
  apple_redirectURI:"https://devapp.beublog.com/redirect"
}

let siteSetting = defaultSetting;
console.log("Env : ",process.env.REACT_APP_ENV);
// switch ('stag') {
switch (process.env.REACT_APP_ENV) {
  case "prod":
  case "production":
    siteSetting = _.extend(defaultSetting, ProdSetting);
    break;
  case "rush":
  case "rush_prod":
    siteSetting = _.extend(defaultSetting, ProdRushSetting);
    break;
  case "stag":
  case "staging":
    siteSetting = _.extend(defaultSetting, StagSetting);
    break;
  case "dev":
  case "development":
    siteSetting = _.extend(defaultSetting, DevSetting);
    break;
  case "local":
  case "localhost":
    siteSetting = _.extend(defaultSetting, localhostSetting);
    break;
  default:
    siteSetting = _.extend(defaultSetting, StagSetting);
}

export default siteSetting;
