import React, {Component} from 'react';
import {connect} from "react-redux";
import {bookingActions, hairdresserActions} from "../../_actions";
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';
import "react-tabs/style/react-tabs.css";
import StarRatings from "react-star-ratings";
import dateFormat from "dateformat";
import Popup from "reactjs-popup";
import {Modal} from 'react-bootstrap';
import ReactWeeklyDayPicker from "react-weekly-day-picker";
import {Addicon} from "../SvgIcon";
import siteSetting from "../../config/env";
import {settingConstants} from "../../_constants";
import {GoogleMap, Marker, withGoogleMap, withScriptjs} from "react-google-maps";

var _ = require('lodash');
const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];

function getslotTime(time){
    let hr=parseInt(time/60);
    let min=time-(hr*60)
    return hr+':'+(min==0?'00':min);
}

const MapWithAMarker = withScriptjs(withGoogleMap((props) =>
    <GoogleMap
        defaultZoom={8}
        defaultCenter={{ lat: props.lat, lng: props.lng }}
    >
        {props.isMarkerShown && <Marker position={{lat: props.lat, lng: props.lng }} />}
    </GoogleMap>
))

const mapUrl="https://maps.googleapis.com/maps/api/js?key="+siteSetting.google_map_key+"&libraries=places"

class Booking extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentTab: 'upcoming',
            bookingId:'',

            date: new Date(),
            isOpenCalender: false,
            selectedService: [],
            isBookingButton: false,
            selectedDay:new Date(),
            bookingDate:null,
            month:monthNames[new Date().getMonth()],
            year:new Date().getFullYear(),
            bookingSlots:[],
            finalBookingSlots:[],
            latitude: siteSetting.default_latitude,
            longitude:siteSetting.default_longitude,
            totalAmount:0,
            totalTime:0,
            selectedBookingSlots:'',
            selectedBookingSlotsIndex:'',
            slotInfo:{},
            hairdresserId:'',
            DeletemszPopup:false,
            cancelBookingId:'',
            selectedCurrencySymbol:'£'
        }
        this.selectDay=this.selectDay.bind(this);
        this.unselectDay=this.unselectDay.bind(this);
        this.PrevClick=this.PrevClick.bind(this);
        this.NextClick=this.NextClick.bind(this);
        this.gotoBookingPage = this.gotoBookingPage.bind(this);
        this.cancelBooking=this.cancelBooking.bind(this);
        this.rescheduleBooking=this.rescheduleBooking.bind(this);
        this.rescheduleBookingPopUp=this.rescheduleBookingPopUp.bind(this);
        this.toggleBooking = this.toggleBooking.bind(this);
        this.bookingSlotCreate=this.bookingSlotCreate.bind(this);
        this.noLocation=this.noLocation.bind(this);
        this.foundLocation=this.foundLocation.bind(this);
        this.checkSelectedBookingSlots=this.checkSelectedBookingSlots.bind(this);
        this.getslotTime=this.getslotTime.bind(this);
        this.selectSlot=this.selectSlot.bind(this);
        this.openSalon=this.openSalon.bind(this);
        this.closeCancelBooking=this.closeCancelBooking.bind(this);
        this.cancelBookingPopup=this.cancelBookingPopup.bind(this);

    }

    closeCancelBooking(){
        this.setState({
            DeletemszPopup:false,
            cancelBookingId:''
        })
    }

    cancelBookingPopup(bookingId){
        let self=this;
        this.setState({
            cancelBookingId:bookingId,
            DeletemszPopup:true
        })
    }

    getslotTime(time){
        let hr=parseInt(time/60);
        let min=time-(hr*60)
        return hr+':'+(min==0?'00':min);
    }

    openSalon(hairdresserId, salonId) {
        this.props.history.push("/salon/" + salonId + "/professional/" + hairdresserId);
    }

    foundLocation(location) {

        this.setState({
            currentLocation:location.coords,
            latitude: location.coords.latitude?location.coords.latitude:siteSetting.default_latitude,
            longitude:location.coords.longitude?location.coords.longitude:siteSetting.default_longitude,
        })
    }

    noLocation() {

    }

    componentDidMount() {

        let {dispatch} = this.props;
        let self = this;
        let params = this.state.currentTab;
        dispatch(bookingActions.upcomingOrPastBooking(params))
        navigator.geolocation.getCurrentPosition(this.foundLocation,this.noLocation.bind(this))
    }

    PrevClick(e){
        //console.log('PrevClick',e);
        let date=new Date(e._d);
        this.setState({
            month:monthNames[date.getMonth()],
            year:date.getFullYear()
        },function () {
            //console.log('@@@@@@@@@@@@@');
        })
    }

    NextClick(e){
        //console.log('NextClick',e);
        let date=new Date(e._d)
        this.setState({
            month:monthNames[date.getMonth()],
            year:date.getFullYear()
        },function () {
            //console.log('$$$$$$$$$$$$$$$$$$');
        })
    }

    selectDay(e){
        console.log('selectDay',e);
        this.state.bookingDate=e[0];
        this.state.selectedDay=e[0];
        //console.log(this.state.bookingDate)
        //this.setState({bookingDate:e[0]})
        let params = {};
        let self = this;
        let sdate=new Date(e[0])
        self.setState({
            month:monthNames[sdate.getMonth()],
            year:sdate.getFullYear()
        })

        params.hairdresserId = this.state.hairdresserId;
        params.latitude = this.state.latitude
        params.longitude = this.state.longitude
        params.slotsForBookingDate=dateFormat(e[0],'yyyy-mm-dd');
        self.props.dispatch(hairdresserActions.getHairdresserInfo(params))
            .then(function (response) {

                if (response.data.error) {
                    self.setState({
                        message: response.data.error.message
                    })
                } else {
                    console.log(response);
                    self.setState({
                        bookingSlots:response.data.data.bookingSlots,
                        selectedBookingSlotsIndex:'',
                        finalBookingSlots:[]
                    },function () {
                        self.bookingSlotCreate(0);
                    })
                }
            });
    }

    cancelBooking(bookingId){
        let self=this;

        this.setState({
            bookingId
        })
        console.log('cancelBooking');
        let params = {
            bookingId:bookingId,
            deviceToken:'sadsadsad'
        };
        self.props.dispatch(bookingActions.cancelBooking(params))
            .then(function (response) {

                if (response.data.errorCode!=0) {
                    self.setState({
                        error: response.data.messages
                    })
                } else {
                    self.setState({
                        DeletemszPopup:false,
                        cancelBookingId:'',
                        error:''
                    })
                    let params1 = self.state.currentTab;
                    self.props.dispatch(bookingActions.upcomingOrPastBooking(params1))
                }
        })
    }

    unselectDay(e){
        console.log('unselectDay',e);
    }

    rescheduleBookingPopUp(bookingInfo){
        let self=this;
        let params={};
        params.hairdresserId = bookingInfo.hairdresserId;
        params.latitude = this.state.latitude
        params.longitude = this.state.longitude
        //params.slotsForBookingDate=dateFormat(e[0],'yyyy-mm-dd');
        self.props.dispatch(hairdresserActions.getHairdresserInfo(params))
            .then(function (response) {

                if (response.data.errorCode!=0) {
                    self.setState({
                        error: response.data.messages
                    })
                } else {
                    //console.log('++++++++++++++',response.data.data.bookingSlots);
                    self.setState({
                        hairdresserId:bookingInfo.hairdresserId,
                        bookingId:bookingInfo._id,
                        bookingSlots:response.data.data.bookingSlots,
                        selectedBookingSlotsIndex:'',
                        finalBookingSlots:[],
                        selectedService:bookingInfo.servicesBooked,
                        error:'',
                        selectedCurrencySymbol:bookingInfo.currencySymbol&&bookingInfo.currencySymbol!==''?bookingInfo.currencySymbol:"£"
                    },function () {
                        self.bookingSlotCreate(0);
                        self.toggleBooking(1,bookingInfo);
                    })
                }
            });

    }


    rescheduleBooking(bookingId){
        let self=this;

        this.setState({
            bookingId
        })
        console.log('cancelBooking');
        let params = {
            bookingId:bookingId,
            deviceToken:'sadsadsad',
        };
        params.bookingSlot = [this.state.slotInfo.startTime, (this.state.slotInfo.startTime + this.state.totalTime)]
        params.bookingDate = dateFormat(this.state.bookingDate, 'yyyy-mm-dd')

        self.props.dispatch(bookingActions.rescheduleBooking(params))
            .then(function (response) {
                if (response.data.errorCode!=0) {
                    self.setState({
                        error: response.data.messages
                    })
                } else {
                    let params1 = self.state.currentTab;
                    self.props.dispatch(bookingActions.upcomingOrPastBooking(params1))
                    self.toggleBooking(2);
                }
            })
    }

    gotoBookingPage(e) {
        let {dispatch} = this.props;
        let self = this;
        let params = this.state.currentTab;

        if (e.tab === 'upcoming') {


            dispatch(bookingActions.upcomingOrPastBooking(e.tab)).then(res => {

            })
        }
        if (e.tab === 'past') {


            dispatch(bookingActions.upcomingOrPastBooking(e.tab)).then(res => {


            })

        }
    }

    bookingSlotCreate(index){

        let self=this;
        let finalBookingSlots=[];
        let pre;
        let next;
        let  tempArr = []
        let flag = false;
        let k =index;
        let arrslots=[];
        let bookingSlots=self.state.bookingSlots;
        //bookingSlots.map(function(slots, i) {
        for(let i=index;i<bookingSlots.length;i++) {
            //console.log(index,bookingSlots.length,i, self.state.totalTime, bookingSlots[i],bookingSlots[i].status);
            let check = false;
            if (bookingSlots[i].status == 0) {
                tempArr.push(bookingSlots[i])
                // console.log('tempArr', tempArr);
                if (((tempArr[tempArr.length - 1]["startTime"]) - (tempArr[0]["startTime"])) == self.state.totalTime) {

                    finalBookingSlots.push(tempArr[0])

                    for (let m = 0; m < bookingSlots.length; m++) {
                        if (tempArr[0]["startTime"] == bookingSlots[m]["startTime"]) {
                            k = m + 1;
                            flag = true;
                            break;
                        }
                    }
                    tempArr = []
                }
            } else {
                tempArr = []
            }

            if (flag == true) {
                break
            }
        }
        if (flag&&k<bookingSlots.length)
        {
            self.bookingSlotCreate(k)
        }
        //console.log('finalBookingSlots', finalBookingSlots);
        if(finalBookingSlots[0]) {

            let finalslot = this.state.finalBookingSlots;
            finalslot.unshift(finalBookingSlots[0]);
            // console.log('finalslot', finalslot);
            this.setState({
                finalBookingSlots: finalslot
            })
        }
    }

    checkSelectedBookingSlots(index){

        if (this.state.selectedBookingSlotsIndex===index) {
            return true;
        } else {
            return false;
        }
    }

    toggleBooking(value,bookingInfo) {
        //console.log('value', value, service, selection);
        let self = this;
        let totalAmount=this.state.totalAmount;
        let totalTime=this.state.totalTime;
        let selectedService = this.state.selectedService;
        //console.log('selectedService', selectedService);
        if (value === 1) {

            for(let i=0;i<bookingInfo.servicesBooked.length;i++){
                totalAmount += bookingInfo.servicesBooked[i].servicePrice;
                totalTime += bookingInfo.servicesBooked[i].serviceTime;
            }

            this.setState({
                selectedService,
                totalAmount,
                totalTime,
                selectedBookingSlotsIndex: '',
                finalBookingSlots:[]
            }, function () {
                self.bookingSlotCreate(0);
            })
        }
        if(value===2){
            this.props.dispatch({type: settingConstants.TOGGLE_BOOKING,value:2});
        }else if(value===1){
            this.props.dispatch({type: settingConstants.TOGGLE_BOOKING,value:1});
        }
    }

    selectSlot(slotInfo,index,isSelect){
        let self=this;
        this.setState({
            finalBookingSlots:[]
        },function () {
            self.bookingSlotCreate(0);
            //console.log('slotInfo,index',slotInfo,index,isSelect);
            let finalBookingSlots=self.state.finalBookingSlots;
            let totalTime=self.state.totalTime;

            if(isSelect==1) {
                let start=index+1;
                let res=_.remove(finalBookingSlots, function (o) {
                    return o.startTime<=slotInfo.startTime+totalTime && o.startTime>slotInfo.startTime
                });
                //finalBookingSlots=res;
                //console.log('finalBookingSlots',finalBookingSlots);
                self.setState({
                    selectedBookingSlotsIndex: index,
                    slotInfo: slotInfo,
                    finalBookingSlots,
                    isBookingButton:true
                })
            }else{
                self.setState({
                    selectedBookingSlotsIndex: '',
                    slotInfo: {}
                })
            }
        })
    }

    render() {
        let props=this.props;
        console.log("props",props);
        const classNames = {
            // container : 'red',
            // prevWeekArrow: 'red',
            // nextWeekArrow: 'red',
            // dayBox: 'gray-holiday',
            // dayCircleContainer: 'gray-holiday',
            // dayCicle: 'gray-holiday',
            // dayCircleTodayText: 'gray-holiday',
            dayCircleUnavailable: 'rwdp-DayCircle rwdp-flex-box rwdp-flex-column rwdp-justify-content-center rwdp-align-items-center gray',
            //dayCircleUnavailableText: 'gray-holiday',
            // dayCicleSelected: 'skyblue-holiday',

        };
        return (
            <React.Fragment>

                <Tabs>
                    <TabList className="booking">
                        <h2 className="cm_bookin_deatils booking_tab row">
                            <Tab onClick={this.gotoBookingPage.bind(this, {tab: 'upcoming'})}>Upcoming</Tab>
                            <Tab onClick={this.gotoBookingPage.bind(this, {tab: 'past'})}>Past</Tab>
                        </h2>

                    </TabList>

                    <TabPanel>
                        {this.props.upcomingOrPastBooking.bookings && this.props.upcomingOrPastBooking.paginationObject.totalRecords > 0 ?

                        this.props.upcomingOrPastBooking.bookings.map((value, index) => (
                            value.salonInfo?
                            <React.Fragment key={'upcomming_'+value._id}>
                                <div className="contentBg" >
                                    <div className="booking_dateinfo ">
                                        <div className="review_star algnCc">
                        <span>
                            <StarRatings
                                rating={parseFloat(value.hairdresser.hairdresser.reviewStats.ratingsAverage)}
                                starRatedColor="#aa9567"
                                numberOfStars={5}
                                name='rating'
                                starDimension="25px"
                                starSpacing="2px"
                            />
                        </span>
                                        </div>
                                        <div className="date_info booking_confirm">
                                            <h4 className="text-right">{dateFormat(value.bookingDate, "dddd,dd mmm yyyy")}</h4>
                                            <h5 className="text-right">{getslotTime(value.bookingSlot[0])} - {getslotTime(value.bookingSlot[1])}</h5>
                                        </div>
                                    </div>
                                    <div className="row p-rel">
                                        <div className="col-sm-6 pad-R0">
                                            <img onClick={value.hairdresser.hairdresser.salon?this.openSalon.bind(this, value.hairdresser._id, value.hairdresser.hairdresser.salon._id):''} style={{cursor:'pointer'}}
                                                 src={value.hairdresser.userImage.rectangleImage ? value.hairdresser.userImage.rectangleImage : require('../../assets/images/salonpic.png')}
                                                 className="img-fluid imgheight" />
                                        </div>
                                        <div className="team-round-img"><img onClick={value.hairdresser.hairdresser.salon?this.openSalon.bind(this, value.hairdresser._id, value.hairdresser.hairdresser.salon._id):''} style={{cursor:'pointer'}}
                                                                             src={value.hairdresser.userImage.circleImage ? value.hairdresser.userImage.circleImage : require('../../assets/images/Pixie.png')}
                                                                             className="cm_team-round-img"/></div>
                                        <div className="col-sm-6 pad-L0">
                                            <div className="gmap_canvas">
                                                <a href={'http://maps.google.com/?q='+value.salonInfo.loc[1]+','+value.salonInfo.loc[0]} target="_blank">
                                                <MapWithAMarker
                                                    googleMapURL={mapUrl}
                                                    loadingElement={<div style={{ height: `100%` }} />}
                                                    containerElement={<div style={{ height: `100%` }} />}
                                                    mapElement={<div style={{ height: `100%` }} />}
                                                    isMarkerShown={true}
                                                    lat={value.salonInfo.loc[1]}
                                                    lng={value.salonInfo.loc[0]}
                                                />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <br/>
                                    <div className="booking_dateinfo">
                                        <div className="review_star  col-md-6 pad-0">
                                            {value.servicesBooked.map((service, index) => (
                                            <h6 key={'service_'+index}>{service.service.serviceName}, {value.currencySymbol&&value.currencySymbol!=''?value.currencySymbol:"£"} {service.servicePrice.toFixed(2)} </h6>
                                            ))}
                                            <p>{value.hairdresser.name} from {value.salonInfo.salonName}</p>
                                        </div>
                                        <div className="date_info colgray col-md-6 pad-0">
                                            <h6 className="text-right">{value.salonInfo.formattedAddress}</h6>

                                        </div>
                                    </div>
                                    <div className="booking-footer">
                                        <div>
                                            <p>{value.bookingStatusText}, {value.bookingPaymentMethod}</p>
                                        </div>
                                        <div className="btnsml-screen">
                                            <button type="submit" className="btn btn-edit btn-sm " onClick={this.rescheduleBookingPopUp.bind(this,value)}>Reschedule</button>
                                            <button type="submit" className="btn btn-sm btn-edit delete" onClick={this.cancelBookingPopup.bind(this,value._id)}>Cancel</button>
                                        </div>
                                    </div>
                                </div>
                                <br/><br/>
                            </React.Fragment>
                            :''
                        )
                        )
                   :
                        (
                            <div className="contentBg">
                                <h3 className="text-center">No upcoming bookings</h3>
                            </div>
                        )
                    }

                    </TabPanel>

                    <TabPanel>
                        {(this.props.upcomingOrPastBooking.bookings && this.props.upcomingOrPastBooking.paginationObject.totalRecords > 0) ?


                            this.props.upcomingOrPastBooking.bookings.map((value, index) => (
                                value.salonInfo?
                                <React.Fragment key={'past_'+value._id}>
                                    <div className="contentBg" >
                                        <div className="booking_dateinfo">
                                            <div className="review_star">
                        <span>
                            <StarRatings
                                rating={parseFloat(value.hairdresser.hairdresser.reviewStats.ratingsAverage)}
                                starRatedColor="#aa9567"
                                numberOfStars={5}
                                name='rating'
                                starDimension="25px"
                                starSpacing="2px"
                            />
                        </span>
                                            </div>
                                            <div className="date_info booking_confirm">
                                                <h4 className="text-right">{dateFormat(value.bookingDate, "dddd,dd mmm yyyy")}</h4>
                                                <h5 className="text-right">{getslotTime(value.bookingSlot[0])} - {getslotTime(value.bookingSlot[1])}</h5>
                                            </div>
                                        </div>
                                        <div className="row p-rel">
                                            <div className="col-sm-6 pad-R0">
                                                {/*<img onClick={this.openSalon.bind(this, value.hairdresser._id, value.hairdresser.hairdresser.salon._id)} style={{cursor:'pointer'}}*/}
                                                {/*    src={value.hairdresser.hairdresser.salon.imageUrl ? value.hairdresser.hairdresser.salon.imageUrl : require('../../assets/images/salonpic.png')}*/}
                                                {/*    className="img-fluid imgheight" />*/}
                                                <img onClick={value.hairdresser.hairdresser.salon?this.openSalon.bind(this, value.hairdresser._id, value.hairdresser.hairdresser.salon._id):''} style={{cursor:'pointer'}}
                                                     src={value.hairdresser.userImage.rectangleImage ? value.hairdresser.userImage.rectangleImage : require('../../assets/images/salonpic.png')}
                                                     className="img-fluid imgheight" />
                                            </div>
                                            <div className="team-round-img"><img onClick={value.hairdresser.hairdresser.salon?this.openSalon.bind(this, value.hairdresser._id, value.hairdresser.hairdresser.salon._id):''} style={{cursor:'pointer'}}
                                                src={value.hairdresser.userImage.circleImage ? value.hairdresser.userImage.circleImage : require('../../assets/images/Pixie.png')}
                                                className="cm_team-round-img"/></div>
                                            <div className="col-sm-6 pad-L0">
                                                <div className="gmap_canvas">
                                                    <a href={'http://maps.google.com/?q='+value.salonInfo.loc[1]+','+value.salonInfo.loc[0]} target="_blank">
                                                    <MapWithAMarker
                                                        googleMapURL={mapUrl}
                                                        loadingElement={<div style={{ height: `100%` }} />}
                                                        containerElement={<div style={{ height: `100%` }} />}
                                                        mapElement={<div style={{ height: `100%` }} />}
                                                        isMarkerShown={true}
                                                        lat={value.salonInfo.loc[1]}
                                                        lng={value.salonInfo.loc[0]}
                                                    />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <br/>
                                        <div className="booking_dateinfo" style={{alignItems: 'center'}}>
                                            <div className="review_star  col-md-6 pad-0">
                                                {value.servicesBooked.map((service, index) => (
                                                    <h6 key={'service_'+index}>{service.service.serviceName}, {value.currencySymbol&&value.currencySymbol!=''?value.currencySymbol:"£"} {service.servicePrice.toFixed(2)} </h6>
                                                ))}
                                                <p>{value.hairdresser.name} from {value.salonInfo.salonName}</p>
                                            </div>
                                            <div className="date_info colgray col-md-6 pad-0">
                                                <h6 className="text-right">{value.salonInfo.formattedAddress}</h6>

                                            </div>
                                        </div>
                                        <div className="booking-footer">
                                            <div>
                                                <p>{value.bookingStatusText}, {value.bookingPaymentMethod}</p>
                                            </div>
                                            {/*<div>*/}
                                            {/*    <button type="submit" className="btn btn-edit btn-sm ">Reschedule</button>*/}
                                            {/*    <button type="submit" className="btn btn-sm btn-edit delete">Cancel</button>*/}
                                            {/*</div>*/}
                                        </div>
                                    </div>

                                    <br/><br/>
                                </React.Fragment>
                                :''
                            ))

                   :
                       (
                            <div className="contentBg">
                                <h3 className="text-center">No past bookings </h3>
                            </div>
                        )
                    }
                    </TabPanel>
                </Tabs>


                {/* --------------------------------Booking Popup---------------------------------------- */}
                <Modal show={this.props.setting.booking}   onHide={this.toggleBooking.bind(this,2)}>
                {/* <Popup
                    open={this.props.setting.booking} closeOnDocumentClick={false} closeOnEscape={false}
                    //onClose={this.toggleBooking.bind(this,2)}
                > */}
                    <div className="cm_modal popupscroll cm_calendar">

                        <div className="modal-header" style={{borderBottom: 'none'}}>

                            <button type="button" className="close"
                                    onClick={this.toggleBooking.bind(this,2)}>&times;</button>
                        </div>
                        <div className="modal-body">
                            <h5 className="month">{this.state.month} {this.state.year}</h5>
                            <ReactWeeklyDayPicker
                                daysCount={7}
                                classNames={classNames}
                                //startDay={new Date()} // First day as Date Object or 22 June 2016
                                multipleDaySelect={false}
                                selectedDays={[this.state.selectedDay]} // Selected days list
                                selectDay={this.selectDay.bind(this)}
                                //unselectDay={this.unselectDay.bind(this)}
                                //format={'YYYY-MM-DD'} //format of dates that handled in selectDay and unselectDay functions
                                firstLineFormat={'ddd'} // format for the first line of the day button
                                secondLineFormat={'D'} // format for the second line of the day button
                                //firstLineMobileFormat={'dddd'} // format for the first line of the day button mobile
                                //secondLineMobileFormat={'MMMM D, Y'} // format for the second line
                                onPrevClick={this.PrevClick.bind(this)} // called with the new startDay
                                onNextClick={this.NextClick.bind(this)} // called with the new startDay


                            />

                            <div className="col-sm-12">
                                <div className="row timeslot">
                                    <div className="col-12 timeslot-date">
                                        <h3>Morning</h3>
                                        <ul>
                                            {this.state.finalBookingSlots?this.state.finalBookingSlots.map((bookingSlot, i) => (
                                                bookingSlot?bookingSlot.status==0?bookingSlot.startTime<720?
                                                    <React.Fragment key={i}>
                                                        <li  className={this.checkSelectedBookingSlots(i)?'cal-active':''} onClick={this.selectSlot.bind(this,bookingSlot,i,this.checkSelectedBookingSlots(i)?2:1)}>{this.getslotTime(bookingSlot.startTime)}</li>
                                                        {this.checkSelectedBookingSlots(i)?<li  className={this.checkSelectedBookingSlots(i)?'cal-active':''} onClick={this.selectSlot.bind(this,bookingSlot,i,this.checkSelectedBookingSlots(i)?2:1)}>{this.getslotTime(bookingSlot.startTime+this.state.totalTime)}</li>:''}
                                                    </React.Fragment>:'':'':''
                                            )):''}
                                        </ul>
                                        <h3>Afternoon</h3>
                                        <ul>
                                            {this.state.finalBookingSlots?this.state.finalBookingSlots.map((bookingSlot, i) => (
                                                bookingSlot?bookingSlot.status==0?(bookingSlot.startTime>=720&&bookingSlot.startTime<1020)?
                                                    <React.Fragment key={i}>
                                                        <li  className={this.checkSelectedBookingSlots(i)?'cal-active':''} onClick={this.selectSlot.bind(this,bookingSlot,i,this.checkSelectedBookingSlots(i)?2:1)}>{this.getslotTime(bookingSlot.startTime)}</li>
                                                        {this.checkSelectedBookingSlots(i)?<li  className={this.checkSelectedBookingSlots(i)?'cal-active':''} onClick={this.selectSlot.bind(this,bookingSlot,i,this.checkSelectedBookingSlots(i)?2:1)}>{this.getslotTime(bookingSlot.startTime+this.state.totalTime)}</li>:''}
                                                    </React.Fragment>:'':'':''
                                            )):''}
                                        </ul>
                                        <h3>Evening</h3>
                                        <ul>
                                            {this.state.finalBookingSlots?this.state.finalBookingSlots.map((bookingSlot, i) => (
                                                bookingSlot?bookingSlot.status==0?bookingSlot.startTime>=1020?
                                                    <React.Fragment key={i}>
                                                        <li  className={this.checkSelectedBookingSlots(i)?'cal-active':''} onClick={this.selectSlot.bind(this,bookingSlot,i,this.checkSelectedBookingSlots(i)?2:1)}>{this.getslotTime(bookingSlot.startTime)}</li>
                                                        {this.checkSelectedBookingSlots(i)?<li  className={this.checkSelectedBookingSlots(i)?'cal-active':''} onClick={this.selectSlot.bind(this,bookingSlot,i,this.checkSelectedBookingSlots(i)?2:1)}>{this.getslotTime(bookingSlot.startTime+this.state.totalTime)}</li>:''}
                                                    </React.Fragment>:'':'':''
                                            )):''}
                                        </ul>
                                    </div>

                                </div>


                            </div>
                            <table className="confirm-book">
                                <tbody>
                                {this.state.selectedService.map((services, i) => (
                                    <tr key={"popup_"+services._id}>
                                        <td>{services.service.serviceName}</td>
                                        <td>{services.serviceTime}m</td>
                                        <td>{this.state.selectedCurrencySymbol}{services.servicePrice.toFixed(2)}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                            {/*<h6 className="text-center  pad-14" onClick={this.toggleBookingService.bind(this,1)}><span >Add another service</span>&nbsp;&nbsp;<span className="addservices"><Addicon/> </span></h6>*/}
                            <div className="col-sm-12 text-center">
                                <span className="cm_error_msg">{this.state.error}</span>
                            </div>
                            <div className="text-center">
                                <button type="button" className="btn booknow-btn" disabled={this.state.isBookingButton ? false : true} onClick={this.rescheduleBooking.bind(this,this.state.bookingId)}>Reschedule Now, {this.state.selectedCurrencySymbol}{this.state.totalAmount.toFixed(2)} </button>
                            </div>
                        </div>

                    </div>
                </Modal>
                <Modal show={this.state.DeletemszPopup}   onHide={this.closeCancelBooking.bind(this)}>
                {/* <Popup open={this.state.DeletemszPopup} closeOnDocumentClick={false} onClose={this.closeCancelBooking} > */}
                    <div className="cm_modal popupscroll">
                        <div className="modal-header" style={{borderBottom: 'none'}}>
                            {this.state.isShowClose?(<button type="button" className="close" onClick={this.closeCancelBooking.bind(this)}>&times;</button>):''}
                        </div>
                        <div className="modal-body loginPopup" style={{padding: '0px 22px'}}>
                            <div className="text-center">
                                <span className="cm_error_msg">{this.state.error}</span>
                            </div>
                            <br/>
                            <h2 className="text-center" style={{marginBottom: '29px'}}>Cancel booking?</h2>
                            <p className="cm_txtmsz">If booking is cancelled within 24 hours of it taking place you may have to:</p>
                            <p className="cm_txtmsz">-pay 5% of booking value</p>
                            <p className="cm_txtmsz">-pay for future booking in advance</p>
                           
                            <br/>
                            <div class="text-center">
                                <button type="button" class="btn btn-sm btn-edit" onClick={this.closeCancelBooking.bind(this)} style={{background:'#ffc0cb00', border: '1.4px solid #000'}}>No</button>
                                <button type="button" class="btn btn-edit btn-sm delete" onClick={this.cancelBooking.bind(this,this.state.cancelBookingId)}>Yes</button>
                            </div><br/>
                            <br/>

                            <br/>
                        </div>
                    </div>
                </Modal>
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    const {
        hairdresser, salon, hairdresserReview, user,
        setting, upcomingOrPastBooking,
    } = state;
    return {
        hairdresser,
        salon,
        hairdresserReview,
        user,
        setting,
        upcomingOrPastBooking
    };
}

export default connect(mapStateToProps)(Booking);

