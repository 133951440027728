import React, {Component} from 'react';
import {Tab, Tabs, Modal, Accordion, Card, Button} from 'react-bootstrap';
import {connect} from "react-redux";
import {hairdresserActions, userActions} from "../../_actions";
import moment from "moment";

class ClientCheckout  extends Component {

    constructor(props) {
        super(props);

        this.state = {
            bookingType:'past', //upcoming
            showMore:false,
            rescheduleRequestModal: false,
            cancelBookingModal: false,
            noShowModal: false,
            deleteSalonModal: false,
            removeStaffMemberMobcoder: false,
            editBookingModal:false
        }
        this.getServiceName=this.getServiceName.bind(this);
        this.getBookingTime=this.getBookingTime.bind(this);
        this.getTotalPrice=this.getTotalPrice.bind(this);
        this.showMore=this.showMore.bind(this);
    }


    handleClose = () => this.setState({
        rescheduleRequestModal: false,
        cancelBookingModal: false,
        noShowModal: false,
        deleteSalonModal: false,
        removeStaffMemberMobcoder: false,
        editBookingModal:false
    })

    componentDidMount() {
        var self = this;
        let params = {
            defaultRedirectTo:localStorage.getItem('defaultRedirectTo')
        };
        if (localStorage.getItem('accessToken')) {
            console.log("#####",self.props);
            self.props.dispatch(userActions.profile(params))

        }else{
            this.props.history.push('/')
        }
    }

    getServiceName(booking){
        let serviceName="";
        booking.servicesBooked.map((service,i) => {
            serviceName=serviceName+(serviceName==""?"":", ");
            serviceName=serviceName+service.service.serviceName;
        })
        return serviceName;
    }

    getslotTime(time) {

        let hr = parseInt(time / 60);
        let min = time - (hr * 60);
        //console.log("time",time,hr,min);
        return hr + ':' + (min === 0 ? '00' : min);
    }

    getBookingTime(booking){
        //console.log("booking",booking);
        let totalTime=booking.bookingSlot[1]-booking.bookingSlot[0];
         let hr = parseInt(totalTime / 60);
        let min = totalTime%60 ;
        let showTme="";
        if( totalTime >60){
            showTme=hr+' hr '+min+' min'
        }else{
            showTme=min+' min';
        }
        //console.log("showTme",showTme);
        return this.getslotTime(booking.bookingSlot[0])+" - "+this.getslotTime(booking.bookingSlot[1])+", "+showTme
    }

    getTotalPrice(booking){
        let serviceTotalPrice=0;
        booking.servicesBooked.map((service,i) => {
            serviceTotalPrice=serviceTotalPrice+service.servicePrice;
        })
        return serviceTotalPrice.toFixed(2);
    }

    showMore(){
        this.setState({showMore:!this.state.showMore})
    }

    handleReschedule=()=>{
        this.setState({rescheduleRequestModal:true})
    }

    handleCancel=()=>{
        this.setState({cancelBookingModal:true})
    }

    handleNoShow=()=>{
        this.setState({noShowModal:true})
    }

    handleEditBooking=()=>{
        this.setState({
            rescheduleRequestModal:false,
            editBookingModal:true
        })
    }

    editBooking=()=>{
        console.log("editBooking",this.props)
        this.props.history.push('/booking-edit/'+this.props.bookings._id)
    }

    render() {
        let propes=this.props;
        let {
            rescheduleRequestModal,
            cancelBookingModal,
            noShowModal,
            editBookingModal
        } = this.state;
        return (
            propes.selectedClient ?
                (
                    
                <div className="cm_right_panal client_checkout">
                    <div className="cm_bao">


                        {/*<Dropdown>*/}
                        {/*    <Dropdown.Toggle id="dropdown-basic">*/}
                        {/*        <i className="fa fa-ellipsis-v"/>*/}
                        {/*    </Dropdown.Toggle>*/}

                        {/*    <Dropdown.Menu>*/}
                        {/*        <Dropdown.Item onClick={() => propes.changeClient()}>Change Client</Dropdown.Item>*/}
                        {/*        /!*<Dropdown.Item href="#/action-2">Another action</Dropdown.Item>*!/*/}
                        {/*        /!*<Dropdown.Item href="#/action-3">Something else</Dropdown.Item>*!/*/}
                        {/*    </Dropdown.Menu>*/}
                        {/*</Dropdown>*/}
                        <img
                            src={propes.selectedClient.localAvatar != propes.selectedClient.localAvatar ? "" : require('../../assets/images/client-plachoder.jpg')}/>
                        <h3>{propes.selectedClient.name}</h3>
                        <p>{propes.selectedClient.email}</p>
                    </div>


                    <Tabs defaultActiveKey="Booking" className="new-booking-tab">
                        <Tab eventKey="Booking" title="Booking">

                            <Accordion defaultActiveKey="0">
                                <Card>

                                    <Card.Header>
                                        <Accordion.Toggle as={Button} variant="link" eventKey="0">Upcoming
                                            ({this.props.clientDetail.upcomingBookings?this.props.clientDetail.upcomingBookings.length:0})</Accordion.Toggle>
                                    </Card.Header>

                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body>
                                            <ul>
                                                {this.props.clientDetail.upcomingBookings&&this.props.clientDetail.upcomingBookings.length>0?
                                                    this.props.clientDetail.upcomingBookings.map((booking,i) => (
                                                        <li className="d-flex justify-content-between" key={booking._id}>
                                                            <div className="cm_bg_uniq">
                                                                <h4>{moment(booking.bookingDate).format('DD')}</h4>
                                                                <p className="cm_fw_l">{moment(booking.bookingDate).format('MMM')}</p>
                                                            </div>
                                                            <div>
                                                                <p>{this.getServiceName(booking)}</p>
                                                                <p className="cm_fw_l">{this.getBookingTime(booking)}</p>
                                                            </div>
                                                            <div className="cm_fw_l">
                                                                <p>with {booking.hairdresser.name}</p>
                                                                <p className="text-right">{propes.currencySymbol}{this.getTotalPrice(booking)}</p>
                                                            </div>
                                                        </li>)):""}
                                            </ul>
                                        </Card.Body>
                                    </Accordion.Collapse>

                                </Card>
                                <Card>

                                    <Card.Header>
                                        <Accordion.Toggle as={Button} variant="link" eventKey="1">Past
                                            ({this.props.clientDetail.pastBooking?this.props.clientDetail.pastBooking.length:0})</Accordion.Toggle>
                                    </Card.Header>

                                    <Accordion.Collapse eventKey="1">
                                        <Card.Body>
                                            <ul>
                                                {this.props.clientDetail.pastBooking&&this.props.clientDetail.pastBooking.length>0?
                                                    this.props.clientDetail.pastBooking.map((booking,i) => (
                                                        <li className="d-flex justify-content-between" key={booking._id}>
                                                            <div className="cm_bg_uniq">
                                                                <h4>{moment(booking.bookingDate).format('DD')}</h4>
                                                                <p className="cm_fw_l">{moment(booking.bookingDate).format('MMM')}</p>
                                                            </div>
                                                            <div>
                                                                <p>{this.getServiceName(booking)}</p>
                                                                <p>{this.getBookingTime(booking)}</p>
                                                            </div>
                                                            <div className="cm_fw_l">
                                                                <p>with {booking.hairdresser.name}</p>
                                                                <p>{propes.currencySymbol}{this.getTotalPrice(booking)}</p>
                                                            </div>
                                                        </li>)):""}
                                            </ul>

                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>

                        </Tab>
                        <Tab eventKey="notes" title="Notes">
                            <p>{propes.selectedClient.note?propes.selectedClient.note:"No notes"}</p>
                        </Tab>

                    </Tabs>


                    <div className="cm_btns m_revers">
                        {/*<button className="btn btn-dark btn-block mb-3">Checkout</button>*/}
                        {parseFloat(propes.pendingAmount).toFixed(2)>0?
                        <button className="btn btn-dark btn-block" onClick={() => propes.checkoutBooking()}>Checkout
                        </button>:""}
                        <button className="btn btn-primary btn-block mb-3" onClick={this.showMore.bind(this)}>More Options <span className="cm_angle" />
                        </button>
                    </div>
                    {this.state.showMore?
                    <ul className="buttons_border_wrap">
                        {propes.bookings.bookingStatus!==5&&propes.bookings.bookingStatus!=="5"?
                            <>
                                <li onClick={this.handleEditBooking}>Edit Booking</li>
                                <li onClick={this.handleReschedule}>Reschedule Booking</li>
                                </>
                            :""}
                        <li className="cm_red_c" onClick={this.handleCancel}>Cancel Booking</li>
                        {propes.bookings.bookingStatus===1||propes.bookings.bookingStatus==="1"||propes.bookings.bookingStatus===0||propes.bookings.bookingStatus==="0"?<li className="cm_red_c" onClick={this.handleNoShow}>No Show</li>:""}

                    </ul>
                        :""}

                    <Modal show={editBookingModal} onHide={this.handleClose} className="bg_white_modal text-center">
                        <i className="fa fa-times cm_hide_mod" onClick={this.handleClose} />
                        <Modal.Body>
                            <div className="p-5">
                                <img src={require('../../assets/images/booking_edit.png')} className="img-fluid mb-3" />
                                <h3 style={{fontSize: '38px', fontWeight: '400'}} className="mb-4">Edit Booking</h3>
                                <p style={{fontSize: '23px', fontWeight: '300'}}>Your client will be notified by push notification and email of any changes.</p>

                                <div className="stap-action mt-5">
                                    <button className="btn btn-outline-dark mr-3" onClick={this.handleClose}>Back</button>
                                    <button className="btn btn-dark" onClick={this.editBooking}>Confirm</button>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>

                    <Modal show={rescheduleRequestModal} onHide={this.handleClose} className="bg_white_modal text-center">
                        <i className="fa fa-times cm_hide_mod" onClick={this.handleClose} />
                        <Modal.Body>
                            <div className="p-5">
                            <img src={require('../../assets/images/reschedule-request-img.png')} className="img-fluid mb-3" />
                            <h3 style={{fontSize: '38px', fontWeight: '400'}} className="mb-4">Reschedule Request</h3>
                            <p style={{fontSize: '23px', fontWeight: '300'}}>This will send an email to your client to reschedule their booking at a time convenient for them. If you want to amend the booking yourself, choose edit booking.</p>
                            
                            <div className="stap-action mt-5">
                                <button className="btn btn-outline-dark mr-3" onClick={this.handleEditBooking}>Edit Booking</button>
                                <button className="btn btn-dark" onClick={()=>propes.rescheduleBooking()}>Confirm</button>
                            </div>
                            </div>
                        </Modal.Body>
                    </Modal>

                    <Modal show={cancelBookingModal} onHide={this.handleClose} className="bg_white_modal text-center">
                        <i className="fa fa-times cm_hide_mod" onClick={this.handleClose} />
                        <Modal.Body>
                            <div className="p-5">
                            <img src={require('../../assets/images/cancel-booking-img.png')} className="img-fluid mb-3" />
                            <h3 style={{fontSize: '38px', fontWeight: '400'}} className="mb-4">Cancel Booking</h3>
                            <p style={{fontSize: '23px', fontWeight: '300'}}>This will cancel the booking. Your client will be notified and sent an email. If your client payed in advance they will automatically be refunded.</p>
                            
                            <div className="stap-action mt-5">
                                <button className="btn btn-outline-dark mr-3" onClick={this.handleClose}>Cancel</button>
                                <button className="btn btn-dark" onClick={() =>propes.cancelBooking()}>Confirm</button>
                            </div>
                            </div>
                        </Modal.Body>
                    </Modal>

                    <Modal show={noShowModal} onHide={this.handleClose} className="bg_white_modal text-center">
                        <i className="fa fa-times cm_hide_mod" onClick={this.handleClose} />
                        <Modal.Body>
                            <div className="p-5">
                            <img src={require('../../assets/images/no-show-img.png')} className="img-fluid mb-3" />
                            <h3 style={{fontSize: '38px', fontWeight: '400'}} className="mb-4">No Show</h3>
                            <p style={{fontSize: '23px', fontWeight: '300'}}>Client didn’t show? Sorry to hear that. This has been flagged on our system and we will take steps to ensure this client doesn’t fail to show up again.</p>
                            
                            <div className="stap-action mt-5">
                                <button className="btn btn-outline-dark mr-3" onClick={this.handleClose}>Cancel</button>
                                <button className="btn btn-dark" onClick={() =>propes.noShowBooking()}>Confirm</button>
                            </div>
                            </div>
                        </Modal.Body>
                    </Modal>


                </div>) : ""
        )
    }
}

function mapStateToProps(state) {
    return {
        ...state
    };
}

export default connect(mapStateToProps)(ClientCheckout);