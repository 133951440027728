import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import SideNav from '../template/SideNav';
import TopHeader from '../template/TopHeader';
import {bookingActions, eventActions, hairdresserActions, userActions} from "../../_actions";
import siteSetting from "../../config/env";
import moment from "moment";
import dateFormat from "dateformat";

let userIdHolder = [];
const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];

function getslotTime(time) {
    let hr = parseInt(time / 60);
    let min = time - (hr * 60);
    return hr + ':' + (min === 0 ? '00' : min);
}

function getTime(time) {
    let hr = parseInt(time / 60);
    let min = time - (hr * 60);
    var d = new Date();
    return d.setHours(hr, min);
}
class Event extends Component {

    constructor(props) {
        super(props);

        this.state = {
            hairdresserName: 'Select Staff Member',
            eventTime:new Date(),
            endTime:new Date(),
            title:''
        }
        this.changeHairdresser = this.changeHairdresser.bind(this);
        this.handleEvent=this.handleEvent.bind(this);
        this.editEvent=this.editEvent.bind(this);
        this.deleteEvent=this.deleteEvent.bind(this);
        this.onChanged=this.onChanged.bind(this);
    }

    componentDidMount() {
        var self = this;
        let params = {
            defaultRedirectTo:localStorage.getItem('defaultRedirectTo')
        };
        let params1 = {
            searchKey:this.state.searchKey,
            'hairdresserId':localStorage.getItem('userId'),
            page:1,
            listType:1
        };
        if (localStorage.getItem('accessToken')) {
            if(this.props.event.info){
                //console.log("dateFormat(getslotTime(this.props.event.info.startTime),'hh:MM')",dateFormat(getslotTime(this.props.event.info.startTime),'hh:MM'));
                self.setState({
                    startTime:getTime(this.props.event.info.startTime),
                    endTime:getTime(this.props.event.info.endTime),
                    title:this.props.event.info.title,
                    date:this.props.event.info.date,
                    hairdresserName:this.props.event.hairdresser.name,
                    hairdresserId:this.props.event.hairdresser._id,
                    eventId:this.props.event.info._id,
                    eventStartTime:this.props.event.info.startTime,
                    eventEndTime:this.props.event.info.endTime,
                })
            }else{
                self.props.history.push('/diary/'+localStorage.getItem('salonId'));
            }
            self.props.dispatch(userActions.profile(params))
            self.props.dispatch(hairdresserActions.hairdresserStaffRota(params1))
            .then(function (response) {

            })
        } else {
            self.props.history.push('/')
        }
    }

    changeHairdresser(hairdresserId, hairdresserName,type) {
        this.setState({ startTimeError:'',serviceError:'',hairdresserId, hairdresserName })
        // console.log("salonId",salonId);
        //this.props.history.push("/salon/" + salonId + "/professional/" + hairdresserId);
        let params = {};
        let self = this;
        params.hairdresserId = hairdresserId;
        params.latitude = this.state.latitude;
        params.longitude = this.state.longitude;
        params.history = this.props.history;
        // if(type===1){
        //     //self.props.dispatch(hairdresserActions.getHairdresserClient({ history: this.props.history, 'searchKey': this.state.searchKey, 'hairdresserId': hairdresserId, page: 1, listType: 1 }))
        // }

        // self.props.dispatch(hairdresserActions.getHairdresserInfo(params))
        //     .then(function (response) {
        //
        //         if (response.data.errorCode) {
        //             self.setState({
        //                 error: response.data.error.messages
        //             })
        //         } else {
        //             self.setState({
        //                 bookingSlots: response.data.data.bookingSlots,
        //                 date: new Date(),
        //                 selectedService: [],
        //                 isBookingButton: false,
        //                 selectedDay: new Date(),
        //                 month: monthNames[new Date().getMonth()],
        //                 year: new Date().getFullYear(),
        //                 totalAmount: 0,
        //                 totalTime: 0,
        //                 selectedBookingSlots: '',
        //                 selectedBookingSlotsIndex: '',
        //                 finalBookingSlots: [],
        //                 includeTimes: [],
        //                 pendingAmount:0,
        //                 transaction:[],
        //                 paymentAmount:0,
        //                 totalPaymentAmount:0
        //             }, function () {
        //                 self.bookingSlotCreate(0);
        //             })
        //         }
        //     });
    }

    handleEvent(e, d) {
        if (e === "eventStartTime") {
            let m = moment(d);
            let minutes = (m.hour() * 60) + m.minute();
            this.setState({ startTime: d, eventStartTime: minutes })
        }
        if (e === "eventEndTime") {
            let m = moment(d);
            let minutes = (m.hour() * 60) + m.minute();
            this.setState({ endTime: d, eventEndTime: minutes })
        }
        if (typeof e === "object" && e.target.id === "eventTitle") {
            this.setState({ eventTitle: e.target.value })
        }
        if (typeof e === "object" && e.target.id === "isBookingAllowed") {
            this.setState({ isBookingAllowed: !this.state.isBookingAllowed })
        }
    }

    editEvent(){
        let self = this;
        let params = {
            'eventId':self.state.eventId,
            'eventTitle': self.state.title,
            'eventDate': dateFormat(self.state.date, 'yyyy-mm-dd'),
            'eventStartTime': self.state.eventStartTime,
            'eventEndTime': self.state.eventEndTime,
            'isBookingAllowed': self.state.isBookingAllowed ? 1 : 0,
            'hairdresserId': self.state.hairdresserId,
            'deviceToken': "dfdsfdsfsd",
            'repeatType': "doesNotRepeat"   //'doesNotRepeat', 'everyDay', 'everyWeek', 'everyMonth', 'everyYear'
        }
        self.props.dispatch(eventActions.editEvent(params))
            .then(function (response) {
                console.log('response',response);
                if (response.data.errorCode) {
                    self.setState({
                        eventError: response.data.messages
                    })
                } else {
                    self.props.history.push('/diary/'+localStorage.getItem('salonId'))
                    //window.location.reload();
                }
            })
    }

    deleteEvent(){
        let self = this;
        let params = {
            'eventId':self.state.eventId,
            'deviceToken': "dfdsfdsfsd",
            'hairdresserId': self.state.hairdresserId,

        }
        self.props.dispatch(eventActions.deleteEvent(params))
            .then(function (response) {
                console.log('response',response);
                if (response.data.errorCode) {
                    self.setState({
                        eventError: response.data.messages
                    })
                } else {
                    self.props.history.push('/diary/'+localStorage.getItem('salonId'))
                    //window.location.reload();
                }
            })
    }

    onChanged(e) {
        this.setState({ [e.target.name]: e.target.value, copied: false });
    }


    render(){
        console.log("this.props",this.props);
        console.log("this.state",this.state);
        return(
            <div className="h_n">

                    <div className="body_container bg_white">
                        <TopHeader title="View Event" {...this.props} />
                        <SideNav {...this.props} />
                        <div className="booking-parent">

                            <div className="tab_content_wrapper">

                                <div>

                                    <div className="cm_picker_wrap">
                                        <p>{moment(this.state.date).format('dddd DD MMM YYYY')}</p>
                                    </div>

                                    <div className="booking_form_wrapper">

                                        <div className="cm_row m-0">
                                            <div className="cm_25">
                                                <div className="form-group">

                                                    <label>Start Time</label>
                                                    <div className="cm_picker_wrap">
                                                        {/*<input type="text"  className="form-control" readOnly={true} />*/}
                                                        <input type="text" value={this.state.startTime ?moment (this.state.startTime).format('HH:mm') : ""} className="form-control" placeholder="00:00" />
                                                        <DatePicker
                                                            selected={this.state.startTime}
                                                            showTimeSelect
                                                            showTimeSelectOnly
                                                            timeIntervals={10}
                                                            timeCaption="Time"
                                                            dateFormat="HH:mm"
                                                            timeFormat="HH:mm"
                                                            onChange={this.handleEvent.bind(this, 'eventStartTime')}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="cm_75">
                                                <div className="form-group">
                                                    <label>Event Title</label>
                                                    <input type="text" className="form-control" name="title" value={this.state.title} onChange={this.onChanged.bind(this)}/>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="cm_row">
                                            <div className="cm_25">
                                                <div className="cm_picker_wrap">
                                                    <label>Finish Time</label>
                                                    {/*<input type="text" className="form-control"  />*/}
                                                    <input type="text" value={this.state.endTime ? moment(this.state.endTime).format('HH:mm') : ""} className="form-control" placeholder="00:00" />
                                                    <DatePicker
                                                        selected={this.state.endTime}
                                                        showTimeSelect
                                                        showTimeSelectOnly
                                                        timeIntervals={10}
                                                        timeCaption="Time"
                                                        dateFormat="HH:mm"
                                                        timeFormat="HH:mm"
                                                        onChange={this.handleEvent.bind(this, 'eventEndTime')}
                                                    />
                                                </div>
                                            </div>

                                            <div className="cm_75">
                                                <div className="form-group">
                                                    <label>Staff Member</label>

                                                    <div className="cm__boxs">
                                                        <div className="cm__box" >

                                                            <div className="table-responsive  cm_table">
                                                                <table>
                                                                    <tbody>

                                                                    <tr >
                                                                        <td className="t_width">{this.state.hairdresserName}</td>


                                                                    </tr>

                                                                    </tbody>
                                                                </table>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    {/*<Dropdown className="cm_select_wrapper">*/}
                                                    {/*    <Dropdown.Toggle className="cm_select" id="dropdown-basic" >{this.state.hairdresserName}</Dropdown.Toggle>*/}
                                                    {/*    <Dropdown.Menu>*/}
                                                    {/*        {this.props.staffRota.rota && this.props.staffRota.rota.length>0 ? (*/}
                                                    {/*            <ul className="cm_select_ul">*/}
                                                    {/*                {this.props.staffRota.rota.map((hairdresser, i) => (*/}
                                                    {/*                    hairdresser.name !== 'Unknown' && hairdresser.email !== 'Unknown' ?*/}
                                                    {/*                        (<li key={hairdresser._id} onClick={this.changeHairdresser.bind(this, hairdresser._id, hairdresser.name,1)}><Dropdown.Item>{hairdresser.name}</Dropdown.Item></li>)*/}
                                                    {/*                        : ""))}*/}
                                                    {/*            </ul>*/}
                                                    {/*        ) : ""}*/}
                                                    {/*    </Dropdown.Menu>*/}
                                                    {/*</Dropdown>*/}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="text-right mt-4 pt-2">

                                        <button className="btn btn-pink pl-4 pr-4" onClick={this.deleteEvent.bind(this)}>Delete</button>
                                        {/*<button className="btn btn-outline-dark pl-4 pr-4 ml-3" onClick={this.gotoHome}>Cancel</button>*/}
                                        <button type="button" className="btn btn-dark ml-3 pl-4 pr-4" onClick={this.editEvent.bind(this)}>Save</button>
                                        <br/>
                                        <br/>
                                        {this.state.error ?
                                            <span className="cm_error_msg">{this.state.error}</span> : ''}
                                        {this.state.successMessage ?
                                            <span className="cm_succ_msg">{this.state.successMessage}</span> : ''}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        )
    }
}



function mapStateToProps(state) {
    return {
        ...state
    };
}

export default connect(mapStateToProps)(Event);