import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';





class GoThroughModalAfterLogin extends Component {
    
    constructor(props){
        super(props);

        this.state = {
            isModalOpen: false,
            step: 1
        }
    }


    handleClose = () => this.setState({isModalOpen: false});

    handleStepChange = () => {
        if(this.state.step > 3){
            this.setState({isModalOpen: false})
        }
        this.setState({step : this.state.step + 1})
        
    }


    render(){
        let { step } = this.state;
        return(
            <div className="h_n go-through-modal auth_wrapper" style={{backgroundImage: `url(${require('../../assets/images/auth-wrapper.jpg')})`}}>
                

                <Modal.Body>


                    { step == 1 ? <div className="stap_wrapper">
                                    <img src={require('../../assets/images/login-step-1.png')} />
                                    <h2>Online Bookings</h2>
                                    <h3>Start taking bookings through your website and social media.</h3>
                                </div> : ''}


                    { step == 2 ? <div className="stap_wrapper">
                                    <img src={require('../../assets/images/login-step-2.png')} />
                                    <h2>Get Discovered</h2>
                                    <h3>Get discovered by local clients through the Beu marketplace and rank higher in Google search results</h3>
                                </div> : ''}


                    { step == 3 ? <div className="stap_wrapper">
                                    <img src={require('../../assets/images/login-step-3.png')} />
                                    <h2>Custom URL Link</h2>
                                    <h3>Simply add your URL to whatever platform you want to take online bookings with</h3>
                                </div> : ''}


                    { step == 4 ? <div className="stap_wrapper">
                                    <img src={require('../../assets/images/login-step-4.png')} />
                                    <h2>Enable Online Bookings</h2>
                                    <h3>Make sure you have added a salon image, salon address and bank account if you want to take online payments</h3>
                                </div> : ''}

                    <ul className="stap-indicate">
                        <li className={`${step == 1 ? 'active' : ''}`} />
                        <li className={`${step == 2 ? 'active' : ''}`} />
                        <li className={`${step == 3 ? 'active' : ''}`} />
                        <li className={`${step == 4 ? 'active' : ''}`} />
                    </ul>

                    <div className="stap-action">
                        
                        {
                            step == 4 ? <React.Fragment>
                                            <button className="btn btn-outline-dark mr-3" onClick={this.handleStepChange}>Cancel</button>
                                            <button className="btn btn-dark" onClick={this.handleStepChange}>Confirm</button>
                                        </React.Fragment>
                                        :
                                        <button className="btn btn-dark" onClick={this.handleStepChange}>Continue</button>
                        }

                    </div>

                </Modal.Body>
            </div>
        )
    }
}

export default GoThroughModalAfterLogin;