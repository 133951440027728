import { eventConstants } from '../_constants';

const initialSettings = {};

export function event(state = initialSettings, action) {
  //console.log('action.type',action);
  switch (action.type) {

    case eventConstants.EVENT_SUCCESS:
      let bookingInfo = action.eventSuccess;
      return bookingInfo;

    case eventConstants.EVENT_VIEW_SUCCESS:
      return action.eventViewSuccess;

    case eventConstants.EVENT_ERROR:
      return {};

    case eventConstants.EVENT_REQUEST:
      return {};

    default:
      return state
  }
}

