import React, { Component } from 'react';
import TopHeader from '../template/TopHeader';
import SideNav from '../template/SideNav';
import {hairdresserActions, userActions} from "../../_actions";
import {connect} from "react-redux";
import siteSetting from "../../config/env";
import {hairdresserService} from "../../_services";

var _ = require('lodash');
// let userIdHolder = [];
// const newCalendar = [];
let timeSlot=[];
for(let i=0;i<24*60;i+=10){
    timeSlot.push(i)
}
class AddService extends Component {
    constructor(props){
        super(props);

        this.state = {
            latitude : siteSetting.default_latitude,
            longitude : siteSetting.default_longitude,
            hairdresserList:[],
            selectedHairdresserId:'',
            selectedHairdresser:{},
            mainDuration:'',
            mainPrice:'',
            oldMainDuration:'',
            oldMainPrice:'',
            mainDurationError:'',
            serviceTimeError:'',
            staffService:[]
        }
        this.addService=this.addService.bind(this);
        this.onChanged=this.onChanged.bind(this);
        this.deleteService=this.deleteService.bind(this);
        this.cancleService=this.cancleService.bind(this);
        this.mainStaffService=this.mainStaffService.bind(this);
    }

    componentDidMount() {
        var self = this;
        let params = {
            defaultRedirectTo:localStorage.getItem('defaultRedirectTo')
        };
        if (localStorage.getItem('accessToken')) {
            self.props.dispatch(userActions.profile(params))
            let params2 = {
                hairdresserId:this.props.match.params.hairdresserId,
                serviceId:this.props.match.params.serviceId,
            };
            let hairdresserId=this.props.match.params.hairdresserId
            self.props.dispatch(hairdresserActions.hairdresserStaffServiceDetail(params2))
                .then(function (response) {
                    let selectedHairdresser=_.find(response.data.data.total, function(obj) {
                        //console.log("userService",obj,services);
                        return obj._id === hairdresserId;
                    })
                    console.log("selectedHairdresser",selectedHairdresser);
                    self.setState({
                        selectedHairdresserId:hairdresserId,
                        selectedHairdresser:selectedHairdresser,
                        mainDuration:selectedHairdresser.hairdresserSelectedServices.serviceTime,
                        mainPrice:selectedHairdresser.hairdresserSelectedServices.servicePrice,
                        oldMainDuration:selectedHairdresser.hairdresserSelectedServices.serviceTime?selectedHairdresser.hairdresserSelectedServices.serviceTime:"",
                        oldMainPrice:selectedHairdresser.hairdresserSelectedServices.servicePrice?selectedHairdresser.hairdresserSelectedServices.servicePrice:"",
                    })
                    //let otherServiceData=[];
                    let staffService=self.state.staffService;
                    response.data.data.total.map(item => {
                        if (item.hairdresserSelectedServices.serviceTime) {
                            //otherServiceData.push({[item._id]:true})

                            staffService.push({
                                [item._id]:{[item._id]:true,
                                [item._id + "_old_time"]: item.hairdresserSelectedServices.serviceTime,
                                [item._id + "_old_price"]: item.hairdresserSelectedServices.servicePrice,
                                [item._id + "_time"]: item.hairdresserSelectedServices.serviceTime,
                                [item._id + "_price"]: item.hairdresserSelectedServices.servicePrice,}
                            })
                            self.setState({
                                [item.hairdresserInfo._id]:true,
                                [item.hairdresserInfo._id + "_old_time"]: item.hairdresserSelectedServices.serviceTime,
                                [item.hairdresserInfo._id + "_old_price"]: item.hairdresserSelectedServices.servicePrice,
                                [item.hairdresserInfo._id + "_time"]: item.hairdresserSelectedServices.serviceTime,
                                [item.hairdresserInfo._id + "_price"]: item.hairdresserSelectedServices.servicePrice,
                            })
                        }
                    })
                    self.setState(staffService)

                });
        }else{
            this.props.history.push('/')
        }
    }

    handleEditDetail = (otherStaffId,time )=> {
        // console.log("otherStaffId",otherStaffId)
        if(!time) {
            this.setState({
                [otherStaffId]: !this.state[otherStaffId]
            }, function () {
                if (this.state[otherStaffId] === false) {
                    this.setState({
                        [otherStaffId + "_time"]: "",
                        [otherStaffId + "_price"]: "",
                    })
                }
            })
        }
    }

    addService(){
        var arr = [];
        _.map(this.state, function (value,key) {
            if(value===true){
                arr.push(key) ;
            }
        });
        //console.log("arr",arr);
        let self=this;
       // staffServiceDetail.serviceDetail.categoryID
        this.setState({
            mainPriceError:'',
            mainDurationError:''
        })
        let params={
            categoryId:this.props.staffServiceDetail.serviceDetail.categoryID,
            serviceId:this.props.match.params.serviceId,
            hairdresserId:this.state.selectedHairdresser.hairdresserInfo._id,
            servicePrice:this.state.mainPrice,
            serviceTime:this.state.mainDuration,
            deviceToken:'dfsdfdsf'
        };
        if (this.state.mainPrice === "" || this.state.mainPrice === undefined) {
            this.setState({
                mainPriceError: 'Please enter price'
            })
        } else if (this.state.mainDuration === "" || this.state.mainDuration === undefined) {
            this.setState({
                mainDurationError: 'Please enter time'
            })
        }else {
            if(arr.length>0) {
                _.map(arr, function (obj) {
                    let params1 = {
                        categoryId: self.props.staffServiceDetail.serviceDetail.categoryID,
                        serviceId: self.props.match.params.serviceId,
                        hairdresserId: obj,
                        servicePrice: self.state[obj + "_price"],
                        serviceTime: self.state[obj + "_time"],
                        deviceToken: 'dfsdfdsf'
                    };
                    if (self.state[obj + "_old_time"]) {
                        hairdresserService.updateService(params1)
                            .then(function (response) {
                                // console.log('client response', response);
                                // if(response.data.errorCode==0){
                                //     self.setState({
                                //         successMessage:'Service has been saved successfully!'
                                //     })
                                // }
                                self.mainStaffService(params);
                            })
                    } else {
                        hairdresserService.addService(params1)
                            .then(function (response) {
                                // console.log('client response', response);
                                // if(response.data.errorCode==0){
                                //     self.setState({
                                //         successMessage:'Service has been saved successfully!'
                                //     })
                                // }
                                self.mainStaffService(params);
                            })
                    }
                })
            }else{
                self.mainStaffService(params);
            }

        }

    }

    mainStaffService(params){
        //console.log("mainStaffService==================");
        let self=this;
        if (this.state.oldMainPrice === '' && this.state.oldMainDuration === '') {
            //console.log('if');
            hairdresserService.addService(params)
                .then(function (response) {
                    // console.log('client response', response);
                    if(response.data.errorCode===0||response.data.errorCode==="0"){
                        self.setState({
                            successMessage:'Service has been saved successfully!'
                        })
                        // self.props.history.push('/services/'+self.props.match.params.hairdresserId);
                    }
                    //self.addStaffService();
                })
        } else {
            //console.log('dsadasdas');
            hairdresserService.updateService(params)
                .then(function (response) {
                    // console.log('client response', response);
                    if(response.data.errorCode===0){
                        self.setState({
                            successMessage:'Service has been saved successfully!'
                        })
                        // self.props.history.push('/services/'+self.props.match.params.hairdresserId);
                    }
                    //self.addStaffService();
                })
        }
        self.props.history.push('/services/'+self.props.match.params.hairdresserId);
    }

    onChanged(e) {
        this.setState({[e.target.name]: e.target.value, copied: false});
        if (e.target.name === 'mainDuration') {
            if (e.target.value === '' || e.target.value === null) {
                this.setState({
                    mainDurationError: 'Please enter valid time'
                })
            } else if(e.target.value%10!==0){
                this.setState({
                    mainDurationError: 'The serviceTime must be a multiple of 10',
                })
            }else{
                this.setState({
                    mainDurationError: '',
                })
            }
        }
    }

    deleteService(id,hairdresserId){
        let self=this;
        // staffServiceDetail.serviceDetail.categoryID
        this.setState({
            mainPriceError:'',
            mainDurationError:''
        })
        let params={
            categoryId:this.props.staffServiceDetail.serviceDetail.categoryID,
            serviceId:this.props.match.params.serviceId,
            hairdresserId:hairdresserId,
            servicePrice:this.state.mainPrice,
            serviceTime:this.state.mainDuration,
            deviceToken:'dfsdfdsf'
        };
        hairdresserService.deleteService(params)
            .then(function (response) {
                console.log('client response', response);
                if(response.data.errorCode===0){
                    self.setState({
                        successMessage:'Service has been delete successfully!'
                    })
                    self.handleEditDetail(id)
                    let params2 = {
                        hairdresserId:self.props.match.params.hairdresserId,
                        serviceId:self.props.match.params.serviceId,
                    };
                    let hairdresserId=self.props.match.params.hairdresserId
                    self.props.dispatch(hairdresserActions.hairdresserStaffServiceDetail(params2))
                        .then(function (response) {
                            let selectedHairdresser=_.find(response.data.data.total, function(obj) {
                                //console.log("userService",obj,services);
                                return obj._id === hairdresserId;
                            })
                            self.setState({
                                selectedHairdresserId:hairdresserId,
                                selectedHairdresser:selectedHairdresser,
                                mainDuration:selectedHairdresser.hairdresserSelectedServices.serviceTime,
                                mainPrice:selectedHairdresser.hairdresserSelectedServices.servicePrice,
                                oldMainDuration:selectedHairdresser.hairdresserSelectedServices.serviceTime?selectedHairdresser.hairdresserSelectedServices.serviceTime:"",
                                oldMainPrice:selectedHairdresser.hairdresserSelectedServices.servicePrice?selectedHairdresser.hairdresserSelectedServices.servicePrice:"",
                            })
                        });
                }
            })
    }

    cancleService(){
        this.props.history.push('/services');
    }

    render(){
        // console.log("this.props",this.props);
         console.log("this.state",this.state);
        return(
            <div className="h_n body_container cm_bg_white">

                <TopHeader title={this.state.selectedHairdresser?"Add Service for "+this.state.selectedHairdresser.name:"Add Service for ... "} {...this.props}/>
                <SideNav {...this.props}/>


                <div className="container">

                    <div className="cm_box_view mt-0 pb-0">

                        <h2>{this.props.staffServiceDetail.serviceDetail?this.props.staffServiceDetail.serviceDetail.serviceName:""}</h2>

                        <div className="row mt-5">
                            <div className="col-md-6 form-group">
                                <label>Service Duration (minutes)</label>
                                <input type="number" className="form-control" name="mainDuration" value={this.state.mainDuration} onChange={this.onChanged} min="10" max="100" step="10"/>
                                {this.state.mainDurationError ?
                                    <span className="cm_error_msg">{this.state.mainDurationError}</span> : ''}
                            </div>
                            <div className="col-md-6 form-group">
                                <label>Price ({this.state.selectedHairdresser.hairdresserInfo&&this.state.selectedHairdresser.hairdresserInfo.currencySymbol&&this.state.selectedHairdresser.hairdresserInfo.currencySymbol!==""?this.state.selectedHairdresser.hairdresserInfo.currencySymbol:"£"})</label>
                                <input type="number" className="form-control" name="mainPrice" value={this.state.mainPrice} onChange={this.onChanged} min="0"/>
                                {this.state.mainPriceError ?
                                    <span className="cm_error_msg">{this.state.mainPriceError}</span> : ''}
                            </div>
                        </div>

                        {this.props.staffServiceDetail&&this.props.staffServiceDetail.total&&this.props.staffServiceDetail.total.length>1?
                            (<div className="other_staff mt-4">
                            <div className="cm_tab_row cm_fw_b" style={{background: '#fff'}}>
                                <div className="cm_main_col pl-4">Choose other staff who provide service</div>
                                <div className="cm_main_col_2">Duration</div>
                                <div className="cm_main_col_3">Cost</div>
                            </div>

                            {
                                this.props.staffServiceDetail&&this.props.staffServiceDetail.total&&this.props.staffServiceDetail.total.length>1?
                                this.props.staffServiceDetail.total.map(item => (
                                    this.state.selectedHairdresserId!==item._id?
                                    <div key={item._id} className={`cm_tab_row ${this.state[item.hairdresserInfo._id]||item.hairdresserSelectedServices.serviceTime ? 'selected' : ''}`} >
                                        <div className="cm_main_col">
                                            <img src={item.userImage.circleImage?item.userImage.circleImage: require('../../assets/images/client-plachoder.jpg')} width="50px" />
                                            <p onClick={() => this.handleEditDetail(item.hairdresserInfo._id,item.hairdresserSelectedServices.serviceTime)}>{item.name}</p>
                                        </div>
                                        <div className="cm_main_col_2">
                                            {
                                                this.state[item.hairdresserInfo._id]||item.hairdresserSelectedServices.serviceTime ? <input type="number" className="form-control cm_table_input" value={this.state[item.hairdresserInfo._id+"_time"]} name={item.hairdresserInfo._id+"_time"} onChange={this.onChanged} min="10" max="100" step="10"/> : <span className="cm_b_span" onClick={() => this.handleEditDetail(item.hairdresserInfo._id,item.hairdresserSelectedServices.serviceTime)}>--</span>
                                            }
                                        </div>
                                        <div className="cm_main_col_3">
                                            {
                                                this.state[item.hairdresserInfo._id]||item.hairdresserSelectedServices.serviceTime ?
                                                    <React.Fragment>
                                                        <input type="number" className="form-control cm_table_input" value={this.state[item.hairdresserInfo._id+"_price"]} name={item.hairdresserInfo._id+"_price"} onChange={this.onChanged}/>
                                                        <i className="fa fa-times" onClick={() => (this.handleEditDetail(item.hairdresserInfo._id,item.hairdresserSelectedServices.serviceTime),this.deleteService(item._id,item.hairdresserInfo._id))} />
                                                    </React.Fragment> : <span className="cm_b_span" onClick={() => this.handleEditDetail(item.hairdresserInfo._id,item.hairdresserSelectedServices.serviceTime)}>--</span>
                                            }
                                        </div>
                                    </div>
                                :""))
                            :""}
                        </div>):""}


                    </div>

                    <div className="text-right mt-4">
                        <span className="btn btn-outline-dark pl-4 pr-4" onClick={this.cancleService.bind(this)}>Cancel</span>
                        <button type="button" className="btn btn-dark ml-3 pl-4 pr-4" onClick={this.addService.bind(this)}>Save</button>
                        <br/>
                        <br/>
                        {this.state.successMessage ?
                            <span className="cm_succ_msg">{this.state.successMessage}</span> : ''}
                    </div>

                </div>

            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        ...state
    };
}

export default connect(mapStateToProps)(AddService);

const loop = [1,2,3,4,5,6,7,8]