import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import moment from 'moment';
import { Tab, Tabs, Dropdown, Modal } from 'react-bootstrap';
import SideNav from '../template/SideNav';
import TopHeader from '../template/TopHeader';
import ClientCheckout from './ClientCheckout';
// import Invoice from './Invoice';
// import Checkout from './Checkout';
import { connect } from "react-redux";
import { bookingActions, hairdresserActions, userActions } from "../../_actions";
import siteSetting from "../../config/env";
import { Addicon } from "../SvgIcon";
import { bookingConstants, settingConstants } from "../../_constants";
import dateFormat from "dateformat";
import ReactWeeklyDayPicker from "react-weekly-day-picker";
import Checkout from "./Checkout";
import {bookingService} from "../../_services";
import Invoice from "./Invoice";
import {parse} from "query-string";
var _ = require('lodash');

let userIdHolder = [];
const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];

function getslotTime(time) {
    let hr = parseInt(time / 60);
    let min = time - (hr * 60)
    return hr + ':' + (min === 0 ? '00' : min);
}

class EditBooking extends Component {

    constructor(props) {
        super(props);

        this.state = {
            bookingDate: new Date(),
            selectSlot: '',
            showOptionsModal: false,
            showTipModal: false,
            latitude: siteSetting.default_latitude,
            longitude: siteSetting.default_longitude,
            hairdresserName: 'test',
            selectedService: [],
            finalBookingSlots: [],
            includeTimes: [],
            slotInfo: {},
            totalAmount: 0,
            totalTime: 0,
            hairdresserId: '',
            selectedClientId: '',
            selectedClient: {},
            isSaveBooking: 0,
            bookingSlots: [],
            paymentType: '',
            isBookingAllowed: false,
            searchKey: '',
            bookingType:'past', //upcoming
            pendingAmount:0,
            transaction:[],
            paymentAmount:0,
            totalPaymentAmount:0,
            isCheckout:0,
            userId:'',
            isInvoice:0,
            currencySymbol:"£",
            bookingId:"",
            isServiceEdit:false
        }
        this.editBooking = this.editBooking.bind(this);
        this.resetBooking = this.resetBooking.bind(this);
        this.checkoutBooking=this.checkoutBooking.bind(this);
        this.serPaymentType=this.serPaymentType.bind(this);
        this.onChanged=this.onChanged.bind(this);
        this.updateBooking=this.updateBooking.bind(this);
        this.completeSale=this.completeSale.bind(this);
        this.changeHairdresser = this.changeHairdresser.bind(this);
    }


    handleOptionModal = () => this.setState({ showOptionsModal: !this.state.showOptionsModal })

    handleTipModal = () => {
        this.setState({
            showOptionsModal: false,
            showTipModal: !this.state.showTipModal
        })
    }

    componentDidMount() {
        var self = this;
        let params = {
            defaultRedirectTo:localStorage.getItem('defaultRedirectTo')
        };
        let params1 = {
            searchKey:this.state.searchKey,
            'hairdresserId':localStorage.getItem('userId'),
            page:1,
            listType:1
        };
        if (localStorage.getItem('accessToken')&&this.props.match.params.bookingId!=='') {
            //self.props.dispatch(userActions.profile(params))
            let getparams = {
                bookingID:this.props.match.params.bookingId,
                getBookingsOfType:'updates'
            }
            self.props.dispatch(bookingActions.getHairdresserBooking(getparams))
                .then(function (response) {
                    console.log("getHairdresserBooking",response.data.data);
                    let servicesBooked=[];
                    self.setState({
                        selectedClient:response.data.data.bookings[0].user,
                        selectedClientId:response.data.data.bookings[0].userId
                    })
                    let totalTime=0,totalAmount=0;
                    response.data.data.bookings[0].servicesBooked.map((services, i) => {
                        totalTime=totalTime+services.serviceTime;
                        totalAmount=totalAmount+parseFloat(services.servicePrice);
                        services.serviceId=services.service.id;
                        services.serviceName=services.service.serviceName
                        servicesBooked.push(services)
                    })
                    let totalPaidAmount=0;
                    if(response.data.data.bookings[0].transaction&&response.data.data.bookings[0].transaction.length>0) {
                        response.data.data.bookings[0].transaction.map((transaction, i) => {
                            totalPaidAmount=totalPaidAmount+parseFloat(transaction.amount)
                        })
                    }

                    let pendingAmount=totalAmount-totalPaidAmount;
                    let paymentAmount=pendingAmount;
                    if(pendingAmount>0){
                        self.setState({
                            isServiceEdit:true
                        })
                    }
                    let incTimeSlot = new Date(response.data.data.bookings[0].bookingDate);
                    let hr = parseInt(response.data.data.bookings[0].bookingSlot[0] / 60);
                    let min = response.data.data.bookings[0].bookingSlot[0] - (hr * 60);
                    incTimeSlot.setHours(hr);
                    incTimeSlot.setMinutes(min);
                    self.setState({
                        selectSlot:incTimeSlot,
                        transaction:response.data.data.bookings[0].transaction,
                        bookingId:self.props.match.params.bookingId,
                        bookingDate:new Date(response.data.data.bookings[0].bookingDate),
                        selectedService:servicesBooked,
                        totalAmount: totalAmount.toFixed(2),
                        totalTime: totalTime,
                        pendingAmount:pendingAmount,
                        paymentAmount,
                        hairdresserId:response.data.data.bookings[0].hairdresserId,
                        userId:response.data.data.bookings[0].userId,
                        currencySymbol:response.data.data.bookings[0].hairdresser.hairdresser&&response.data.data.bookings[0].hairdresser.hairdresser.currencySymbol&&response.data.data.bookings[0].hairdresser.hairdresser.currencySymbol!==""?response.data.data.bookings[0].hairdresser.hairdresser.currencySymbol:"£"
                    },function (){
                        self.selectedHairdresser(self.state.hairdresserId,response.data.data.bookings[0].hairdresser.name,1,response.data.data.bookings[0].hairdresser);
                        self.selectDay(self.state.bookingDate);
                    })
                    self.props.dispatch(hairdresserActions.getHairdresserClientDetail({
                        'userId': response.data.data.bookings[0].userId,
                        'hairdresserId':response.data.data.bookings[0].hairdresserId,
                        'bookingType':self.state.bookingType
                    }))
                })
            self.props.dispatch(hairdresserActions.hairdresserStaffRota(params1))
                .then(function (response) {

                })
        } else {
            this.props.history.push('/')
        }
    }

    selectedHairdresser(hairdresserId, hairdresserName,type,hairdresser) {
        // console.log("====",hairdresserId, hairdresserName,type,hairdresser)
        this.setState({isSelected: true})
        this.setState({ startTimeError:'',serviceError:'',hairdresserId, hairdresserName })
        // console.log("salonId",salonId);
        //this.props.history.push("/salon/" + salonId + "/professional/" + hairdresserId);
        let params = {};
        let self = this;
        params.hairdresserId = hairdresserId;
        params.latitude = this.state.latitude;
        params.longitude = this.state.longitude;
        params.history = this.props.history;
        if(type===1){
            //self.props.dispatch(hairdresserActions.getHairdresserClient({ history: this.props.history, 'searchKey': this.state.searchKey, 'hairdresserId': hairdresserId, page: 1, listType: 1 }))
        }

        self.props.dispatch(hairdresserActions.getHairdresserInfo(params))
            .then(function (response) {

                if (response.data.errorCode) {
                    self.setState({
                        error: response.data.error.messages
                    })
                } else {
                    self.setState({
                        currencySymbol:hairdresser.hairdresser.currencySymbol&&hairdresser.hairdresser.currencySymbol!=""?hairdresser.hairdresser.currencySymbol:"£"
                    }, function () {
                        self.bookingSlotCreate(0);

                    })
                }
            });
    }

    changeHairdresser(hairdresserId, hairdresserName,type,hairdresser) {
        // console.log("====",hairdresserId, hairdresserName,type,hairdresser)
        this.setState({isSelected: true})
        this.setState({ startTimeError:'',serviceError:'',hairdresserId, hairdresserName })
        // console.log("salonId",salonId);
        //this.props.history.push("/salon/" + salonId + "/professional/" + hairdresserId);
        let params = {};
        let self = this;
        params.hairdresserId = hairdresserId;
        params.latitude = this.state.latitude;
        params.longitude = this.state.longitude;
        params.history = this.props.history;
        if(type===1){
            //self.props.dispatch(hairdresserActions.getHairdresserClient({ history: this.props.history, 'searchKey': this.state.searchKey, 'hairdresserId': hairdresserId, page: 1, listType: 1 }))
        }

        self.props.dispatch(hairdresserActions.getHairdresserInfo(params))
            .then(function (response) {

                if (response.data.errorCode) {
                    self.setState({
                        error: response.data.error.messages
                    })
                } else {
                    self.setState({
                        bookingSlots: response.data.data.bookingSlots,
                        date: new Date(),
                        selectedService: [],
                        isBookingButton: false,
                        selectedDay: new Date(),
                        month: monthNames[new Date().getMonth()],
                        year: new Date().getFullYear(),
                        totalAmount: 0,
                        totalTime: 0,
                        selectedBookingSlots: '',
                        selectedBookingSlotsIndex: '',
                        finalBookingSlots: [],
                        includeTimes: [],
                        pendingAmount:0,
                        transaction:[],
                        paymentAmount:0,
                        totalPaymentAmount:0,
                        currencySymbol:hairdresser.hairdresser.currencySymbol&&hairdresser.hairdresser.currencySymbol!=""?hairdresser.hairdresser.currencySymbol:"£"
                    }, function () {
                        self.bookingSlotCreate(0);
                    })
                }
            });
    }

    bookingSlotCreate(index) {

        let self = this;
        let finalBookingSlots = [];
        let includeTimes = [];
        let pre;
        let next;
        let tempArr = []
        let flag = false;
        let k = index;
        let arrslots = [];
        let bookingSlots = self.state.bookingSlots;
        //bookingSlots.map(function(slots, i) {
        for (let i = index; i < bookingSlots.length; i++) {
            // console.log(index,bookingSlots.length,i, self.state.totalTime, bookingSlots[i],bookingSlots[i].status);
            let check = false;
            //console.log('=====',tempArr,bookingSlots[i]);
            if (bookingSlots[i].status === 0) {
                tempArr.push(bookingSlots[i])
                //console.log('tempArr', tempArr,k,i);
                if (((tempArr[tempArr.length - 1]["startTime"]) - (tempArr[0]["startTime"])) >= self.state.totalTime) {

                    finalBookingSlots.push(tempArr[0]);

                    let hr = parseInt(tempArr[0].startTime / 60);
                    let min = tempArr[0].startTime - (hr * 60);

                    let incTimeSlot = new Date(self.state.bookingDate);
                    //console.log("incTimeSlot",incTimeSlot);
                    incTimeSlot.setHours(hr);
                    incTimeSlot.setMinutes(min);


                    includeTimes.push(incTimeSlot);
                    //console.log("tempArr[0]",incTimeSlot,includeTimes)

                    for (let m = 0; m < bookingSlots.length; m++) {
                        //console.log("###",tempArr[0]["startTime"],bookingSlots[m]["startTime"])
                        if (tempArr[0]["startTime"] === bookingSlots[m]["startTime"]) {
                            k = m + 1;
                            flag = true;
                            break;
                        }
                    }
                    tempArr = []
                    //console.log("if",tempArr.length)
                }
            }else if (bookingSlots[i].status === 1&&bookingSlots[i].list[0].info.id===self.state.bookingId){
                //for (let t = bookingSlots[i]["startTime"]; t < bookingSlots[i]["endTime"]; t+=10) {
                    //bookingSlots[i]["startTime"]=t;
                    let booking=bookingSlots[i];
                    //booking.startTime=t;
                    //console.log('t', t,booking);
                    tempArr.push(booking)
                    console.log('tempArr', tempArr,k,i,booking.startTime);
                    if (((tempArr[tempArr.length - 1]["startTime"]) - (tempArr[0]["startTime"])) >= self.state.totalTime) {

                        finalBookingSlots.push(tempArr[0]);

                        let hr = parseInt(tempArr[0].startTime / 60);
                        let min = tempArr[0].startTime - (hr * 60);

                        let incTimeSlot = new Date(self.state.bookingDate);
                        //console.log("incTimeSlot",incTimeSlot);
                        incTimeSlot.setHours(hr);
                        incTimeSlot.setMinutes(min);


                        includeTimes.push(incTimeSlot);
                        //console.log("tempArr[0]",incTimeSlot,includeTimes)

                        for (let m = 0; m < bookingSlots.length; m++) {
                            //console.log("###",tempArr[0]["startTime"],bookingSlots[m]["startTime"])
                            if (tempArr[0]["startTime"] === bookingSlots[m]["startTime"]) {
                                k = m + 1;
                                flag = true;
                                break;
                            }
                        }
                        tempArr = []
                        //console.log("if",tempArr.length)
                    } else {
                        //console.log("else",tempArr.length,tempArr,tempArr[tempArr.length - 1]["startTime"])
                    }
                //}
            } else {
                tempArr = []
            }

            if (flag === true) {
                break
            }
        }
        //console.log('finalBookingSlots', finalBookingSlots,flag, k < bookingSlots.length);
        if (flag && k < bookingSlots.length) {
            self.bookingSlotCreate(k)
        }

        if (finalBookingSlots[0]) {


            let finalslot = this.state.finalBookingSlots;
            finalslot.unshift(finalBookingSlots[0]);

            let incTimes = this.state.includeTimes;
            //console.log('incTimes', incTimes);
            incTimes.unshift(includeTimes[0]);
            // console.log('finalslot', finalslot);
            let morningCount = 0;
            let afternoonCount = 0;
            let eveningCount = 0;
            for (let i = 0; i < finalslot.length; i++) {
                let bookingSlot = finalslot[i];
                let bookingIncTimes = incTimes[i];
                if (bookingSlot) {
                    if (bookingSlot.status === 0 && bookingSlot.startTime < 720) {
                        morningCount = 1
                    }
                    if (bookingSlot.status === 0 && (bookingSlot.startTime >= 720 && bookingSlot.startTime < 1020)) {
                        afternoonCount = 1
                    }
                    if (bookingSlot.status === 0 && bookingSlot.startTime >= 1020) {
                        eveningCount = 1
                    }
                }

            }


            this.setState({
                finalBookingSlots: finalslot,
                includeTimes: incTimes,
                morningCount,
                afternoonCount,
                eveningCount,
                slotInfo: finalslot[0],
                //selectSlot: incTimes[0]
            })
        }
    }

    getslotTime(time) {
        let hr = parseInt(time / 60);
        let min = time - (hr * 60)
        return hr + ':' + (min === 0 ? '00' : min);
    }

    selectDay(e) {
        console.log('selectDay', e);
        this.state.bookingDate = e[0];
        this.state.selectedDay = e[0];
        //console.log(this.state.bookingDate)
        //this.setState({bookingDate:e[0]})
        let params = {};
        let self = this;
        let sdate = new Date(e[0])
        self.setState({
            bookingDate: e,
            month: monthNames[sdate.getMonth()],
            year: sdate.getFullYear()
        })
        let salonId = this.props.match.params.salonId;
        let hairdresserId = this.state.hairdresserId?this.state.hairdresserId:localStorage.getItem('userId');
        params.hairdresserId = hairdresserId;
        params.latitude = this.state.latitude
        params.longitude = this.state.longitude
        params.slotsForBookingDate = dateFormat(e, 'yyyy-mm-dd');
        self.props.dispatch(hairdresserActions.getHairdresserInfo(params))
            .then(function (response) {

                if (response.data.error) {
                    self.setState({
                        message: response.data.error.message
                    })
                } else {
                    console.log(response);
                    self.setState({
                        bookingSlots: response.data.data.bookingSlots,
                        selectedBookingSlotsIndex: '',
                        finalBookingSlots: [],
                        includeTimes: []
                    }, function () {
                        self.bookingSlotCreate(0);
                    })
                }
            });
    }

    unselectDay(e) {
        console.log('unselectDay', e);
    }

    checkSelectedService(serviceId) {
        let selectedService = this.state.selectedService;
        let isCheck = _.find(selectedService, function (o) {
            return o.serviceId === serviceId;
        })
        //console.log('isCheck',serviceId,isCheck)
        if (isCheck) {
            return true;
        } else {
            return false;
        }
    }

    selectService(value, service, selection) {
        this.setState({
            serviceError:''
        })
        //console.log('value', value, service, selection);
        let self = this;
        let totalAmount;
        let totalTime;
        let selectedService = this.state.selectedService;
        //console.log('selectedService', selectedService);
        if (value === 1) {

            if (selection === true) {
                selectedService.push(service);
                totalAmount = parseFloat(service.servicePrice) + parseFloat(this.state.totalAmount);
                totalTime = this.state.totalTime + service.serviceTime
            } else {
                //selectedService.pop(service);
                _.remove(selectedService, item => item.serviceId === service.serviceId);
                totalAmount = parseFloat(this.state.totalAmount) - parseFloat(service.servicePrice);
                totalTime = this.state.totalTime - service.serviceTime
            }
            let pendingAmount=totalAmount-this.state.totalPaymentAmount;
            //console.log('totalAmount',totalAmount);
            this.setState({
                selectedService,
                totalAmount,
                totalTime,
                pendingAmount,
                selectedBookingSlotsIndex: '',
                finalBookingSlots: [],
                includeTimes: []
            }, function () {
                self.bookingSlotCreate(0);
                if (self.state.selectedService.length > 0 && self.state.slotInfo.startTime) {
                    self.setState({
                        isBookingButton: true,
                    })
                } else {
                    self.setState({
                        isBookingButton: false,
                    })
                }
            })
        } else {
            self.setState({
                finalBookingSlots: [],
                includeTimes: [],
                selectedService: [],
                totalAmount: 0,
                totalTime: 0,
            })
        }
    }

    editBooking(param) {
        let self = this;

        let params = {};
        params.servicesToBook = _.map(this.state.selectedService, function (o) {
            return o.serviceId;
        })
        //params.userId = this.state.hairdresserId;
        params.bookingSlot = [this.state.slotInfo.startTime, (this.state.slotInfo.startTime + this.state.totalTime)]
        params.bookingDate = dateFormat(this.state.bookingDate, 'yyyy-mm-dd')
        params.deviceToken = "dfdsfdsfsd"
        params.isPayAtSalonBooking = 1;
        params.clientId = '';
        params.paidType = self.state.paidType;
        params.hairdresserId = self.state.hairdresserId;
        params.bookingID=self.props.match.params.bookingId;
        params.transaction = this.state.transaction;
        params.totalAmount = this.state.totalAmount;

        self.props.dispatch(bookingActions.hairdresserEditBooking(params))
            .then(function (response) {
                console.log('booking response', response);
                if (response.data.errorCode) {
                    self.setState({
                        bookingError: response.data.messages
                    })
                } else {
                   self.props.history.push('/booking/' + self.props.match.params.bookingId)
                }
            })
    }

    resetBooking() {
        this.setState({
            bookingDate: new Date(),
            //selectSlot: new Date(),
            showOptionsModal: false,
            showTipModal: false,
            latitude: siteSetting.default_latitude,
            longitude: siteSetting.default_longitude,
            hairdresserName: 'Select Staff Member',
            selectedService: [],
            finalBookingSlots: [],
            includeTimes: [],
            slotInfo: {},
            totalAmount: 0,
            totalTime: 0,
            hairdresserId: '',
            selectedClientId: '',
            selectedClient: {},
            isSaveBooking: 0,
            bookingSlots: [],
            paymentType: '',
            searchKey: ''
        })
    }

    changeClient() {
        this.setState({
            selectedClientId: '',
            selectedClient: {},
            isSaveBooking: 0,
            bookingSlots: [],
            paymentType: '',
            searchKey: ''
        })
    }

    handleEvent(e, d) {
        if (e === "eventStartTime") {
            let m = moment(d);
            let minutes = (m.hour() * 60) + m.minute();
            this.setState({ eventTime: d, eventStartTime: minutes })
        }
        if (e === "eventEndTime") {
            let m = moment(d);
            let minutes = (m.hour() * 60) + m.minute();
            this.setState({ endTime: d, eventEndTime: minutes })
        }
        if (typeof e === "object" && e.target.id === "eventTitle") {
            this.setState({ eventTitle: e.target.value })
        }
        if (typeof e === "object" && e.target.id === "isBookingAllowed") {
            this.setState({ isBookingAllowed: !this.state.isBookingAllowed })
        }
    }

    checkoutBooking(){
        this.setState({
            isCheckout:1
        })
    }

    serPaymentType(paymentType) {
        let transaction=this.state.transaction;

        transaction.push({
            paymentType:paymentType,
            amount:parseFloat(this.state.paymentAmount)
        })
        let totalPaymentAmount=parseFloat(this.state.totalPaymentAmount)+parseFloat(this.state.paymentAmount);
        let pendingAmount=parseFloat(this.state.totalAmount)-totalPaymentAmount;
        this.setState({
            totalPaymentAmount,
            transaction: transaction,
            pendingAmount:pendingAmount,
            paymentAmount:pendingAmount
        })
    }

    onChanged(e) {
        console.log("e",e.target);
        this.setState({ [e.target.name]: parseFloat(e.target.value)});

        if (e.target.name === 'pendingAmount') {
            let totalPaymentAmount=parseFloat(this.state.totalPaymentAmount)+parseFloat(e.target.value);
            let pendingAmount=parseFloat(this.state.totalAmount)-totalPaymentAmount;
            if (parseFloat(e.target.value) > pendingAmount) {
                this.setState({
                    paymentAmountError: 'Invalid Amount'
                })
            } else {
                this.setState({
                    paymentAmountError: '',
                })
            }
        }
    }

    updateBooking() {
        let params = {};
        params.servicesToBook = _.map(this.state.selectedService, function (o) {
            return o.serviceId;
        })
        params.userId = this.state.hairdresserId;
        params.transaction = this.state.transaction;
        //params.totalAmount = parseFloat(this.state.totalAmount).toFixed(2);
        params.bookingID=this.props.match.params.bookingId;
        bookingService.updateBooking(params)
            .then(function (response) {
                console.log('client response', response);
                window.location.reload();
            })
    }

    completeSale(){
        this.setState({isInvoice: 1})
    }

    selectSlot(e) {
        console.log("e", e);
        let self = this;
        this.setState({
            finalBookingSlots: [],
            includeTimes: []
        }, function () {
            self.bookingSlotCreate(0);
            //console.log('slotInfo,index',slotInfo,index,isSelect);
            let includeTimes = self.state.includeTimes;
            let finalBookingSlots = self.state.finalBookingSlots;
            let totalTime = self.state.totalTime;

            _.remove(includeTimes, function (o) {
                return o <= moment(e).add(totalTime, 'm').toDate() && o > e
            });

            let m = moment(e);
            let minutes = (m.hour() * 60) + m.minute();
            let slotInfo = _.find(finalBookingSlots, function (o) {

                return o.startTime === minutes
            })
            //finalBookingSlots=res;
            // console.log('finalBookingSlots',res,slotInfo,minutes);
            self.setState({
                slotInfo: slotInfo,
                includeTimes,
                selectSlot: e
            }, function () {

            })

        })
    }

    render() {
        console.log("this.props",this.props);
        console.log("this.state", this.state);
        let { bookingDate, showOptionsModal, showTipModal, hairdresserName } = this.state;
        let { hairdresser, salon } = this.props;
        return (
            <div className="h_n">

                    <div className="body_container bg_white">
                        <TopHeader title="Edit Booking" {...this.props} />
                        <SideNav {...this.props} />
                        {this.props.hairdresserBooking.bookings ?
                        <div className="booking-parent">

                            <div className="tab_content_wrapper">

                                {this.state.isInvoice===1 ? (
                                        <div className="invoice_card_wrapper">
                                            <div className="cm_head">
                                                <h3>Invoice - {moment().format("YYYYMMDDHHmmSS")}</h3>
                                                <p className="cm_fw_b">{moment().format("dddd, MMM Do YY")}</p>
                                            </div>

                                            <div className="cm_body">
                                                <ul>

                                                    {this.state.transaction.map((transaction, index) => (
                                                        <li key={"t_"+index}>{transaction.paymentType === 1 ? 'Pay by App' : ''}{transaction.paymentType === 2 ? 'Card' : ''}{transaction.paymentType === 3 ? 'Cash' : ''}{transaction.paymentType === 4 ? 'Other' : ''} - {this.state.currencySymbol}{transaction.amount.toFixed(2)}</li>
                                                    ))}
                                                    {/*<li>Tip - £4.20</li>*/}
                                                    <li>Total - {this.state.currencySymbol}{parseFloat(this.state.totalAmount).toFixed(2)}</li>

                                                </ul>
                                            </div>

                                            <div className="cm_footer">
                                                <h2>Balance - {this.state.currencySymbol}{parseFloat(this.state.pendingAmount).toFixed(2)}</h2>
                                            </div>
                                        </div>)

                                :(<div>

                                        <div className="cm_picker_wrap">
                                            <p>{moment(bookingDate).format('dddd DD MMM YYYY')} <i className="fa fa-angle-down" /></p>
                                            <DatePicker
                                                selected={this.state.bookingDate}
                                                minDate={new Date()}
                                                onChange={this.selectDay.bind(this)}
                                            />
                                        </div>

                                    <div className="booking_form_wrapper">

                                        <div className="cm_row m-0">
                                            <div className="cm_25">
                                                <div className="form-group">

                                                    <label>Start Time</label>
                                                    <div className="cm_picker_wrap">
                                                        <input type="text" value={this.state.selectSlot ? moment(this.state.selectSlot).format('HH:mm') : ""} placeholder="00:00" className={`form-control ${this.state.isSelected ? '' : 'is_readonly'}`} />
                                                        <DatePicker
                                                            selected={this.state.selectSlot}
                                                            showTimeSelect
                                                            showTimeSelectOnly
                                                            timeIntervals={10}
                                                            timeCaption="Time"
                                                            dateFormat="HH:mm"
                                                            timeFormat="HH:mm"
                                                            includeTimes={this.state.includeTimes}
                                                            onChange={this.selectSlot.bind(this)}
                                                        />
                                                        {this.state.startTimeError ?
                                                            <span className="cm_error_msg">{this.state.startTimeError}</span> : ''}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="cm_75">
                                                <div className="form-group">
                                                    <label>Staff Member</label>

                                                    <div className="cm__boxs">
                                                        <div className="cm__box" >

                                                        <div className="table-responsive  cm_table">
                                                            <table>
                                                                <tbody>

                                                                        <tr >
                                                                            <td className="t_width">{this.props.hairdresserBooking.bookings[0].hairdresser.name}</td>
                                                                        </tr>

                                                                </tbody>
                                                            </table>
                                                        </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/*<div className="cm_75">*/}
                                            {/*    <div className="form-group">*/}
                                            {/*        <label>Staff Member</label>*/}

                                            {/*        <Dropdown className="cm_select_wrapper">*/}
                                            {/*            <Dropdown.Toggle className="cm_select" id="dropdown-basic" >{hairdresserName}</Dropdown.Toggle>*/}

                                            {/*            <Dropdown.Menu>*/}
                                            {/*                {this.props.staffRota.rota && this.props.staffRota.rota.length>0 ? (*/}
                                            {/*                    <ul className="cm_select_ul">*/}
                                            {/*                        {this.props.staffRota.rota.map((hairdresser, i) => (*/}
                                            {/*                            hairdresser.name !== 'Unknown' && hairdresser.email !== 'Unknown' ?*/}
                                            {/*                                (<li key={hairdresser._id} onClick={this.changeHairdresser.bind(this, hairdresser._id, hairdresser.name,1,hairdresser)}><Dropdown.Item>{hairdresser.name}</Dropdown.Item></li>)*/}
                                            {/*                                : ""))}*/}

                                            {/*                    </ul>*/}
                                            {/*                ) : ""}*/}
                                            {/*            </Dropdown.Menu>*/}
                                            {/*        </Dropdown>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                        </div>

                                        <div className="cm_row">
                                            <div className="cm_25">
                                                <div className="form-group">
                                                    <label>Duration</label>
                                                    <input type="text" className="form-control" value={this.state.totalTime + " m"}  />
                                                </div>
                                            </div>
                                            {this.state.isServiceEdit===true?
                                                <div className="cm_75">
                                                    <label>Service</label>

                                                    <div className="table-responsive srvc_table">
                                                        {hairdresser.hairdresserServices && hairdresser.hairdresserServices.length > 0 ?
                                                            <div className="table-responsive srvc_table">
                                                                <table className="table">
                                                                    <tbody>
                                                                    {hairdresser.hairdresserServices.map((category, i) => (
                                                                        <React.Fragment key={category._id}>
                                                                            {i === 0 ? <tr>
                                                                                    <th olSpan="4"><h3
                                                                                        className="cm_fw_l">{category.categoryName}</h3>
                                                                                    </th>
                                                                                    {/*<th>Duration</th>*/}
                                                                                    {/*<th>Cost</th>*/}
                                                                                </tr> :
                                                                                <tr>
                                                                                    <td colSpan="4"><h3
                                                                                        className="cm_fw_l">{category.categoryName}</h3>
                                                                                    </td>
                                                                                </tr>}
                                                                            {category.hairdresserService.map((services, i) => (
                                                                                <tr className="left_bg"
                                                                                    key={services._id}
                                                                                    onClick={this.selectService.bind(this, 1, services, !this.checkSelectedService(services.serviceId))}>
                                                                                    <td className="pointer-event">{services.serviceName}</td>
                                                                                    <td>{services.serviceTime !== 0 ? services.serviceTime + "m" : "--"}</td>
                                                                                    <td className="position-relative cross_wrap">{services.serviceTime !== 0 ? hairdresser.userInfo.hairdresser.currencySymbol && hairdresser.userInfo.hairdresser.currencySymbol !== "" ? hairdresser.userInfo.hairdresser.currencySymbol + services.servicePrice.toFixed(2) : "£" + services.servicePrice.toFixed(2) : "--"}</td>
                                                                                    {/*<td onClick={this.serviceSelect.bind(this, services, !this.checkSelectedService(services._id))}>*/}
                                                                                    <td className="i_width">
                                                                                        {this.checkSelectedService(services.serviceId) ? (
                                                                                            <i className="fa fa-check-circle"></i>
                                                                                        ) : (
                                                                                            <Addicon/>
                                                                                        )}
                                                                                    </td>
                                                                                </tr>
                                                                            ))}

                                                                        </React.Fragment>
                                                                    ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            : <div className="rating-header nodiv">
                                                                <p>No Services Offered </p>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>:
                                                <div className="cm_75">
                                                    <label>Service</label>
                                                    <div className="cm__boxs">
                                                        <div className="cm__box">
                                                            <div className="table-responsive  cm_table">
                                                                <table>
                                                                    <tbody>
                                                                    {this.props.hairdresserBooking.bookings && this.props.hairdresserBooking.bookings[0].servicesBooked.length > 0 ?
                                                                        this.props.hairdresserBooking.bookings[0].servicesBooked.map((services, i) => (
                                                                            <tr key={services._id}>
                                                                                <td className="t_width">{services.service.serviceName}</td>
                                                                                <td>{services.serviceTime}m</td>
                                                                                <td>{this.state.currencySymbol}{services.servicePrice.toFixed(2)}</td>
                                                                            </tr>
                                                                        ))
                                                                        :
                                                                        <div className="rating-header nodiv">
                                                                            <p>No Services Offered </p>
                                                                        </div>
                                                                    }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                            }
                                        </div>

                                    </div>

                                    {this.state.transaction.length > 0?
                                        <div className="cm_width_25">
                                            <div className="cm_body">
                                                <br/>
                                                {this.state.bookingError ?
                                                    <span className="cm_error_msg">{this.state.bookingError}</span> : ''}
                                                <ul>

                                                    {this.state.transaction.map((transaction, index) => (
                                                        <li key={"t_"+index}>{transaction.paymentType === 1 ? 'Pay by App' : ''}{transaction.paymentType === 2 ? 'Card' : ''}{transaction.paymentType === 3 ? 'Cash' : ''}{transaction.paymentType === 4 ? 'Other' : ''} - {this.state.currencySymbol}{transaction.amount.toFixed(2)}</li>
                                                    ))}
                                                    {/*<li>Tip - £4.20</li>*/}
                                                    <li>Total - {this.state.currencySymbol}{parseFloat(this.state.totalAmount).toFixed(2)}</li>

                                                </ul>
                                            </div>
                                            <div />

                                            <div className="cm_footer">
                                                <h2>Balance - {this.state.currencySymbol}{parseFloat(this.state.pendingAmount).toFixed(2)}</h2>
                                            </div>
                                        </div>:<div className="cm_width_25">
                                            <div className="cm_body">
                                                <br/>
                                                {this.state.bookingError ?
                                                    <span className="cm_error_msg">{this.state.bookingError}</span> : ''}
                                                <ul>

                                                    {this.props.hairdresserBooking.bookings[0].transaction&&this.props.hairdresserBooking.bookings[0].transaction.length>0?
                                                        this.props.hairdresserBooking.bookings[0].transaction.map((transaction, index) => (
                                                            <li key={"t_"+index}>{transaction.paymentType === 1 ? 'Pay by App' : ''}{transaction.paymentType === 2 ? 'Card' : ''}{transaction.paymentType === 3 ? 'Cash' : ''}{transaction.paymentType === 4 ? 'Other' : ''} - {this.state.currencySymbol}{transaction.amount.toFixed(2)}</li>
                                                        )):""}
                                                    {/*<li>Tip - £4.20</li>*/}
                                                    <li>Total - {this.state.currencySymbol}{parseFloat(this.state.totalAmount).toFixed(2)}</li>

                                                </ul>
                                            </div>
                                            <div/>

                                            <div className="cm_footer">
                                                <h2>Balance - {this.state.currencySymbol}{this.state.pendingAmount?parseFloat(this.state.pendingAmount).toFixed(2):0}</h2>
                                            </div>
                                        </div>}


                                </div>)}

                            </div>
                            {
                                this.state.isInvoice === 0 ?
                                this.state.isCheckout === 1 ? (
                                    <Checkout saveBooking={this.saveBooking} onChangedValue={this.onChanged}
                                              pendingAmount={this.state.pendingAmount}
                                              currencySymbol={this.state.currencySymbol}
                                              paymentAmountError={this.state.paymentAmountError}
                                              selectedClient={this.state.selectedClient}
                                              selectedService={this.state.selectedService}
                                              totalAmount={this.state.totalAmount} paymentType={this.state.paymentType}
                                              serPaymentType={this.serPaymentType} resetBooking={this.resetBooking}
                                              paymentAmount={this.state.paymentAmount}
                                              completeSale={this.completeSale}
                                              hairdresserId={this.state.hairdresserId}
                                              bookings={this.props.hairdresserBooking.bookings[0]}
                                    />
                                ) :
                                <ClientCheckout selectedClientId={this.state.selectedClientId}
                                                selectedClient={this.props.hairdresserBooking.bookings ? this.props.hairdresserBooking.bookings[0].user : ""}
                                                changeClient={this.changeClient}
                                                currencySymbol={this.state.currencySymbol}
                                                pendingAmount={this.state.pendingAmount}
                                                checkoutBooking={this.checkoutBooking}
                                                editBooking={this.editBooking}
                                                userId={this.state.userId}
                                                hairdresserId={this.state.hairdresserId}
                                                bookings={this.props.hairdresserBooking.bookings[0]}

                                />
                                :
                                    (
                                        <Invoice editBooking={this.editBooking} selectedClient={this.state.selectedClient} selectedService={this.state.selectedService} totalAmount={this.state.totalAmount} createBooking={this.createBooking} paymentType={this.state.paymentType} resetBooking={this.resetBooking} />
                                    )
                            }

                        </div>
                            : <div className="cm_loader"><div className="loader"></div></div>}
                    </div>

            </div>

        )
    }

}

function mapStateToProps(state) {
    return {
        ...state
    };
}

export default connect(mapStateToProps)(EditBooking);

