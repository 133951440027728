import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Tabs, Tab, Modal } from 'react-bootstrap';

import SideNav from '../template/SideNav';
import TopHeader from '../template/TopHeader';
import { Link, Redirect } from 'react-router-dom';
import siteSetting from "../../config/env";
import {userActions} from "../../_actions";
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import {salonService} from "../../_services";

const getSuggestionValue = suggestion => suggestion.name;

// Use your imagination to render suggestions.
const renderSuggestion = suggestion => (
    <div>
        {suggestion.name}
    </div>
);
const searchOptions = {
    //componentRestrictions: { country: ['GB'] },
    //types: ['city']
}
class Settings extends Component {

    constructor(props){
        super(props);

        this.state = {
            isProfileSettingTab: false,
            salonImagePrev:require('../../assets/images/salonpic.png'),
            salonImage:'',
            salonName:'',
            allow:false,
            salonUrl:'',
            deviceToken:'asfasfasfas',
            isShow:localStorage.getItem('isShow'),
            deleteSalonModal: false,
        }
        this.onChanged=this.onChanged.bind(this);
        this.updateSalon=this.updateSalon.bind(this);
        this.checkReadOnly=this.checkReadOnly.bind(this);
    }

    componentDidMount() {
        let employmentType={};
        var self = this;
        if (localStorage.getItem('accessToken')) {
            let params={
                defaultRedirectTo:localStorage.getItem('defaultRedirectTo')
            }
            params.latitude = siteSetting.default_latitude;
            params.longitude = siteSetting.default_longitude;
            self.props.dispatch(userActions.profile(params))
                .then(function (response) {
                    console.log("response",response.data.data);
                    if(response.data.data) {
                        if(self.props.user.userInfo.hairdresser.salon){
                            self.setState({
                                salonUrl:siteSetting.api.weburl+'salon/' +self.props.user.userInfo.hairdresser.salon._id,
                                salonName:self.props.user.userInfo.hairdresser.salon.salonName,
                                formattedAddress:self.props.user.userInfo.hairdresser.salon.formattedAddress,
                                administrativeAreaLevel1: self.props.user.userInfo.hairdresser.salon.administrativeAreaLevel1,
                                administrativeAreaLevel2: self.props.user.userInfo.hairdresser.salon.administrativeAreaLevel2,
                                countryLongName: self.props.user.userInfo.hairdresser.salon.countryLongName,
                                countryShortName: self.props.user.userInfo.hairdresser.salon.countryShortName,
                                latitude: self.props.user.userInfo.hairdresser.salon.loc[1],
                                longitude: self.props.user.userInfo.hairdresser.salon.loc[0],
                                salonId: self.props.user.userInfo.hairdresser.salon._id,
                                isShow: self.props.user.userInfo.hairdresser.salon.isShow,
                                postalCode:self.props.user.userInfo.hairdresser.salon.postalCode,
                                salonImagePrev:self.props.user.userInfo.hairdresser.salon.imageUrl?self.props.user.userInfo.hairdresser.salon.imageUrl:require('../../assets/images/salonpic.png')
                            })
                        }
                    }else{
                        self.props.history.push('/')
                    }
                })
                .catch((err) => {
                    self.props.history.push('/')
                })
        }else{
            self.props.history.push('/')
        }
    }

    handleChange = address => {
        this.setState({ formattedAddress:address });
    };

    handleSelect = address => {
        geocodeByAddress(address)
            .then(results => {
                // parseGooglePlace(results)
                //console.log("results===>>",results[0]);
                let countryShortName='';
                let countryLongName='';
                let postalCode='';
                let administrativeAreaLevel1='';
                let administrativeAreaLevel2='';

                const address_components = results[0].address_components;
                for (let i = 0; i < address_components.length; i++) {
                    const component = address_components[i];
                    if (component['types'][0]) {
                        if (component['types'][0] === 'administrative_area_level_2') {

                            administrativeAreaLevel2 = component['long_name'];

                        }
                        if (component['types'][0] === 'administrative_area_level_1') {

                            administrativeAreaLevel1 = component['short_name'];

                        }
                        if (component['types'][0] === 'postal_code') {

                            postalCode = component['long_name'];

                        }
                        if (component['types'][0] === 'country') {

                            countryShortName = component['short_name'];
                            countryLongName= component['long_name'];

                        }
                    }
                }
                this.setState({
                    formattedAddress:results[0].formatted_address,
                    countryShortName:countryShortName,
                    countryLongName:countryLongName,
                    postalCode:postalCode,
                    administrativeAreaLevel1:administrativeAreaLevel1,
                    administrativeAreaLevel2:administrativeAreaLevel2,
                });

                return getLatLng(results[0])
            })
            .then(latLng => {
                console.log('Success', latLng);
                this.setState({
                    latitude: latLng.lat,
                    longitude: latLng.lng,
                })
            })
            .catch(error => console.error('Error', error));
    };

    onChanged(e) {
        //console.log("e.target.name",e.target)
        this.setState({ [e.target.name]: e.target.value, copied: false });

        if (e.target.name === 'email') {
            if (!this.validateEmail(e.target.value)) {
                this.setState({
                    emailError: 'Please enter a valid email address'
                })
            } else {
                this.setState({
                    emailError: ''
                })
            }
        }

        if (e.target.name === 'first_name') {
            if (e.target.value === '' || e.target.value === null) {
                this.setState({
                    fNameError: ''
                })
            } else {
                this.setState({
                    fNameError: '',
                })
            }
        }

        if (e.target.name === 'salonImage') {
            // console.log("e",e);
            let reader = new FileReader();
            let file = e.target.files[0];

            reader.onloadend = () => {
                this.setState({
                    salonImagePrev: reader.result
                });
            }
            reader.readAsDataURL(file)

            this.setState({
                salonImagePrev: file
            })
            this.setState({
                salonImage: e.target.files[0]
            });
        }
    }

    toggleChange = () => {
        this.setState({
            isShow: !this.state.isShow,
        });
    }

    clip=(event)=>{
        event.target.select();
        navigator.clipboard.writeText(this.state.salonUrl)
    }

    updateSalon(){
        this.setState({
            error:''
        })
        let self = this;

            if (this.state.salonName === "" || this.state.salonName === undefined) {
                this.setState({
                    salonNameError: 'Please enter salon name'
                })
            } else if (this.state.formattedAddress === "" || this.state.formattedAddress === undefined) {
                this.setState({
                    formattedAddressError: 'Please enter salon address'
                })
            } else {
                let data = new FormData();
                data.append('salonId', this.state.salonId);
                data.append('salonName', this.state.salonName);
                data.append('formattedAddress', this.state.formattedAddress);
                data.append('latitude', parseFloat(this.state.latitude));
                data.append('longitude', parseFloat(this.state.longitude));
                data.append('countryShortName', this.state.countryShortName);
                data.append('countryLongName', this.state.countryLongName);
                data.append('postalCode', this.state.postalCode);
                data.append('administrativeAreaLevel1', this.state.administrativeAreaLevel1);
                data.append('administrativeAreaLevel2', this.state.administrativeAreaLevel2);
                data.append('deviceToken', this.state.deviceToken);
                data.append('image', this.state.salonImage);
                data.append('isShow', this.state.isShow);

                salonService.updateSalon(data)
                    .then(function (response) {
                        console.log('client response', response);

                        if (response.data.errorCode) {
                            if (response.data.errorCode == 51) {
                                self.setState({
                                    salonNameError: response.data.messages
                                })
                            }
                            else {
                                self.setState({
                                    error: response.data.messages
                                })
                            }
                        } else {
                            localStorage.setItem('isShow',self.state.isShow);
                            //self.props.history.push('/setting')
                            self.setState({
                                successMessage:'Your salon has been updated successfully!'
                            })
                        }
                    })
                    .catch((err) => {
                        console.log("errr", err);
                        //dispatch(failure(err));

                    })
            }

    }

    gotoHome=()=>{
        this.props.history.push('/diary/'+localStorage.getItem('salonId'))
    }

    handleClose = () => this.setState({
        deleteSalonModal: false,
    })

    checkReadOnly(){
        if(this.props.user&&this.props.user.userInfo&&this.props.user.userInfo.hairdresser.isSalonOwner===1){
            return true;
        }else{
            return false;
        }
    }

    noClick=()=>{

    }
    
    render() {
        let {
            deleteSalonModal,
        } = this.state;
        console.log("settings",this.state,this.checkReadOnly());
        // console.log("settings props",this.props);
        let { isProfileSettingTab } = this.state;
        return (
            <div className="h_n">
            <div className="body_container cm_bg_white setting_page">

                <TopHeader title="Settings" {...this.props}/>
                <SideNav {...this.props}/>

                    <nav className="nav nav-tabs">
                        <Link className="nav-item nav-link active" to="/settings">Salon Settings</Link>
                        <Link className="nav-item nav-link" to="/settings/account">Account Settings</Link>
                        <Link className="nav-item nav-link" to="/settings/profile">Profile Settings</Link>
                    </nav>
                    <div className="seetings_wrap_box">

                        <div className="row">
                            <div className="col-md-6 border_right">

                                <div className="form-group">
                                    <label>Salon Image</label>
                                    <div className="file_upload_wrap">
                                        {this.checkReadOnly()===true?
                                        <input ref={(ref) => this.salonImage = ref}  type="file" name="salonImage" id="salonImage" onChange={this.onChanged}/>
                                        :""}
                                        <img src={this.state.salonImagePrev} alt="staff" onClick={(e) => this.checkReadOnly()===true?this.salonImage.click():this.noClick()}/>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label>Salon Name</label>
                                    <input type="text" className="form-control" value={this.state.salonName} name="salonName" onChange={this.onChanged} disabled={this.checkReadOnly()===true? "":"disabled"}/>
                                    {this.state.salonNameError ?
                                        <span className="cm_error_msg">{this.state.salonNameError}</span> : ''}
                                </div>

                                <div className="form-group">
                                    <label>Salon Location</label>
                                    {/*<input type="text" className="form-control" value="12, Thrift Street, London, SW21 6CL" />*/}
                                    <div className={this.checkReadOnly()===true?"address_input_wrap w-100":"address_input_wrap disabled w-100"}>
                                        <PlacesAutocomplete
                                            searchOptions={searchOptions}
                                            //disabled={this.checkReadOnly().toString()}
                                            value={this.state.formattedAddress}
                                            onChange={this.handleChange}
                                            onSelect={this.handleSelect}
                                        >
                                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                <div>
                                                    <input
                                                        {...getInputProps({
                                                            placeholder: 'Search Places ...',
                                                            className: 'location-search-input',
                                                        })}
                                                    />
                                                    {
                                                        suggestions.length ? <div className="autocomplete_location">
                                                            {loading && <div>Loading...</div>}
                                                            {suggestions.map(suggestion => {
                                                                const className = suggestion.active
                                                                    ? 'suggestion-item--active'
                                                                    : 'suggestion-item';
                                                                // inline style for demonstration purpose
                                                                const style = suggestion.active
                                                                    ? { backgroundColor: '#ffffff', cursor: 'pointer' }
                                                                    : { backgroundColor: '#e4e4e4', cursor: 'pointer' };
                                                                return (
                                                                    <div
                                                                        {...getSuggestionItemProps(suggestion, {
                                                                            className,
                                                                            style,
                                                                        })}
                                                                    >
                                                                        <span>{suggestion.description}</span>
                                                                    </div>
                                                                );
                                                            })}

                                                        </div> : ''
                                                    }

                                                </div>
                                            )}
                                        </PlacesAutocomplete>

                                        {/*<div className={`cm_check_btn ${this.state.isSalonCreated ? 'active' : ''}`}>*/}
                                        {/*    <i className="fa fa-check" onClick={this.salonCreate.bind(this)}/>*/}
                                        {/*</div>*/}

                                    </div>
                                    {this.state.formattedAddressError ?
                                        <span className="cm_error_msg">{this.state.formattedAddressError}</span> : ''}
                                </div>

                            </div>

                            <div className="col-md-6">
                                {this.state.isShow==true?
                                (<><div className="text-center link_img">
                                    <img src={require('../../assets/images/link-url.png')} />
                                    <h3>Online Booking URL</h3>
                                </div>


                                <div className="form-group">
                                    <label>Click to Copy</label>
                                    <input type="text" className="form-control" value={this.state.salonUrl} onClick={this.clip.bind(this)} disabled={this.checkReadOnly()?"": "disabled"} />
                                </div></>):(
                                    <>
                                        <div className="text-center link_img">
                                            <img src={require('../../assets/images/login-step-4.png')} />
                                            <h3>Online Bookings</h3>
                                        </div>


                                        <div className="form-group text-center mb-4">
                                            <p>Start taking bookings through your website and social media.</p>
                                        </div>
                                    </>
                                    )}

                                <div className="form-group">
                                    <div className="check_wraper">
                                        <input type="checkbox" checked={this.state.isShow} name="allow" onChange={this.toggleChange} disabled={this.checkReadOnly()?"": "disabled"} /><span />
                                    Allow clients to book directly through your website, social media and the Beu marketplace.
                                    </div>
                                </div>
                                {this.state.allow==false?
                                        (<div className="text-right">
                                        <ul className="cm_check_list">
                                            <li>salon photo added</li>
                                            <li>location added </li>
                                        </ul>
                                    </div>):''}

                            </div>
                        </div>

                    </div>
                {this.checkReadOnly()===true?
                    <div className="text-right mt-4 pt-2">

                        {/*<button className="btn btn-pink pl-4 pr-4">Delete Salon</button>*/}
                        <button className="btn btn-outline-dark pl-4 pr-4 ml-3" onClick={this.gotoHome}>Cancel</button>
                        <button type="button" className="btn btn-dark ml-3 pl-4 pr-4" onClick={this.updateSalon.bind(this)}>Save</button>
                        <br/>
                        <br/>
                        {this.state.error ?
                            <span className="cm_error_msg">{this.state.error}</span> : ''}
                        {this.state.successMessage ?
                            <span className="cm_succ_msg">{this.state.successMessage}</span> : ''}
                    </div>
                    :""}
            </div>

                <Modal show={deleteSalonModal} onHide={this.handleClose} className="bg_white_modal text-center">
                    <i className="fa fa-times cm_hide_mod" onClick={this.handleClose} />
                    <Modal.Body>
                        <div className="p-5">
                            <img src={require('../../assets/images/delete-salon-img.png')} className="img-fluid mb-3" />
                            <h3 style={{fontSize: '38px', fontWeight: '400'}} className="mb-4">Delete Salon</h3>
                            <p style={{fontSize: '23px', fontWeight: '300'}}>Are you sure you want to delete the salon? This will cancel all appointments and remove the salon from Beu. Staff will be free to join or create another salon.</p>

                            <div className="stap-action mt-5">
                                <button className="btn btn-outline-dark mr-3">Cancel</button>
                                <button className="btn btn-pink">Delete Salon</button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

        </div>
        )
    }

}



function mapStateToProps(state) {
    return {
        ...state
    };
}

export default connect(mapStateToProps)(Settings);





