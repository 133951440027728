import { hairdresserConstants } from '../_constants';


const initialState = {
};

export function clientDetail(state = initialState, action) {
  //console.log('action.type',action);
  switch (action.type) {

    case hairdresserConstants.HAIRDRESSER_CLIENTDETAIL_SUCCESS:
      let data = action.getHairdresserClientDetailSuccess;
      return data;

    case hairdresserConstants.HAIRDRESSER_CLIENT_ERROR:
      return {};

    case hairdresserConstants.HAIRDRESSER_CLIENT_CLEAR:
      return {};

    default:
      return state
  }
}
