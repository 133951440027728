export const pageConstants = {

    T_AND_C_REQUEST            : 'T_AND_C_REQUEST',
    T_AND_C_SUCCESS           : 'T_AND_C_SUCCESS',
    T_AND_C_ERROR          : 'T_AND_C_ERROR',

    P_AND_P_REQUEST         :'P_AND_P_REQUEST',
    P_AND_P_SUCCESS         :'P_AND_P_SUCCESS',
    P_AND_P_FAILURE         :'P_AND_P_FAILURE',

    GDPR_REQUEST            :'GDPR_REQUEST',
    GDPR_SUCCESS            :'GDPR_SUCCESS',
    GDPR_ERROR              :'GDPR_ERROR',

    H_T_AND_C_REQUEST            : 'H_T_AND_C_REQUEST',
    H_T_AND_C_SUCCESS           : 'H_T_AND_C_SUCCESS',
    H_T_AND_C_ERROR          : 'H_T_AND_C_ERROR',

};
