import { userConstants } from '../_constants';

const initialReviewState = [];
export function userReview(state = initialReviewState, action) {
  //console.log('action.type',action);
  switch (action.type) {

    case userConstants.REVIEW_SUCCESS:
      let hairdresserReview=action.reviewSuccess;
      return hairdresserReview;

    case userConstants.REVIEW_ERROR:
      return {};

    default:
      return state
  }
}
