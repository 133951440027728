import React, { Component } from 'react';
import { connect } from 'react-redux';
import SideNav from '../template/SideNav';
import TopHeader from '../template/TopHeader';
import {Link} from 'react-router-dom';
import CalendarCard from './CalendarCard';
import {Plus} from '../SvgIcon';
import { hairdresserActions,eventActions} from '../../_actions';
import siteSetting from "../../config/env";
import moment from 'moment';
import promise from 'bluebird'

// import {hairdresserConstants} from "../../_constants";
// import Steps from '../hairdresserAuth/Steps';
// import GoThroughModalAfterLogin from '../hairdresserAuth/GoThroughModalAfterLogin';
// import Login from '../hairdresserAuth/Login';
import DailySchedule from 'reactjs-schedule-calendar'
import 'reactjs-schedule-calendar/dist/index.css'

const exclude = [
    //{ start: 0, end: 480 },
    // { start: 1080, end: 1440 },
    //{ start: 1320, end: 1440 }
]
var _ = require('lodash');
let userIdHolder = [];
const newCalendar = [];
let timeSlot=[];
for(let i=480;i<=24*60;i+=10){
    timeSlot.push(i)
}
const colors = ['#D6D5EC', '#C8EEEE', '#D6D5EC'];

function getslotTime(time) {
    let hr = parseInt(time / 60);
    let min = time - (hr * 60);
    //console.log("time",time,hr,min);
    return hr + ':' + (min == 0 ? '00' : min);
}

function getServiceName(item){
    let serviceName="";
    if(item.list[0].info.servicesBooked){
        item.list[0].info.servicesBooked.map((service)=>{
            serviceName=serviceName+(serviceName==""?"":", ");
            serviceName=serviceName+service.service.serviceName;
        })
    }
    return serviceName;
}

class DiaryPage extends Component {

    constructor(props){
        super(props);
        this.state={
            scrollTopPos: 0,
            tempArray:[],
            bookingDate:this.props.location.state&&this.props.location.state.bookingDate?moment(this.props.location.state.bookingDate).format('YYYY-MM-DD'):moment().format('YYYY-MM-DD'),
            latitude : siteSetting.default_latitude,
            longitude : siteSetting.default_longitude,
            hairdresserList:[],
        }
        this.getslotTime=this.getslotTime.bind(this);
        this.setCalenderDate=this.setCalenderDate.bind(this);
        this.getCalenderData=this.getCalenderData.bind(this);
        this.noLocation=this.noLocation.bind(this);
        this.foundLocation=this.foundLocation.bind(this);
        this.onMoveEvent = this.onMoveEvent.bind(this);
        this.gotoEvent=this.gotoEvent.bind(this);
        this.getHairdresserTime=this.getHairdresserTime.bind(this);
        this.getHairdresserTimeClass=this.getHairdresserTimeClass.bind(this);
        this.checkHairdresserTime=this.checkHairdresserTime.bind(this);
        this.getHairdresserTimeClassDiary=this.getHairdresserTimeClassDiary.bind(this);
    }

    onMoveEvent(data) {
        console.log("data",data)
        // this.setState({
        //     appointments: this.state.appointments.map(appointment => {
        //         if (appointment.id === data.source.id) {
        //             const duration = data.source.end - data.source.start
        //             return { ...appointment, start: data.target.start, end: data.target.start + duration }
        //         }
        //         return appointment
        //     })
        // })
    }

    addAppointment(data) {
        let self=this;
        console.log("start",data);

        let info={
            end: data.end,
            passedData: data.passedData,
            start: data.start,
            bookingDate:self.state.bookingDate
        };

        // this.setState({
        //     appointments: [
        //         ...this.state.appointments,
        //         { start, end, id: this.state.appointments.length },
        //     ]
        // });
        self.props.history.push('/new-booking/'+localStorage.getItem('salonId')+'/'+data.passedData.hairdresserId,info);
    }

    componentWillUpdate(prevProps){
        let i = 13;
        let { calendar, bookingUpdatesCount } = prevProps.hairdresserSchedule;
        let arrayIndex;
        while(i--){
            newCalendar.push("");  
        }

        if(calendar) {
            calendar.map(function (data, index) {
                arrayIndex = Math.ceil(data.startTime / bookingUpdatesCount)
                arrayIndex = arrayIndex % 9;
                newCalendar[arrayIndex] = data
            })
        }
    }

    componentDidMount(){

        document.body.classList.remove('menu_open');

        var self = this;
        if(localStorage.getItem('accessToken')){
            if(self.props.location.state&&self.props.location.state.bookingDate){
                let hairdresserId = localStorage.getItem('userId');
                let params = {
                    searchKey: this.state.searchKey,
                    'hairdresserId': hairdresserId,
                    page: 1,
                    listType: 1
                };
                self.props.dispatch(hairdresserActions.hairdresserStaffRota(params))
                    .then(function (response) {
                        self.setCalenderDate(moment(self.props.location.state.bookingDate).format('YYYY-MM-DD'))
                    })
            }else {
                let hairdresserId = localStorage.getItem('userId');
                let params = {
                    searchKey: this.state.searchKey,
                    'hairdresserId': hairdresserId,
                    page: 1,
                    listType: 1
                };
                //navigator.geolocation.getCurrentPosition(this.foundLocation,this.noLocation.bind(this))
                self.props.dispatch(hairdresserActions.getHairdresserScheduleReset())
                self.props.dispatch(hairdresserActions.hairdresserStaffRota(params))
                    .then(function (response) {
                        let Promisekey = [];
                        let startTime = 1440;
                        response.data.data.rota.map((hairdresser, i) => {
                            // Promisekey.push(self.getHairdresserScheduleList(hairdresser._id,hairdresser,i))
                            self.getHairdresserScheduleList(hairdresser._id, hairdresser, i);
                            if (hairdresser.rotaInfo.rota.schedule) {
                                //console.log("hairdresser.rotaInfo.rota",hairdresser.rotaInfo.rota.schedule[0],moment().format('ddd').toLowerCase());
                                let selected = _.find(hairdresser.rotaInfo.rota.schedule[0].profiles, function (o) {
                                    return o.day === moment().format('ddd').toLowerCase();
                                })
                                if (selected) {
                                    //console.log("selected.startTime<startTime",selected.startTime,startTime)
                                    if (selected.startTime < startTime) {
                                        startTime = selected.startTime;
                                    }
                                }
                                //console.log("selected",selected);
                            }
                        })
                        let hr = parseInt(startTime / 60);
                        //console.log("######",startTime,hr);
                        timeSlot = [];
                        for (let i = hr * 60; i <= 24 * 60; i += 10) {
                            timeSlot.push(i)
                        }
                        exclude.push({start: 0, end: hr * 60})
                    })
            }
        }else{
            this.props.history.push('/')
        }


        window.addEventListener('scroll', self.handleScroll);
    }

    foundLocation(location) {
        this.setState({
            currentLocation:location.coords,
            latitude: location.coords.latitude?location.coords.latitude:siteSetting.default_latitude,
            longitude:location.coords.longitude?location.coords.longitude:siteSetting.default_longitude,
        },function () {
            this.getCalenderData();
        })
    }

    noLocation() {
        this.getCalenderData();
    }

    getCalenderData(){
        let {dispatch} = this.props;
        let self = this;
        let params = {}
        let salonId=this.props.match.params.salonId;
        let hairdresserId=this.props.match.params.hairdresserId;

        if(salonId){
            params.salonId=salonId;
            params.latitude = this.state.latitude;
            params.longitude = this.state.longitude;
            self.props.dispatch(hairdresserActions.getSalonProfile(params))
                .then(function (response) {
                    if(!response.data.data.salons){
                        self.props.history.push("/404");
                    }
                    let displayData = [];
                    let salon={salonInfo:response.data.data};
                    //console.log("salon",salon);
                    if(salon.salonInfo) {
                        _.uniqBy(salon.salonInfo.salons.rota, function (e) {
                            _.uniqBy(salon.salonInfo.salons.rota, '_id');

                            userIdHolder.push(e.userId);
                        });
                        let uniq = [...new Set(userIdHolder)];

                        _.forEach(salon.salonInfo.salons.hairdresser, function (value, index) {
                            if (uniq.indexOf(value._id) > -1) {
                                displayData.push(salon.salonInfo.salons.hairdresser[index]);
                            }
                        })
                        self.props.dispatch(hairdresserActions.getHairdresserScheduleReset());
                        displayData.map((hairdresser)=>{
                            self.getHairdresserScheduleList(hairdresser._id,hairdresser);
                        })
                    }
                    //console.log("response.data.data.salons",response.data.data.salons.hairdresser[0]._id);
                    if (response.data.error) {
                        self.setState({
                            message: response.data.error.message
                        })
                    } else {
                    }
                });
        }else{
            this.props.history.push("/404");
        }
    }

    getslotTime(time) {
        let hr = parseInt(time / 60);
        let min = time - (hr * 60)
        return hr + ':' + (min == 0 ? '00' : min);
    }

    getServiceName(item){
        let serviceName="";
        if(item.list[0].info.servicesBooked){
            item.list[0].info.servicesBooked.map((service)=>{
                serviceName=serviceName+(serviceName==""?"":", ");
                serviceName=serviceName+service.service.serviceName;
            })
        }
        return serviceName;
    }

    getHairdresserTime(schedule){
        let self=this;
        //console.log("schedule",schedule);
        if(schedule.hairdresser.rotaInfo.rota.schedule){
            //console.log("hairdresser.rotaInfo.rota",hairdresser.rotaInfo.rota.schedule[0],moment().format('ddd').toLowerCase());
            let selected = _.find(schedule.hairdresser.rotaInfo.rota.schedule[0].profiles, function (o) {
                return o.day === moment(self.state.bookingDate).format('ddd').toLowerCase();
            })
            if(selected){
                return this.getslotTime(selected.startTime)+'-'+this.getslotTime(selected.endTime);
            }else{
                return <span className="text_red">Not Working Today</span>
            }

        }else{
            return <span className="text_red">Not Working Today</span>
        }

    }

    getHairdresserTimeClassDiary(schedule,data){
        let self=this;

        if(schedule.hairdresser.rotaInfo.rota.schedule){
            //console.log("hairdresser.rotaInfo.rota",hairdresser.rotaInfo.rota.schedule[0],moment().format('ddd').toLowerCase());
            let selected = _.find(schedule.hairdresser.rotaInfo.rota.schedule[0].profiles, function (o) {
                return o.day === moment(self.state.bookingDate).format('ddd').toLowerCase();
            })
            //console.log("schedule==",schedule,selected,schedule.hairdresser.name,data);
            if(selected){
                //return true;
                if(selected.startTime<=data.start&&selected.endTime>=data.end){
                    return '';
                }else{
                    return 'cm_dark_b';
                }
            }else{
                return 'cm_dark_b';
            }

        }else{
            return 'cm_dark_b';
        }

    }

    getHairdresserTimeClass(schedule){
        let self=this;

        if(schedule.hairdresser.rotaInfo.rota.schedule){
            //console.log("hairdresser.rotaInfo.rota",hairdresser.rotaInfo.rota.schedule[0],moment().format('ddd').toLowerCase());
            let selected = _.find(schedule.hairdresser.rotaInfo.rota.schedule[0].profiles, function (o) {
                return o.day === moment(self.state.bookingDate).format('ddd').toLowerCase();
            })
            //console.log("schedule",schedule,i,selected,schedule.hairdresser.name);
            if(selected){
                return true;
            }else{
                return 'cm_dark_b';
            }

        }else{
            return 'cm_dark_b';
        }

    }

    checkHairdresserTime(schedule,data){
        let self=this;

        if(schedule.hairdresser.rotaInfo.rota.schedule){
            //console.log("hairdresser.rotaInfo.rota",hairdresser.rotaInfo.rota.schedule[0],moment().format('ddd').toLowerCase());
            let selected = _.find(schedule.hairdresser.rotaInfo.rota.schedule[0].profiles, function (o) {
                return o.day === moment(self.state.bookingDate).format('ddd').toLowerCase();
            })
            //console.log("schedule",schedule,selected,schedule.hairdresser.name,data);
            let currentTime=parseInt(moment().format("HH")*60)+parseInt(moment().format("mm"));
            //console.log("currentTime",currentTime,self.state.bookingDate,moment().format("YYYY-MM-DD"),self.state.bookingDate===moment().format("YYYY-MM-DD"))
            if(selected){
               // return true;
                if(selected.startTime<=data.start&&selected.endTime>=data.end){
                    //return true;
                    if(self.state.bookingDate===moment().format("YYYY-MM-DD")) {
                        if (currentTime <= data.end) {
                            return true;
                        } else {
                            return false;
                        }
                    }else if(self.state.bookingDate<=moment().format("YYYY-MM-DD")) {
                        return false;
                    }else{
                        return true;
                    }

                }else{
                    return false;
                }
            }else{
                return false;
            }

        }else{
            return false;
        }

    }

    setCalenderDate(bookingDate){

        let self=this;
        this.setState({bookingDate:moment(bookingDate).format('YYYY-MM-DD')},function () {
            self.props.dispatch(hairdresserActions.getHairdresserScheduleReset());
            var Promisekey=[];
            let startTime=1440;
            self.props.staffRota.rota.map((hairdresser,i)=>{
                // Promisekey.push(self.getHairdresserScheduleList(hairdresser._id,hairdresser,i))
                self.getHairdresserScheduleList(hairdresser._id,hairdresser,i)
                if(hairdresser.rotaInfo.rota.schedule){
                    //console.log("hairdresser.rotaInfo.rota",hairdresser.rotaInfo.rota.schedule[0],moment().format('ddd').toLowerCase());
                    let selected = _.find(hairdresser.rotaInfo.rota.schedule[0].profiles, function (o) {
                        return o.day === moment(bookingDate).format('ddd').toLowerCase();
                    })
                    if(selected){
                        //console.log("selected.startTime<startTime",selected.startTime,startTime)
                        if(selected.startTime<startTime){
                            startTime=selected.startTime;
                        }
                    }
                    //console.log("selected",selected);
                }
            })
            let hr=parseInt(startTime/60);
            //console.log("######",startTime,hr);
            timeSlot=[];
            for(let i=hr*60;i<=24*60;i+=10){
                timeSlot.push(i)
            }
            while(exclude.length > 0) {
                exclude.pop();
            }
            exclude.push({ start: 0, end: hr*60 })
        })
    }

    getHairdresserScheduleList = (hairdresserId,hairdresser,i) => {
        let self=this;
        let params = `${hairdresserId}/calendar?calendarDate=${this.state.bookingDate}&deviceToken=fedfvcfdvfdvfdvfgvfgv`;
        return new promise(function (resolve, reject) {
            self.props.dispatch(hairdresserActions.getHairdresserSchedule(params, hairdresser, i))
                .then(function (result) {
                    resolve(result.data.data);
                })
        })
    }

    appointments(schedule){
        //console.log("item",schedule);
        let appointments=[];
        if(schedule.calendar.calendar.length>0){
            schedule.calendar.calendar.map((item,i)=>{
                let res=(<div></div>);
                let create=false;
                if(item.list[0].type==="booking"){
                    create=true;
                    res= (<a href={"/booking/"+item.list[0].info._id}><div className={`booking_card ${(item.list[0].endTime-item.list[0].startTime) > 30 ? '' : 'cm_wrap_text'}`} style={{background: colors[i%3]}}>
                        <h5>{getServiceName(item)} with {item.list[0].info.user?item.list[0].info.user.name:""}</h5>
                        {(item.list[0].endTime-item.list[0].startTime)>30?
                        <img src={item.list[0].info.user&&item.list[0].info.user.avatar!="" ? item.list[0].info.user.avatar : 'https://ra.ac.ae/wp-content/uploads/2017/02/user-icon-placeholder.png'} />
                        :""}
                        <p className="cm_sdule_time">{getslotTime(item.list[0].startTime)}-{getslotTime(item.list[0].endTime)}</p>

                    </div></a>)
                }if(item.list[0].type==="event"){
                    create=true;
                    //console.log("item.list[0]",item.list[0]);
                    res= (<div className="booking_card pointer-event" style={{background: colors[i%3]}} onClick={this.gotoEvent.bind(this,item.list[0],schedule)}>
                       <h5>{item.list[0].info.title}</h5>
                        {/*<h5>{getServiceName(item)} with {item.list[0].info.user?item.list[0].info.user.name:""}</h5>*/}
                        {/*{(item.list[0].endTime-item.list[0].startTime)>30?*/}
                        {/*    <img src={item.list[0].info.user&&item.list[0].info.user.avatar!="" ? item.list[0].info.user.avatar : 'https://ra.ac.ae/wp-content/uploads/2017/02/user-icon-placeholder.png'} />*/}
                        {/*    :""}*/}
                        <p className="cm_sdule_time">{getslotTime(item.list[0].startTime)}-{getslotTime(item.list[0].endTime)}</p>

                    </div>)
                }
                else{
                    //console.log("item",item);
                }
                if(create===true){
                    appointments.push({
                        id:i,
                        start:item.startTime,
                        end:item.endTime,
                        hairdresserId:schedule.hairdresser._id,
                        component: () => res
                    })
                }
            })
        }
        if(schedule.calendar.todayRota.breakTimes&&schedule.calendar.todayRota.breakTimes.length>0){
            schedule.calendar.todayRota.breakTimes.map((breakTime,j)=>{
                let res=(<div></div>);
                //console.log("breakTime",breakTime);
                res= (<div className="booking_card pointer-event" style={{background: colors[j%3]}}>
                    <h5>Break</h5>
                    <p className="cm_sdule_time">{getslotTime(breakTime.startTime)}-{getslotTime(breakTime.endTime)}</p>

                </div>)
                appointments.push({
                    id:"B-"+j,
                    start:breakTime.startTime,
                    end:breakTime.endTime,
                    hairdresserId:schedule.hairdresser._id,
                    component: () => res
                })
            })
        }
        //console.log("appointments",appointments);
        return appointments;
    }

    componentWillUnmount() {
        let self = this;
        window.removeEventListener('scroll', self.handleScroll);
    }
    
    handleScroll = event => {
        this.setState({scrollTopPos: window.pageYOffset});
    }

    gotoEvent(selectedEvent,schedule){
        let self=this;
        //console.log("selectedEvent",selectedEvent,schedule);
        selectedEvent.hairdresser=schedule.hairdresser;
        self.props.dispatch(eventActions.viewEvent(selectedEvent))
        .then(function (response) {
            self.props.history.push('/event')
        })
    }

    render() {
        let self=this;
        // console.log("this.props",this.props);
        // console.log("this.state",this.state);
        //console.log(window.pageYOffset, 'scrollLeftscrollLeftscrollLeftscrollLeft')
        return (
            <div className="h_n">
            {/*<Login />*/}
            <div className="body_container cm_bg_white">

                <TopHeader title="New Appointment" {...this.props}/>
                <SideNav {...this.props}/>
                <CalendarCard bookingDate={this.state.bookingDate} setCalenderDate={this.setCalenderDate}/>
                {this.props.staffRota.rota&&this.props.staffRota.rota.length>0&&this.props.staffRota.rota.length===this.props.hairdresserSchedule.length?
                    (<div className="diary-parent">
                    <div className="d-flex">

                    <div className="times_no" style={{transform: `translateY(-${window.pageYOffset}px)`}}>{timeSlot.map((item,i) => item%60===0?(<div key={"t_s_"+i}>{(item/60).toFixed(2)}<div className="cm_line_w" /></div>):"")}</div>

                        {/*<div className="booking_card_wrapper" style={{backgroundImage: `url(${require('../../assets/images/notpad_bg.png')})`}}>*/}
                            <div className="d-flex">

                                {
                                    this.props.hairdresserSchedule.map((schedule,index)=>(

                                    <div className="cm__col cm__border" key={"h_c_"+schedule.hairdresser._id}>

                                        <div className={'about_hairdresser '+this.getHairdresserTimeClass(schedule)}>

                                            <img src={schedule.hairdresser.userImage.circleImage ? schedule.hairdresser.userImage.circleImage : 'https://ra.ac.ae/wp-content/uploads/2017/02/user-icon-placeholder.png'} width="50px" />
                                            <h3>{schedule.hairdresser.name}</h3>
                                            <h5>
                                                {this.getHairdresserTime(schedule)}
                                            </h5>
                                        </div>

                                        <DailySchedule
                                            slices={10}
                                            excludeRanges={exclude}
                                            appointments={this.appointments(schedule)}
                                             //onDrop={this.onMoveEvent}
                                            metaData={{"hairdresserId":schedule.hairdresser._id}}
                                            BucketComponent={(data) => self.checkHairdresserTime(schedule,data)===true?<button className={self.getHairdresserTimeClassDiary(schedule,data)} onClick={() => this.addAppointment(data)}>+</button>:<div className="not_working"></div>}
                                            // EventComponent={({ id }) => <div className="demo">Appointment {id}</div>}
                                        />
                                    </div>
                                ))
                                }
                            </div>
                        {/*</div>*/}
                    </div>
                    <div id="addbutton">
                        <Link to={"/new-booking/"+this.props.match.params.salonId} ><div className="add-newbooking" ><Plus/></div></Link>
                    </div>
                </div>)
                    :<div className="cm_loader"><div className="loader"></div></div>}
            </div>
        </div>
        )
    }
}

function mapStateToProps(state) {
    let { hairdresserSchedule } = state.calender
    return {
        hairdresserSchedule,
        ...state
    };
}

export default connect(mapStateToProps)(DiaryPage);





