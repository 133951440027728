export const bookingConstants = {

    USER_BOOKING_REQUEST: 'USER_BOOKING_REQUEST',
    USER_BOOKING_SUCCESS: 'USER_BOOKING_SUCCESS',
    USER_BOOKING_ERROR: 'USER_BOOKING_ERROR',

    UPCOMING_OR_PAST_BOOKING_REQUEST: 'UPCOMING_OR_PAST_BOOKING_REQUEST',
    UPCOMING_OR_PAST_BOOKING_SUCCESS: 'UPCOMING_OR_PAST_BOOKING_SUCCESS',
    UPCOMING_OR_PAST_BOOKING_FAILURE: 'UPCOMING_OR_PAST_BOOKING__FAILURE',

    USER_CREATE_BOOKING_REQUEST: 'USER_CREATE_BOOKING_REQUEST',
    USER_CREATE_BOOKING_SUCCESS: 'USER_CREATE_BOOKING_SUCCESS',
    USER_CREATE_BOOKING_ERROR: 'USER_CREATE_BOOKING_ERROR',

    USER_CREATE_EVENT_REQUEST: 'USER_CREATE_EVENT_REQUEST',
    USER_CREATE_EVENT_SUCCESS: 'USER_CREATE_EVENT_SUCCESS',
    USER_CREATE_EVENT_ERROR: 'USER_CREATE_EVENT_ERROR',

    IS_BOOKING: 'IS_BOOKING',
    IS_BOOKING_NOT: 'IS_BOOKING_NOT',

    HAIRDRESSER_BOOKING_REQUEST: 'HAIRDRESSER_BOOKING_REQUEST',
    HAIRDRESSER_BOOKING_SUCCESS: 'HAIRDRESSER_BOOKING_SUCCESS',
    HAIRDRESSER_BOOKING_ERROR: 'HAIRDRESSER_BOOKING_ERROR',


}
