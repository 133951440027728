import {pageConstants} from '../_constants';


const termsAndConditionsState = {};

export function termsAndConditions(state = termsAndConditionsState, action) {
  //console.log('action.type',action);
  switch (action.type) {

    case pageConstants.T_AND_C_SUCCESS:
      let pageInfo= action.termsAndConditionsSuccess;
      return pageInfo;

    case pageConstants.T_AND_C_ERROR:
      return {};

    case pageConstants.T_AND_C_REQUEST:
      return {};

    default:
      return state
  }
}

const privacyPolicyState = {};

export function privacyPolicy(state = privacyPolicyState, action) {
  //console.log('action.type',action);
  switch (action.type) {

    case pageConstants.P_AND_P_SUCCESS:
      let pageInfo= action.privacyPolicySuccess;
      return pageInfo;

    case pageConstants.P_AND_P_FAILURE:
      return {};

    case pageConstants.P_AND_P_REQUEST:
      return {};

    default:
      return state
  }
}

const gdprState = {};

export function gdpr(state = gdprState, action) {
  //console.log('action.type',action);
  switch (action.type) {

    case pageConstants.GDPR_SUCCESS:
      let pageInfo= action.gdprSuccess;
      return pageInfo;

    case pageConstants.GDPR_ERROR:
      return {};

    case pageConstants.GDPR_REQUEST:
      return {};

    default:
      return state
  }
}

const hairdresserTermsAndConditionsState = {};

export function hairdresserTermsAndConditions(state = hairdresserTermsAndConditionsState, action) {
  //console.log('action.type',action);
  switch (action.type) {

    case pageConstants.H_T_AND_C_SUCCESS:
      let pageInfo= action.hairdresserTermsAndConditionsSuccess;
      return pageInfo;

    case pageConstants.H_T_AND_C_ERROR:
      return {};

    case pageConstants.H_T_AND_C_REQUEST:
      return {};

    default:
      return state
  }
}

