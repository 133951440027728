import React, { Component } from 'react';
import { connect } from 'react-redux';

import SideNav from '../template/SideNav';
import TopHeader from '../template/TopHeader';
import {hairdresserActions} from "../../_actions";
import siteSetting from "../../config/env";
import Select from "react-select";
import PhoneInput,{isValidPhoneNumber} from 'react-phone-number-input';
import {Modal} from "react-bootstrap";
import ReactCountryFlagsCurrencySelect from "react-country-flag-currency-picker";

const options = [
    { value: '1', label: 'Employed' },
    { value: '2', label: 'Self-Employed' },
];

class EditStaff extends Component {

    constructor(props){
        super(props);

        this.state = {
            name:'',
            first_name:'',
            last_name:'',
            email:'',
            phoneNumber:'',
            jobTitle:'',
            shortBio:'',
            circleImagePrev:require('../../assets/images/user-placeholder.png'),
            circleImage:require('../../assets/images/user-placeholder.png'),
            countryId:'217',
            salonId:localStorage.getItem('salonId'),
            employmentTypeId:1,
            employmentType:{ value: '1', label: 'Employed' },
            removeStaffMember:false,
            currencyCountry:"GB"
        }
        this.editStaff=this.editStaff.bind(this);
        this.onChanged=this.onChanged.bind(this);
        this.onDataChange=this.onDataChange.bind(this);
        this.validateEmail=this.validateEmail.bind(this);
        this.getPhoneNumber=this.getPhoneNumber.bind(this);
        this.removeStaff=this.removeStaff.bind(this);
    }

    componentDidMount() {
        let employmentType={};
        var self = this;
        if (localStorage.getItem('accessToken')) {
            let params={
                hairdresserId:this.props.match.params.id,
                lastName:'',
                email:'',
                jobTitle:'',
                phoneNumber:''
            }
            params.latitude = siteSetting.default_latitude;
            params.longitude = siteSetting.default_longitude;
            self.props.dispatch(hairdresserActions.getHairdresserInfo(params))
                .then(function (response) {
                    //console.log("response",response.data.data);
                    if(response.data.data) {
                        if(response.data.data.userInfo.hairdresser.employmentTypeId===1){
                            employmentType={ value: '1', label: 'Employed' }
                        }else{
                            employmentType={ value: '2', label: 'Self-Employed' }
                        }
                        let phoneNumber=response.data.data.userInfo.phoneNumber?self.getPhoneNumber(response.data.data.userInfo.phoneNumber):"";
                        //console.log("phoneNumber",phoneNumber);
                        self.setState(
                            {
                                employmentType:employmentType,
                                first_name: response.data.data.userInfo.first_name&&response.data.data.userInfo.first_name!==""?response.data.data.userInfo.first_name:response.data.data.userInfo.name,
                                last_name:response.data.data.userInfo.last_name,
                                email: response.data.data.userInfo.email,
                                phoneNumber: phoneNumber,
                                jobTitle: response.data.data.userInfo.hairdresser.jobTitle,
                                shortBio: response.data.data.userInfo.hairdresser.shortBio,
                                circleImagePrev:response.data.data.userInfo.userImage.circleImage?response.data.data.userInfo.userImage.circleImage:require('../../assets/images/user-placeholder.png'),
                                currency:response.data.data.userInfo.hairdresser.currency?response.data.data.userInfo.hairdresser.currency:"",
                                currencySymbol:response.data.data.userInfo.hairdresser.currencySymbol?response.data.data.userInfo.hairdresser.currencySymbol:"",
                                currencyCountry:response.data.data.userInfo.hairdresser.currencyCountry?response.data.data.userInfo.hairdresser.currencyCountry:"GB"
                            }
                        )
                        let currencyCountry=response.data.data.userInfo.hairdresser.currencyCountry?response.data.data.userInfo.hairdresser.currencyCountry:"GB"
                        self.refs.userCurrency.updateSelected(currencyCountry)

                    }
                })
        }else{
            this.props.history.push('/')
        }
    }

    editStaff(){
        this.setState({
            error:'',
            emailError:'',
            lNameError:'',
            fNameError:'',
            phoneNumberError:'',
            jobError:''
        })
        let self = this;
        let params = {};
        params.name=this.state.first_name+" "+this.state.last_name;
        params.first_name=this.state.first_name;
        params.last_name=this.state.last_name;
        params.email=this.state.email;
        params.phoneNumber=this.state.phoneNumber;
        params.jobTitle=this.state.jobTitle;
        params.shortBio=this.state.shortBio;
        params.employmentTypeId=this.state.employmentTypeId;
        params.salonId=this.state.salonId;
        params.countryId=this.state.countryId;

        if (this.state.first_name === "" || this.state.first_name === undefined) {
            this.setState({
                fNameError: 'Please enter first name'
            })
        } else if (this.state.last_name === "" || this.state.last_name === undefined) {
            this.setState({
                lNameError: 'Please enter last name'
            })
        } else if (this.state.email === "" || this.state.email === undefined) {
            this.setState({
                emailError: "Please enter your email address"
            })
        } else if (!this.validateEmail(this.state.email)) {
            this.setState({
                emailError: 'Please enter a valid email address'
            })
        } else if (this.state.phoneNumber === "" || this.state.phoneNumber === undefined ) { //!isValidPhoneNumber(this.state.phoneNumber)
            this.setState({
                phoneNumberError: 'Please enter a valid phone number'
            })
        } else if (this.state.jobTitle === "" || this.state.jobTitle === undefined) {
            this.setState({
                jobError: 'Please enter a job title'
            })
        }
        // else if (this.state.shortBio === "" || this.state.shortBio === undefined) {
        //     this.setState({
        //         shortBioError: 'Please enter a short bio'
        //     })
        // }
        else {
            let data = new FormData();
            data.append('userId', this.props.match.params.id);
            data.append('name', this.state.first_name+" "+this.state.last_name);
            data.append('first_name', this.state.first_name);
            data.append('last_name', this.state.last_name);
            data.append('email', this.state.email);
            data.append('phoneNumber', this.state.phoneNumber);
            data.append('jobTitle', this.state.jobTitle);
            data.append('shortBio', this.state.shortBio);
            data.append('employmentTypeId', this.state.employmentTypeId);
            data.append('salonId', this.state.salonId);
            data.append('countryId', this.state.countryId);
            data.append('circleImage', this.state.circleImage);
            data.append('rectangleImage', this.state.circleImage);
            data.append('currency', this.state.currency);
            data.append('currencySymbol', this.state.currencySymbol);
            data.append('currencyCountry', this.state.currencyCountry);

            self.props.dispatch(hairdresserActions.editStaff(data))
                .then(function (response) {
                    //console.log('client response', response);

                    if (response.data.errorCode) {
                        if(response.data.errorCode===51){
                            self.setState({
                                emailError: response.data.messages
                            })
                        }else if(response.data.errorCode===57){
                            self.setState({
                                phoneNumberError: response.data.messages
                            })
                        }else{
                            self.setState({
                                error: response.data.messages
                            })
                        }

                    } else {
                        self.props.history.push('/staff')
                    }
                })
                .catch((err) => {
                    console.log("errr",err);
                    //dispatch(failure(err));

                })
        }
    }

    onChanged(e) {
        this.setState({ [e.target.name]: e.target.value, copied: false });

        if (e.target.name === 'email') {
            if (!this.validateEmail(e.target.value)) {
                this.setState({
                    emailError: 'Please enter a valid email address'
                })
            } else {
                this.setState({
                    emailError: ''
                })
            }
        }

        if (e.target.name === 'first_name') {
            if (e.target.value === '' || e.target.value === null) {
                this.setState({
                    fNameError: ''
                })
            } else {
                this.setState({
                    fNameError: '',
                })
            }
        }

        if (e.target.name === 'last_name') {
            if (e.target.value === '' || e.target.value === null) {
                this.setState({
                    fNameError: ''
                })
            } else {
                this.setState({
                    lNameError: '',
                })
            }
        }

        if (e.target.name === 'phoneNumber') {
            if (e.target.value.length!==10) {
                this.setState({
                    phoneNumberError: 'Please enter a valid phone number'
                })
            } else {
                this.setState({
                    phoneNumberError: '',
                })
            }
        }

        if (e.target.name === 'jobTitle') {
            if (e.target.value === '' || e.target.value === null) {
                this.setState({
                    jobError: ''
                })
            } else {
                this.setState({
                    jobError: '',
                })
            }
        }

        // if (e.target.name === 'shortBio') {
        //     if (e.target.value === '' || e.target.value === null||e.target.value.length<10) {
        //         this.setState({
        //             shortBioError: 'The shortBio must be at least 10 characters.'
        //         })
        //     } else {
        //         this.setState({
        //             shortBioError: '',
        //         })
        //     }
        // }

        if (e.target.name === 'circleImage') {
            // console.log("e",e);
            let reader = new FileReader();
            let file = e.target.files[0];

            reader.onloadend = () => {
                this.setState({
                    circleImagePrev: reader.result
                });
            }
            reader.readAsDataURL(file)


            this.setState({
                circleImagePrev: file
            })
            this.setState({
                circleImage: e.target.files[0]
            });
        }
    }

    onDataChange(value, action){
        this.setState({
            [action.name]: value,
            employmentTypeId:value.value
        })
    }

    validateEmail(email) {
        const pattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const result = pattern.test(email);
        return result;
    }

    cancel(){
        this.props.history.push('/staff')
    }

    handlePhoneChange = val => {
        this.setState({phoneNumber: val})
        // if(!isValidPhoneNumber(val)) {
        //     this.setState({
        //         phoneNumberError: 'Please enter a valid phone number'
        //     })
        // }
    }

    getPhoneNumber(phoneNumber){
        let phone=phoneNumber.toString()
        //console.log("phone",phone);
        if(phone.length===10){
            //console.log("phone1",phone);
            if (phone[0] === 0||phone[0] === "0") {
                //console.log("phone1.1",phone);
                return "+44"+phone.substr(1)
            }else{
                //console.log("phone1.2",phone);
                return "+44"+phone
            }
        }else {
            //console.log("phone2",phone);
            if (phone[0] === 0||phone[0] === "0") {
                //console.log("phone2.1",phone);
                return "+44"+phone.substr(1)
            } else if (phone[0] === 4||phone[0] === "4") {
                //console.log("phone2.2",phone);
                return "+" + phone
            } else {
                //console.log("phone2.3",phone);
                return "+44"+phone
            }
        }
    }

    removeStaff(){
        let self=this;
        let params={
            employeeId:this.props.match.params.id,
            hairdresserId:localStorage.getItem('userId'),
            salonLeaveDate:''
        }
        self.props.dispatch(hairdresserActions.removeStaff(params))
            .then(function (removeStaff) {
                //console.log('removeStaff',removeStaff);
                self.props.history.push('/staff')
            })
    }

    handleClose = () => this.setState({
        removeStaffMember: false,
    })

    handleRemoveStaffMember=()=>{
        this.setState({
            removeStaffMember:true
        })
    }

    onSelectCurrency=(country)=>{
        //console.log("country",country);
        this.setState({
            currency:country.currency,
            currencySymbol:country.symbol,
            currencyCountry:country.countryCode,
        })
    }
    
    render() {
        let {
            removeStaffMember
        } = this.state;
        // console.log("this.prop",this.state);
        return (
            <div className="h_n">
            <div className="body_container cm_bg_white staff_page">

                <TopHeader title="Edit Staff" {...this.props}/>
                <SideNav {...this.props}/>
                

                <div className="container">

                        <div className="add_staff_form">
                            <div className="row">

                            <div className="col-md-12 form-group">
                                    <div className="file_upload">
                                        <img src={this.state.circleImagePrev}  alt="staff" onClick={(e) => this.circleImage.click()} />
                                        <input type="file" ref={(ref) => this.circleImage = ref} name="circleImage" id="circleImage" onChange={this.onChanged} />
                                        <i className="fa fa-plus" />
                                    </div>
                                </div>

                                <div className="col-md-6 form-group">
                                    <label>First Name</label>
                                    <input type="text" className="form-control" value={this.state.first_name} name="first_name" onChange={this.onChanged}/>
                                    {this.state.fNameError ?
                                        <span className="cm_error_msg">{this.state.fNameError}</span> : ''}
                                </div>

                                <div className="col-md-6 form-group">
                                    <label>Surname</label>
                                    <input type="text" className="form-control" value={this.state.last_name}  name="last_name" onChange={this.onChanged}/>
                                    {this.state.lNameError ?
                                        <span className="cm_error_msg">{this.state.lNameError}</span> : ''}
                                </div>

                                <div className="col-md-6 form-group">
                                    <label>Email</label>
                                    <input type="email" className="form-control" value={this.state.email} name="email" onChange={this.onChanged}/>
                                    {this.state.emailError ?
                                    <span className="cm_error_msg">{this.state.emailError}</span> : ''}
                                </div>

                                <div className="col-md-6 form-group cm_PhoneInput">
                                    <label>Mobile Number</label>
                                    {/*<input type="number" className="form-control" value={this.state.phoneNumber} name="phoneNumber" onChange={this.onChanged}/>*/}
                                    <PhoneInput
                                        international={true}
                                        defaultCountry="GB"
                                        // country={"GB"}
                                        className="form-control"
                                        placeholder="Phone Number"
                                        name="phoneNumber"
                                        placeholder="Enter phone number"
                                        value={this.state.phoneNumber?this.state.phoneNumber:""}
                                        onChange={this.handlePhoneChange}/>
                                    {this.state.phoneNumberError ?
                                        <span className="cm_error_msg">{this.state.phoneNumberError}</span> : ''}
                                </div>

                                <div className="col-md-6 form-group">
                                    <label>Job Title (viewable when making online booking)</label>
                                    <input type="text" className="form-control" value={this.state.jobTitle} name="jobTitle" onChange={this.onChanged}/>
                                    {this.state.jobError ?
                                        <span className="cm_error_msg">{this.state.jobError}</span> : ''}
                                </div>

                                <div className="col-md-6 form-group">
                                    <label>Employment Type</label>
                                    <Select
                                        options={options}
                                        classNamePrefix="custom"
                                        name="employmentType"
                                        onChange={this.onDataChange}
                                        value={this.state.employmentType}
                                    />
                                </div>

                                <div className="col-md-6 form-group">
                                    <label>Currency</label>
                                    <ReactCountryFlagsCurrencySelect className="form-control CurrencySelect"
                                                                     defaultCountry={this.state.currencyCountry}
                                                                     showOptionLabel={false}
                                                                     showCurrency={true}
                                                                     showCurrencySymbol={true}
                                                                     showSelectedLabel={false}
                                                                     onSelect={this.onSelectCurrency}
                                                                     ref="userCurrency"
                                    />
                                </div>

                                <div className="col-md-12 form-group">
                                    <label>Bio</label>
                                    <textarea className="form-control" value={this.state.shortBio} name="shortBio" onChange={this.onChanged}/>
                                    {this.state.shortBioError ?
                                        <span className="cm_error_msg">{this.state.shortBioError}</span> : ''}
                                </div>

                            </div>
                        </div>

                        <div className="text-right">
                            <button className="btn btn-pink pl-4 pr-4" onClick={this.handleRemoveStaffMember} >Remove from Salon </button>
                            <button className="btn btn-outline-dark pl-4 pr-4 ml-3" onClick={this.cancel.bind(this)}>Cancel</button>
                            <button className="btn btn-dark ml-3 pl-4 pr-4" onClick={this.editStaff.bind(this)}>Save</button>
                        </div>

                </div>
              

            </div>

                <Modal show={removeStaffMember} onHide={this.handleClose} className="bg_white_modal text-center">
                    <i className="fa fa-times cm_hide_mod" onClick={this.handleClose} />
                    <Modal.Body>
                        <div className="p-5">
                            <img src={require('../../assets/images/remove-staff-member-img.png')} className="img-fluid mb-3" />
                            <h3 style={{fontSize: '38px', fontWeight: '400'}} className="mb-4">Remove Staff Member</h3>
                            <p style={{fontSize: '23px', fontWeight: '300'}}>This will remove the staff member from the salon. This will cancel all of the stylists bookings and notify the clients via email.</p>

                            <div className="stap-action mt-5">
                                <button className="btn btn-outline-dark mr-3" onClick={this.handleClose}>Cancel</button>
                                <button className="btn btn-pink" onClick={this.removeStaff.bind(this)}>Remove Staff</button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            

        </div>
        )
    }

}



function mapStateToProps(state) {
    return {
        ...state
    };
}

export default connect(mapStateToProps)(EditStaff);





