import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import {connect} from "react-redux";
import siteSetting from "../../config/env";
import {userActions} from "../../_actions";
import { Link } from 'react-router-dom';





class Steps extends Component {
    
    constructor(props){
        super(props);

        this.state = {
            isModalOpen: true,
            step: 1
        }
    }

    componentDidMount() {
        let employmentType={};
        var self = this;
        if (localStorage.getItem('accessToken')) {
            let params={
            }
            params.latitude = siteSetting.default_latitude;
            params.longitude = siteSetting.default_longitude;
            self.props.dispatch(userActions.profile(params))
                .then(function (response) {

                })
        }else{
            this.props.history.push('/')
        }
    }

    changeStapPos = val => this.setState({step: val});


    handleClose = () => this.setState({isModalOpen: false});

    handleStepChange = () => {
        if(this.state.step > 3){
            this.setState({isModalOpen: false})
        }
        this.setState({step : this.state.step + 1})
        
    }

    Done=()=>{
        this.props.history.push('/diary/'+localStorage.getItem('salonId'));
    }


    render(){
        let { step } = this.state;
        return(
            <div className="h_n go-through-modal auth_wrapper" style={{backgroundImage: `url(${require('../../assets/images/auth-wrapper.jpg')})`}}>
                

                <Modal.Body>
                    <Link to={'/diary/'+localStorage.getItem('salonId')}><i className="fa fa-times cm_hide_mod" /></Link>

                    { step == 1 ? <div className="stap_wrapper">
                                <div className="_img_wrap">
                                    <img src={require('../../assets/images/step-1.png')} />
                                    </div>
                                    <h2>Welcome {this.props.user.userInfo?this.props.user.userInfo.name:""}</h2>
                                    <h3>Time to meet Beu, your new personal assistant</h3>
                                </div> : ''}


                    { step == 2 ? <div className="stap_wrapper">
                                <div className="_img_wrap">
                                    <img src={require('../../assets/images/step-2.png')} />
                                    </div>
                                    <h2>Your Hours</h2>
                                    <h3>For now we have set your hours to <span className="cm_fw_m">Tuesday - <br />Saturday, 9am - 5pm</span></h3>
                                </div> : ''}


                    { step == 3 ? <div className="stap_wrapper">
                                    <div className="_img_wrap">
                                    <img src={require('../../assets/images/step-3.png')} />
                                    </div>
                                    <h2>Your Services</h2>
                                    <h3>We added the demo services <span className="cm_fw_m">Cut and Blow-dry</span> and <span className="cm_fw_m">Cut and Finish</span></h3>
                                </div> : ''}


                    { step == 4 ? <div className="stap_wrapper">
                                 <div className="_img_wrap">
                                    <img src={require('../../assets/images/step-4.png')} />
                                    </div>
                                    <h2>Your Team</h2>
                                    <h3>We added a demo staff member called <span className="cm_fw_m">Tess Ting</span> to your team</h3>
                                </div> : ''}

                    <ul className="stap-indicate">
                                    <li onClick={() => this.changeStapPos(1)} className={`${step == 1 ? 'active' : ''}`} />
                                    <li onClick={() => this.changeStapPos(2)} className={`${step == 2 ? 'active' : ''}`} />
                                    <li onClick={() => this.changeStapPos(3)} className={`${step == 3 ? 'active' : ''}`} />
                                    <li onClick={() => this.changeStapPos(4)} className={`${step == 4 ? 'active' : ''}`} />
                    </ul>

                    <div className="stap-action">

                        {
                            step === 4 ? <React.Fragment>
                                    <button className="btn btn-dark" onClick={this.Done}>Done</button>
                                </React.Fragment>
                                :
                                <button className="btn btn-dark" onClick={this.handleStepChange}>Next</button>

                        }
                    </div>

                </Modal.Body>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        ...state
    };
}

export default connect(mapStateToProps)(Steps);