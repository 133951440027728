import axios from 'axios';
import API from "../config/API";
import siteSetting from "../config/env";

export const bookingService = {
    userBooking,
    createBooking,
    getBooking,
    cancelBooking,
    upcomingOrPastBooking,
    rescheduleBooking,
    createEvent,
    getHairdresserBooking,
    updateBooking,

    hairdresserCancelBooking,
    hairdresserNoShowBooking,
    hairdresserRescheduleBooking,
    hairdresserEditBooking
};

var instance = axios.create({
    baseURL: siteSetting.api.url,
    //timeout: 30000,    
    headers: {
        'Content-Type' : 'application/json',
        'Authorization': 'Bearer '+localStorage.getItem('accessToken'),
    }
});

function userBooking(params) {
    params.clientId=siteSetting.api_clientId;
    params.clientSecret=siteSetting.api_clientSecret;
    //console.log('params',params);
    return instance.get(API.USER_BOOKING, {params:params})
}

function upcomingOrPastBooking(params) {
    let userId=localStorage.getItem('userId');
    let  headers= {
        'Content-Type' : 'application/json',
        'Authorization': 'Bearer '+localStorage.getItem('accessToken'),
    }
    return axios.get(API.UPCOMING+userId+'/bookings?deviceToken=53675755757&getBookingsOfType='+params, {headers:headers})
}

function createBooking(params) {
    //console.log('params',params);
   let  headers= {
        'Content-Type' : 'application/json',
        'Authorization': 'Bearer '+(params.accessToken&&params.accessToken!==''?params.accessToken:localStorage.getItem('accessToken')),
    }
    return axios.post(siteSetting.api.url+"hairdresser/"+params.userId+API.USER_CREATE_BOOKING, params, {headers:headers})
}

function createEvent(params) {
   let  headers= {
        'Content-Type' : 'application/json',
        'Authorization': 'Bearer '+localStorage.getItem('accessToken'),
    }
    return axios.post(siteSetting.api.url+"hairdresser/"+params.userId+API.USER_CREATE_EVENT, params, {headers:headers})
}

function getBooking(params) {
    let userId=localStorage.getItem('userId');
    params.clientId=siteSetting.api_clientId;
    params.clientSecret=siteSetting.api_clientSecret;
    //console.log('params',params);

    let headers = {
        'Content-Type' : 'application/json',
        'Authorization': 'Bearer '+localStorage.getItem('accessToken'),
        'Accept': 'application/json'
    }

    return axios.get(API.UPCOMING+userId+'/bookings/'+params.bookingId,{params:params,headers:headers})
}

function cancelBooking(params) {
    let userId=localStorage.getItem('userId');
    params.clientId=siteSetting.api_clientId;
    params.clientSecret=siteSetting.api_clientSecret;
    //console.log('params',params);
    let  headers= {
        'Content-Type' : 'application/json',
        'Authorization': 'Bearer '+localStorage.getItem('accessToken'),
    }

    return axios.put(API.UPCOMING+userId+'/bookings/'+params.bookingId+'/cancel', params, {headers:headers})
}

function rescheduleBooking(params) {
    let userId=localStorage.getItem('userId');
    params.clientId=siteSetting.api_clientId;
    params.clientSecret=siteSetting.api_clientSecret;
    //console.log('params',params);
    return instance.put(API.UPCOMING+userId+'/bookings/'+params.bookingId+'/reschedule', params)
}

function getHairdresserBooking(params) {
    let userId=localStorage.getItem('userId');
    params.clientId=siteSetting.api_clientId;
    params.clientSecret=siteSetting.api_clientSecret;
    params.deviceToken='dfdsfds';

    //console.log('params',params);

    let headers = {
        'Content-Type' : 'application/json',
        'Authorization': 'Bearer '+localStorage.getItem('accessToken'),
        'Accept': 'application/json'
    }

    return axios.get(API.HAIRDRESSER_PROFILE+userId+'/bookings/'+params.bookingID,{params:params,headers:headers})
}

function updateBooking(params) {
    let userId=localStorage.getItem('userId');
    params.clientId=siteSetting.api_clientId;
    params.clientSecret=siteSetting.api_clientSecret;
    //console.log('params',params);
    let  headers= {
        'Content-Type' : 'application/json',
        'Authorization': 'Bearer '+localStorage.getItem('accessToken'),
    }

    return axios.put(API.HAIRDRESSER_PROFILE+userId+'/bookings/'+params.bookingID+'/update', params, {headers:headers})
}

function hairdresserCancelBooking(params) {
    //let userId=localStorage.getItem('userId');
    // params.clientId=siteSetting.api_clientId;
    // params.clientSecret=siteSetting.api_clientSecret;
    //console.log('params',params);
    params.deviceToken='dfdsfds';
    let  headers= {
        'Content-Type' : 'application/json',
        'Authorization': 'Bearer '+localStorage.getItem('accessToken'),
    }

    return axios.put(API.HAIRDRESSER_PROFILE+params.hairdresserId+'/bookings/'+params.bookingID+'/cancel', params, {headers:headers})
}

function hairdresserNoShowBooking(params) {
    // let userId=localStorage.getItem('userId');
    // params.clientId=siteSetting.api_clientId;
    // params.clientSecret=siteSetting.api_clientSecret;
    //console.log('params',params);
    params.deviceToken='dfdsfds';
    let  headers= {
        'Content-Type' : 'application/json',
        'Authorization': 'Bearer '+localStorage.getItem('accessToken'),
    }

    return axios.put(API.HAIRDRESSER_PROFILE+params.hairdresserId+'/bookings/'+params.bookingID+'/done', params, {headers:headers})
}

function hairdresserRescheduleBooking(params) {
    params.clientId=siteSetting.api_clientId;
    params.clientSecret=siteSetting.api_clientSecret;
    params.deviceToken='dfdsfds';
    //console.log('params',params);
    return instance.put(API.HAIRDRESSER+params.hairdresserId+'/bookings/'+params.bookingID+'/reschedule', params)
}

function hairdresserEditBooking(params) {
    params.clientId=siteSetting.api_clientId;
    params.clientSecret=siteSetting.api_clientSecret;
    params.deviceToken='dfdsfds';
    //console.log('params',params);
    return instance.post(API.HAIRDRESSER+params.hairdresserId+'/bookings/'+params.bookingID+'/edit', params)
}


