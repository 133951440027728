import { hairdresserConstants } from '../_constants';


const initialState = {
  salon: {},
};

export function salon(state = initialState, action) {
    //console.log('action.type',action);
    switch (action.type) {

      case hairdresserConstants.SALON_PROFILE_SUCCESS:
        let salonProfile= {salonInfo:action.getSalonProfileSuccess};
        return salonProfile;

      case hairdresserConstants.SALON_PROFILE_ERROR:
        return {};

      case hairdresserConstants.SALON_PROFILE_CLEAR:
        return {};

      default:
        return state
    }
}

const initialListState = [];

export function salonList(state = initialListState, action) {
  switch (action.type) {

    case hairdresserConstants.SALON_LIST_SUCCESS:
      let salonList= action.getSalonListSuccess;
      return salonList;

    case hairdresserConstants.SALON_PROFILE_ERROR:
      return {};

    case hairdresserConstants.SALON_PROFILE_CLEAR:
      return {};

    default:
      return state
  }
}


