import { hairdresserConstants } from '../_constants';


const initialState =[];

export function categories(state = initialState, action) {
    //console.log('action.type',action);
    switch (action.type) {

      case hairdresserConstants.CATEGORY_SUCCESS:
        let salonProfile= action.getCategorySuccess;
        return salonProfile;

      case hairdresserConstants.CATEGORY_ERROR:
        return [];

      case hairdresserConstants.CATEGORY_CLEAR:
        return [];

      default:
        return state
    }
}



