import React from 'react';
import {Tab, Tabs, Dropdown, Accordion, Card, Button} from 'react-bootstrap';
import moment from "moment";

const Checkout = (propes) => {
    // console.log("client",propes);
    return(
        propes.selectedClient?
            (<div className="cm_right_panal client_checkout">
            <div className="cm_bao">



                {/*<Dropdown>*/}
                {/*    <Dropdown.Toggle id="dropdown-basic">*/}
                {/*        <i className="fa fa-ellipsis-v" />*/}
                {/*    </Dropdown.Toggle>*/}

                {/*    <Dropdown.Menu>*/}
                {/*        <Dropdown.Item onClick={() => propes.changeClient()} >Change Client</Dropdown.Item>*/}
                {/*        /!*<Dropdown.Item href="#/action-2">Another action</Dropdown.Item>*!/*/}
                {/*        /!*<Dropdown.Item href="#/action-3">Something else</Dropdown.Item>*!/*/}
                {/*    </Dropdown.Menu>*/}
                {/*</Dropdown>*/}
                <img src={propes.selectedClient.localAvatar!=propes.selectedClient.localAvatar?"": require('../../assets/images/client-plachoder.jpg')} />
                <h3>{propes.selectedClient.name}</h3>
                <p>{propes.selectedClient.email}</p>
            </div>


            <div className="payment_detail">
                {propes.selectedService.map((service, index) => (
                    <p key={service._id}>{service.serviceName} - {propes.currencySymbol}{service.servicePrice.toFixed(2)}</p>
                ))}

                {/*<p>Total - £42</p>*/}
                <h3>Total - {propes.currencySymbol}{propes.totalAmount}</h3>
            </div>

                {propes.pendingAmount!==0?
                    (<><div className="cm_ammount">
                <p>Pay ({propes.currencySymbol})</p>
                {/*<h4>{propes.currencySymbol}{propes.totalAmount}</h4>*/}
                <input type="number" name="paymentAmount" className="form-control" value={propes.paymentAmount} onChange={propes.onChangedValue}/>
                {propes.paymentAmountError ?
                    <span className="cm_error_msg">{propes.paymentAmountError}</span> : ''}
            </div>

               <div className="cm_btns">

                <button className="btn btn-dark btn-block mb-3" onClick={() => propes.serPaymentType(1)}>Pay by App</button>
                <button className="btn btn-dark btn-block mb-3" onClick={() => propes.serPaymentType(2)}>Card</button>
                <button className="btn btn-dark btn-block mb-3" onClick={() => propes.serPaymentType(3)}>Cash</button>
                <button className="btn btn-dark btn-block" onClick={() => propes.serPaymentType(4)}>Other</button>
                {/*<button className="btn btn-dark btn-block mb-3" onClick={() =>propes.resetBooking()}>Cancel Booking</button>*/}

               </div></>)
                    :<div className="cm_btns">

                        <button className="btn btn-dark btn-block mb-3" onClick={() =>propes.completeSale()}>Complete</button>
                        {/*<button className="btn btn-dark btn-block mb-3" onClick={() =>propes.resetBooking()}>Cancel</button>*/}
                    </div>
                }

            </div>):""
    )
}

export default Checkout;
