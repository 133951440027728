import { hairdresserConstants } from '../_constants';


const initialState = [];

export function services(state = initialState, action) {
  //console.log('action.type',action);
  switch (action.type) {

    case hairdresserConstants.SALON_SERVICE_SUCCESS:
      let getService = action.getServiceSuccess;
      return getService;

    case hairdresserConstants.HAIRDRESSER_INFO_ERROR:
      return {};

    case hairdresserConstants.HAIRDRESSER_INFO_CLEAR:
      return {};

    default:
      return state
  }
}

