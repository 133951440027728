import React from 'react';
import {Tab, Tabs, Dropdown, Accordion, Card, Button} from 'react-bootstrap';

const Invoice = (propes) => {
    // console.log("client",propes);
    return(
        propes.selectedClient?
            (<div className="cm_right_panal client_checkout">
            <div className="cm_bao">

                <h3>Payment Taken </h3>

            </div>


            <div className="cm_btns">
                <button className="btn btn-dark btn-block mb-3" onClick={() =>propes.editBooking()}>Complete</button>
                <button className="btn btn-dark btn-block mb-3" onClick={() =>propes.resetBooking()}>Cancel</button>
            </div>

        </div>):""
    )
}

export default Invoice;
