import React, { useState} from 'react';
import {Modal} from "react-bootstrap";

const toggleMenuClass = () => {
    document.body.classList.toggle('nav_menu_open')
};

const TopHeader = (props) => {
        const [show, setShow] = useState(false);
        const [step, setStep] = useState(1);

        const changeStapPos = val => setStep(val);

        const handleClose = () => {
                setShow(false);
                setStep(1)
        };
        const handleShow = () => setShow(true);

        let {title}=props;

        const gotoSalon = () => {
            props.history.push('/settings')
            setShow(false);
            setStep(1)
        };

        return(
            <div className="top_header">
                <span onClick={toggleMenuClass} className="menu_toogle_icon"><img src={require('../../assets/images/menu-icon.png')} /></span>
                <h1>{title}</h1>
                <div className="user_pic">
                    {localStorage.getItem('isShow')!=="true"?
                        <button onClick={handleShow} className="btn btn-dark">Online Bookings</button>
                        :""}
                        <img src={props.user&&props.user.userInfo&&props.user.userInfo.userImage.circleImage?props.user.userInfo.userImage.circleImage: require('../../assets/images/client-plachoder.jpg')} />
                </div>




                <Modal show={show} onHide={handleClose} className="bg_white_modal">
                        <i className="fa fa-times cm_hide_mod" onClick={handleClose} />
                        <Modal.Body>
                        { step == 1 ? <div className="stap_wrapper">
                        <div className="_img_wrap">
                                    <img src={require('../../assets/images/login-step-1.png')} />
                                </div>
                                    <h2>Online Bookings</h2>
                                    <h3>Start taking bookings through your website and social media.</h3>
                            </div> : ''}


                            { step == 2 ? <div className="stap_wrapper">
                            <div className="_img_wrap">
                                    <img src={require('../../assets/images/login-step-2.png')} />
                                    </div>
                                    <h2>Get Discovered</h2>
                                    <h3>Get discovered by local clients through the Beu marketplace and rank higher in Google search results</h3>
                            </div> : ''}


                            { step == 3 ? <div className="stap_wrapper">
                                    <div className="_img_wrap">
                                    <img src={require('../../assets/images/login-step-3.png')} />
                                    </div>
                                    <h2>Custom URL Link</h2>
                                    <h3>Simply add your URL to whatever platform you want to take online bookings with</h3>
                            </div> : ''}


                            { step == 4 ? <div className="stap_wrapper">
                                <div className="_img_wrap">
                                    <img src={require('../../assets/images/login-step-4.png')} />
                                </div>
                                    <h2>Enable Online Bookings</h2>
                                    <h3>Make sure you have added a salon image, salon address and bank account if you want to take online payments</h3>
                            </div> : ''}

                            <ul className="stap-indicate">
                                    <li onClick={() => changeStapPos(1)} className={`${step == 1 ? 'active' : ''}`} />
                                    <li onClick={() => changeStapPos(2)} className={`${step == 2 ? 'active' : ''}`} />
                                    <li onClick={() => changeStapPos(3)} className={`${step == 3 ? 'active' : ''}`} />
                                    <li onClick={() => changeStapPos(4)} className={`${step == 4 ? 'active' : ''}`} />
                            </ul>

                            <div className="stap-action">

                                    {step == 4 ? <React.Fragment>
                                                        <button className="btn btn-outline-dark mr-3" onClick={handleClose}>Cancel</button>
                                                        <button className="btn btn-dark"  onClick={gotoSalon}>Confirm</button>
                                                </React.Fragment>
                                    :
                                    <button className="btn btn-dark" onClick={() => setStep(step + 1)}>Continue</button>
                                    }

                            </div>
                        </Modal.Body>
                        
                </Modal>

                   
            </div>
)
}
export default TopHeader;