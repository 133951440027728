import { combineReducers } from 'redux';

import { alert } from './alert.reducer';
import { hairdresser } from './hairdresser.reducer';
import { salon,salonList } from './salon.reducer';
import { hairdresserReview } from './review.reducer';
import { user } from './user.reducer';
import { setting } from './setting.reducer';
import { booking,createBooking,hairdresserBooking } from './booking.reducer';
import {userReview} from "./userreview.reducer";
import {upcomingOrPastBooking} from "./upcoming&pastBooking.reducer";
import {services} from "./services.reducer";
import {termsAndConditions,privacyPolicy,gdpr,hairdresserTermsAndConditions} from "./page.reducer";
import { calender } from './calender.reducer';
import { hairdresserClient } from './client.reducer';
import { clientDetail } from './clientDetail.reducer';
import { staff,staffService,staffServiceDetail,staffRota } from './staff.reducer';
import { categories } from './category.reducer';
import { hairdresserServices } from './hairdresserServices.reducer';
import { event } from './event.reducer';

const rootReducer = combineReducers({
  user,
  alert,
  hairdresser,
  salon,
  hairdresserReview,
  setting,
  booking,createBooking,
  userReview,
  salonList,
  upcomingOrPastBooking,
  services,
  termsAndConditions,privacyPolicy,gdpr,
  calender,
  hairdresserClient,
  clientDetail,
  staff,
  hairdresserTermsAndConditions,
  categories,
  hairdresserServices,
  staffService,
  staffServiceDetail,
  staffRota,
  hairdresserBooking,
  event
});

export default rootReducer;
