import React, { Component } from 'react';

class Footer extends Component{

    render(){
        return(
            <footer >
                <div className="social-icon push">
                        <a href={'https://www.instagram.com/beuapp/'} className="facebbok" target="_blank"> <i class="fa fa-instagram" aria-hidden="true"></i></a>
                        <a href={'https://twitter.com/beuapp'} className="twitter" target="_blank"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                        <a href={'https://www.facebook.com/beuapp/'} className="linkedin" target="_blank"><i class="fa fa-facebook-official" aria-hidden="true"></i></a>
                    </div>
            </footer>
        )
    }
}
export default Footer;
