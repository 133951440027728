import { bookingConstants } from '../_constants';


const initialState = {
    hairdresser: {},
};

export function upcomingOrPastBooking(state = initialState, action) {
    //console.log('action.type',action);
    let upcomingOrPastBooking;
    switch (action.type) {

        case bookingConstants.UPCOMING_OR_PAST_BOOKING_REQUEST:
             upcomingOrPastBooking= action.getUpcomingOrPastBookingRequest;
            return upcomingOrPastBooking;

        case bookingConstants.UPCOMING_OR_PAST_BOOKING_SUCCESS:
            upcomingOrPastBooking=action.getUpcomingOrPastBookingSuccess
            return upcomingOrPastBooking;

        case bookingConstants.UPCOMING_OR_PAST_BOOKING_FAILURE:
            return {};

        default:
            return state
    }
}

