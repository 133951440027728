import { settingConstants } from '../_constants';


const initialSettings = {
  login:false,
  register:false,
  booking:false,
  bookingConf:false,
  bookingLogin:false,
  forgot:false,
  reset:false,
  thanku:false,
  bookingService:false,
  editprofile:false,
  changePass:false,
  location:false,
  date:false,
  socialLogin:false
};

export function setting(state = initialSettings, action) {
    //console.log('**********',state,action)
    switch (action.type) {

      case settingConstants.TOGGLE_LOGIN:
        let loginValue=!state.login;

        if(action.value){
          if(action.value===1) {
            loginValue = true
          }else{
            loginValue = false
          }
        }
        return Object.assign({},initialSettings,{login:loginValue});

      case settingConstants.TOGGLE_SOCIAL_LOGIN:
        let socialLoginValue=!state.socialLogin;

        if(action.value){
          if(action.value===1) {
            socialLoginValue = true
          }else{
            socialLoginValue = false
          }
        }
        return Object.assign({},initialSettings,{socialLogin:socialLoginValue});

      case settingConstants.TOGGLE_SIGNUP:
        let registerValue=!state.register;
        if(action.value){
          if(action.value===1) {
            registerValue = true;
          }else{
            registerValue = false;
          }
        }
        return Object.assign({},initialSettings,{register:registerValue});

      case settingConstants.TOGGLE_BOOKING:
        let bookingValue=!state.booking;

        if(action.value){
          if(action.value===1) {
            bookingValue = true;
          }else{
            bookingValue = false;
          }
        }
        return Object.assign({},initialSettings,{booking:bookingValue});
      case settingConstants.TOGGLE_FORGOT:
        let forgotValue=!state.forgot;
        if(action.value){
          if(action.value===1) {
            forgotValue = true;
          }else{
            forgotValue = false;
          }
        }
        return Object.assign({},initialSettings,{forgot:forgotValue});
      case settingConstants.TOGGLE_RESET:
        let resetValue=!state.reset;
        if(action.value){
          if(action.value===1) {
            resetValue = true;
          }else{
            resetValue = false;
          }
        }
        return Object.assign({},initialSettings,{reset:resetValue});
      case settingConstants.TOGGLE_THANKU:
        let thankuValue=!state.thanku;
        if(action.value){
          if(action.value===1) {
            thankuValue = true;
          }else{
            thankuValue = false;
          }
        }
        return Object.assign({},initialSettings,{thanku:thankuValue});
      case settingConstants.TOGGLE_BOOKING_SERVICE:
        let bookingServiceValue=!state.bookingService;
        if(action.value){
          if(action.value===1) {
            bookingServiceValue = true;
          }else{
            bookingServiceValue = false;
          }
        }
        return Object.assign({},initialSettings,{bookingService:bookingServiceValue});
      case settingConstants.TOGGLE_CONF_BOOKING:
        let bookingConfValue=!state.bookingConf;
        if(action.value){
          if(action.value===1) {
            bookingConfValue = true;
          }else{
            bookingConfValue = false;
          }
        }
        return Object.assign({},initialSettings,{bookingConf:bookingConfValue});

      case settingConstants.TOGGLE_LOGIN_BOOKING:
        let bookingLoginValue=!state.bookingLogin;
        if(action.value){
          if(action.value===1) {
            bookingLoginValue = true;
          }else{
            bookingLoginValue = false;
          }
        }
        return Object.assign({},initialSettings,{bookingLogin:bookingLoginValue});

      case settingConstants.TOGGLE_EDIT_PROFILE:
        let editProfileValue=!state.editprofile;
        if(action.value){
          if(action.value===1) {
            editProfileValue = true;
          }else{
            editProfileValue = false;
          }
        }
        return Object.assign({},initialSettings,{editprofile:editProfileValue});

      case settingConstants.TOGGLE_CHANGE_PASSWORD:
        let changePassValue=!state.changePass;
        if(action.value){
          if(action.value===1) {
            changePassValue = true;
          }else{
            changePassValue = false;
          }
        }
        return Object.assign({},initialSettings,{changePass:changePassValue});




        case settingConstants.TOGGLE_SERVICE_POP:
        let changebookingServiceValue=!state.changePass;
        if(action.value){
          if(action.value===1) {
            changebookingServiceValue = true;
          }else{
            changebookingServiceValue = false;
          }
        }
        return Object.assign({},initialSettings,{bookingService:changebookingServiceValue});

      case settingConstants.TOGGLE_LOCATION_POP:
        let locationValue=!state.location;
        if(action.value){
          if(action.value===1) {
            locationValue = true;
          }else{
            locationValue = false;
          }
        }
        return Object.assign({},initialSettings,{location:locationValue});

      case settingConstants.TOGGLE_DATE_POP:
        let dateValue=!state.date;
        if(action.value){
          if(action.value===1) {
            dateValue = true;
          }else{
            dateValue = false;
          }
        }
        return Object.assign({},initialSettings,{date:dateValue});

      default:
        return state
  }
}

